/* controller for dictionary terms */
module.exports = () => {

  angular.module('explainApp')

    .controller('DictionaryController', ['$scope', '$rootScope', '$location', '$routeParams', 'DictionaryService', 'AuthoringService', 'ModalService', 'sweet',
      function DictionaryController($scope, $rootScope, $location, $routeParams, DictionaryService, AuthoringService, ModalService, sweet) {

        $scope.currentDictionaryPage = 0;
        $scope.itemsPerPage = AppConfigService.getValue('api.paging.maxPageSize');
        $scope.currentDictionarySearchTerm = undefined;
        $scope.currentDerId = $routeParams.derId;

        $scope.dictionaryTermListForDER = [];
        $scope.hasDictionaryResults = false;
        $scope.numDictionaryPages = 0;
        $scope.dictionaryPages = [];

        $scope.dictionaryTerm = undefined;


        $scope.initDictionaryPage = () => {
          $scope.fetchDictionaryList();
        };

        $scope.fetchDictionaryList = () => {
          $scope.getDictionaryTermListForDER($scope.currentDerId, $scope.currentDictionaryPage, $scope.itemsPerPage, $scope.currentDictionarySearchTerm);
        };

        $scope.changeDictionaryPage = (newPage, newSize) => {
          $scope.currentDictionaryPage = newPage;
          if (newSize) {
            $scope.itemsPerPage = newSize;
          }
        };

        /** ----- Get the list of dictionary terms -----  */
        $scope.getDictionaryTermListForDER = (derId, currentPage, itemsPerPage, searchTerm) => {
          $rootScope.isLoading = true;

          DictionaryService.getDictionaryTermListForDER(derId, currentPage, itemsPerPage, searchTerm)
            .then((res) => { if (res.err !== 0) { throw res; } return res; })

            .then((res) => {
              $scope.dictionaryTermListForDER = res.data.items;
              $scope.hasDictionaryResults = res.data.totalItems !== 0;

              if ($scope.hasDictionaryResults) {
                $scope.numDictionaryPages = Math.ceil(res.data.totalItems / itemsPerPage);
                $scope.dictionaryPages = [];
                for (var i = 0; i < $scope.numDictionaryPages; i++) {
                  $scope.dictionaryPages.push(i);
                }
              }
            })

            // handle errors
            .catch((err) => {
              console.log("Error fetching dictionary term list", err);
            })

            .finally(() => {
              $rootScope.isLoading = false;
            });
        };

        /** ----- Get a single dictionary term by id -----  */
        $scope.getDictionaryTerm = (termId) => {

          $rootScope.isLoading = true;

          DictionaryService.getDictionaryTerm(termId)
            .then((res) => { if (res.err !== 0) { throw res; } return res; })

            .then((res) => {
              $scope.dictionaryTerm = res.data;

            })
            // handle errors
            .catch((err) => {
              console.log("Error fetching dictionary term", err);
            })

            .finally(() => {
              $rootScope.isLoading = false;
            });
        };

        /** ----- Create a new dictionary term ----- */
        $scope.createDictionaryTerm = (derId, title, translatedTitle, description, audioDerRelationId, imageDerRelationId) => {

          $rootScope.isLoading = true;

          DictionaryService.createDictionaryTerm(derId, title, translatedTitle, description, audioDerRelationId, imageDerRelationId)
            .then((res) => { if (res.err !== 0) { throw res; } return res; })

            .then((res) => {
              $scope.changeDictionaryPage(0);
              $scope.currentDictionarySearchTerm = undefined;

              $scope.fetchDictionaryList();

            })
            // handle errors
            .catch((err) => {
              console.log("Error creating dictionary term", err);
            })

            .finally(() => {
              $rootScope.isLoading = false;
            });
        };

        /** ----- Update an existing dictionary term ----- */
        $scope.updateDictionaryTerm = (termId, title, translatedTitle, description, audioDerRelationId, imageDerRelationId) => {

          $rootScope.isLoading = true;

          DictionaryService.updateDictionaryTerm(termId, title, translatedTitle, description, audioDerRelationId, imageDerRelationId)
            .then((res) => { if (res.err !== 0) { throw res; } return res; })

            .then((res) => {

              $scope.fetchDictionaryList();
            })
            // handle errors
            .catch((err) => {
              console.log("Error updating dictionary term", err);
            })

            .finally(() => {
              $rootScope.isLoading = false;
            });
        };

        /** ----- Delete an existing dictionary term ----- */
        $scope.deleteDictionaryTerm = (termId) => {

          $rootScope.isLoading = true;

          DictionaryService.deleteDictionaryTerm(termId)
            .then((res) => { if (res.err !== 0) { throw res; } return res; })

            .then((res) => {

              $scope.fetchDictionaryList();
            })
            // handle errors
            .catch((err) => {
              console.log("Error deleting dictionary term", err);
            })

            .finally(() => {
              $rootScope.isLoading = false;
            });
        };

        $scope.openEditDictionaryTermModal = (onSave, term) => {
          ModalService.showModal({
            templateUrl: "/views/partials/editDictionaryTermModal.html",
            controller: "EditDictionaryTermModalController",
            inputs: {
              term: term,
            }
          }).then((modal) => {

            modal.element.modal();
            modal.close.then((res) => {
              if (res.save) {
                onSave(res.term);
              }
            });

          });
        };

        $scope.handleCreateNewTerm = (term) => {
          $scope.createDictionaryTerm($scope.currentDerId, term.title, term.translatedTitle, term.description, term.audioDerRelationId, term.imageDerRelationId)
        };

        $scope.handlEditDictionaryTerm = (term) => {
          $scope.updateDictionaryTerm(term.dictionaryTermId, term.title, term.translatedTitle, term.description, term.audioDerRelationId, term.imageDerRelationId)
        };

        $scope.handleDeleteDictionaryTerm = (term) => {
          sweet.show({
            title: 'Hmmm...',
            text: 'Želite li stvarno obrisati izraz?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: "Da, želim obrisati!",
            closeOnConfirm: true
          }, () => {

            $scope.deleteDictionaryTerm(term.dictionaryTermId);
          })

        };

        $scope.handleDictionarySearch = () => {
          $scope.fetchDictionaryList();
        };

        $scope.handleShowAll = () => {
          $scope.currentDictionarySearchTerm = undefined;
          $scope.fetchDictionaryList();
        };


      }])

    .controller('EditDictionaryTermModalController', [
      '$scope', '$rootScope', 'term', '$element', 'close', 'ModalService',
      function ($scope, $rootScope, term, $element, close, ModalService) {

        $scope.term = { ...term };

        $scope.saveDictionaryTerm = () => {
          $element.modal('hide');
          close({
            term: $scope.term,
            save: true
          }, 500);
        };

        $scope.cancelDictionaryTermEdit = () => {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

        $scope.openInsertResourceModal = (fileType, title) => {
          const multimediaData = {};
          multimediaData.title = title;
          ModalService.showModal({
            templateUrl: "/views/partials/uploadResourceModal.html",
            controller: "UploadImageModalCtrl",
            inputs: {
              hasAltText: false,
              fileMultimediaData: multimediaData,
              fileType: fileType,
              actionType: 'insertToEditor',
            }
          }).then((modal) => {

            modal.element.modal();
            modal.close.then((res) => {
              if (res.save) {
                const resultResourceObject = JSON.parse(res.res);

                if (fileType === 'img') {
                  $scope.term.imageDerRelationId = resultResourceObject.relationId;
                }
                else if (fileType === 'aud') {
                  $scope.term.audioDerRelationId = resultResourceObject.relationId;
                }
                else {
                  console.log('wrong file type')
                }
              }

            });

          });
        };

      }
    ])
}

