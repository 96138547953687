module.exports = function () {
  angular.module('explainApp')

    .controller('initController', ['$scope', '$cookieStore', '$rootScope', '$location', '$localStorage', function initController($scope, $cookieStore, $rootScope, $location, $localStorage) {

      $scope.init = function () {

        var cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
          document.cookie = "testcookie";
          cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
        }
        if (!cookieEnabled) {
          $location.path('/nocookies');
        }

        $rootScope.errorMessage = '';
        $rootScope.infoMessage = '';

        $rootScope.reqs = [];
        $rootScope.whats = [];
        $rootScope.isLoggedIn = false;

      };
    }])

    .controller('MetaController', ['$scope', 'Page', function MetaController($scope, Page) {

      $scope.Page = Page;
      $scope.frontPageDate = new Date();

    }])

    .controller('ContactController', ['$scope', '$location', 'User', 'sweet', function ContactController($scope, $location, User, sweet) {
      $scope.contactData = {};
      var tags = {};

      $scope.sendQuery = function () {
        // slanje kontakt forme
        $scope.contactData.timestamp = Date.now();
        $scope.contactData.username = User.getUsername();
        $scope.contactData.location = $location.url();
        tags.username = User.getUsername();
        //console.log($scope.contactData);
        ContactService.sendContactFormData($scope.contactData.timestamp, $scope.contactData, tags);
        sweet.show('Hvala!', 'Vaša poruka je poslana, te ćemo Vam odgovoriti u što kraćem roku.', 'success');
        $scope.contactData = {};
      };
    }])



    .controller('DOMSearchController', ['$scope', '$rootScope', '$filter', '$routeParams', '$location', '$timeout', 'ModalService', 'AuthoringService', 'PermissionService', 'Search', 'User', 'sweet', 'ngToast',
      function DOMSearchController($scope, $rootScope, $filter, $routeParams, $location, $timeout, ModalService, AuthoringService, PermissionService, Search, User, sweet, ngToast) {


        $scope.DERCopy = function (derId, targetDerId) {
          AuthoringService.copyDER(derId, targetDerId)
            .then(function (res) {
              if (res.err === 0) {
                ngToast.create({
                  dismissOnTimeout: true,
                  content: 'Kopiranje pokrenuto! Kopiranje traje od nekoliko do 30ak minuta, ovisno o veličini DOS-a',
                  className: 'success'
                });
              }
            })
            .finally(function () {
              $rootScope.isLoading = false;
            });
        };

        $scope.createhPubWithCustomName = function (moduleId, version, name) {

        };

        $scope.createhPub = function (moduleId, version, name) {
          AuthoringService.createFile(moduleId, version, 'hpub', 'web-accessible', name)
            .then(function (res) {
              $rootScope.isLoading = false;
              sweet.show('Super!', 'Na server je poslan zahtjev za izradu hPuba. Izrada može trajati nekoliko minuta. Da biste provjerili je li hPub napravljen, osvježite listu.', 'success');
            })
        };
        $scope.currentSearchTerm = $routeParams.searchTerm;
        $scope.currentPage = $routeParams.page ? parseInt($routeParams.page) : 0;

        $scope.currentSort = $location.search().sort;
        if (!$scope.currentSort) {
          $scope.currentSort = 'title_az';
        }
        if ($location.search().dos) {
          $scope.dos = $location.search().dos;
        }

        $scope.newSearchTerm = $routeParams.searchTerm;

        $scope.includePublic = true;
        if ($location.search().javne === 'false' || $location.search().javne === false) {
          $scope.includePublic = false;
        }

        $scope.showMine = true;
        if ($location.search().moje === 'false' || $location.search().moje === false) {
          $scope.showMine = false;
        }

        if ($scope.showMine && $scope.includePublic) {
          $scope.filterBy = '0';
        } else if ($scope.showMine) {
          $scope.filterBy = '2';
        } else {
          $scope.filterBy = '1';
        }

        $scope.doFilter = function () {
          switch ($scope.filterBy) {
            case ('0'):
              $scope.showMine = true;
              $scope.includePublic = true;
              $scope.paramChanged();
              break;
            case ('1'):
              $scope.showMine = false;
              $scope.includePublic = true;
              $scope.paramChanged();
              break;
            case ('2'):
              $scope.showMine = true;
              $scope.includePublic = false;
              $scope.paramChanged();
              break;
          }
        };

        $scope.paramChanged = function () {
          var oldPathParams = $location.path().split('/');
          var newPath = '/' + oldPathParams[1] + '/0';
          $location.path(newPath).search({
            javne: $scope.includePublic,
            moje: $scope.showMine,
            sort: $scope.currentSort,
            dos: $scope.dos
          });

        };

        if ($location.url().substring(0, 7) === '/knjige') {
          $scope.searchFilter = 'eBook';
        } else if ($location.url().substring(0, 7) === '/moduli') {
          $scope.searchFilter = 'module';
        } else if ($location.url().substring(0, 7) === '/dosevi') {
          $scope.searchFilter = 'dos';
        } else if ($location.url().substring(0, 5) === '/list') {
          $scope.searchFilter = 'list';
        } else if ($location.url().substring(0, 6) === '/grupa') {
          $scope.searchFilter = 'grupa';
        } else if ($location.url().substring(0, 13) === '/prezentacije') {
          $scope.searchFilter = 'presentation';
        } else if ($location.url().substring(0, 6) === '/slike') {
          $scope.searchFilter = 'image';
        } else if ($location.url().substring(0, 13) === '/video-snimke') {
          $scope.searchFilter = 'video';
        } else if ($location.url().substring(0, 13) === '/audio-snimke') {
          $scope.searchFilter = 'audio';
        } else if ($location.url().substring(0, 8) === '/kvizovi') {
          $scope.searchFilter = 'test';
        } else if ($location.url().substring(0, 9) === '/tekstovi') {
          $scope.searchFilter = 'text';
        } else if ($location.url().substring(0, 12) === '/interakcije') {
          $scope.searchFilter = 'geogebra';
        }

        $scope.searchFilters = [{
            id: 'eBook',
            label: 'eKnjiga'
          },
          {
            id: 'presentation',
            label: 'prezentacija'
          }
        ];
        $scope.searchResFilters = [{
            id: 'image',
            label: 'slika'
          },
          {
            id: 'video',
            label: 'video'
          },
          {
            id: 'audio',
            label: 'audio'
          },
          {
            id: 'geogebra',
            label: 'interakcija',
          }
          //{id: 'text', label: 'tekst'}
          // ,{id: 'test', label: 'kviz'}
        ];

        $scope.doslist = [{
            id: "Sve",
            label: " "
          },
          {
            id: "Test",
            label: "Test"
          },
          {
            id: "Matematika 7",
            label: "Matematika 7"
          },
          {
            id: "Matematika 8",
            label: "Matematika 8"
          },
          {
            id: "Matematika 1",
            label: "Matematika 1"
          },
          {
            id: "Matematika 2",
            label: "Matematika 2"
          },
          {
            id: "Fizika 7",
            label: "Fizika 7"
          },
          {
            id: "Fizika 8",
            label: "Fizika 8"
          },
          {
            id: "Fizika 1",
            label: "Fizika 1"
          },
          {
            id: "Fizika 2",
            label: "Fizika 2"
          },
          {
            id: "Nepotrebno",
            label: "Nepotrebno"
          }
        ];


        $scope.sorters = [{
            id: 'title_az',
            label: 'A do Z'
          },
          {
            id: 'title_za',
            label: 'Z do A'
          },
          {
            id: 'newest',
            label: 'Najnovije'
          }
        ];

        var organizationId = null;

        $scope.DERlistTree = function () {
          if ($rootScope.tenant === 'carnet') {
            $timeout(function () {
              //console.log($rootScope.userOrganization.organizationId);
              if ($rootScope.userOrganization && $rootScope.userOrganization.organizationId) {
                organizationId = $rootScope.userOrganization.organizationId;
                $scope.includePublic = false;
                if (organizationId === -1) {
                  organizationId = null;
                  $scope.includePublic = true;
                }
                $scope.getPermissions = false;

                $scope.DERlist();
              }
            }, 900);
          } else {
            $scope.DERlist();
          }

        };

        $scope.DERlistResources = function () {
          if ($rootScope.tenant === 'carnet') {
            $timeout(function () {
              //console.log($rootScope.userOrganization.organizationId);
              if ($rootScope.userOrganization && $rootScope.userOrganization.organizationId) {
                organizationId = $rootScope.userOrganization.organizationId;
                if (organizationId === -1) {
                  organizationId = null;
                  $scope.includePublic = true;
                }
                if ($scope.searchFilter === 'video' || $scope.searchFilter === 'audio' || $scope.searchFilter === 'image' || $scope.searchFilter === 'geogebra') {
                  $scope.includePublic = true;
                  $scope.getPermissions = false;
                };

                $scope.DERlist();
              }
            }, 900);
          } else {
            $scope.DERlist();
          }

        };

        $scope.DERlistPermissions = function () {
          if ($rootScope.tenant === 'carnet') {
            $timeout(function () {
              //console.log($rootScope.userOrganization.organizationId);
              if ($rootScope.userOrganization && $rootScope.userOrganization.organizationId) {
                organizationId = $rootScope.userOrganization.organizationId;
                $scope.includePublic = false;
                if (organizationId === -1) {
                  organizationId = null;
                  $scope.includePublic = true;
                }
                $scope.DERlist();
              }
            }, 900);
          } else {
            $scope.DERlist();
          }

        };

        var itemsPerPage = 100;

        $scope.DERlist = function () {
          $rootScope.isLoading = true;
          var currentFilter = $scope.searchFilter === 'list' ? 'dos' : $scope.searchFilter;
          if (!$scope.withPermissionsForGroupId) {
            $scope.withPermissionsForGroupId = null;
          };
          if ($scope.searchFilter === 'grupa') {
            currentFilter = 'dos';
            if ($rootScope.tenant === 'carnet') {
              $scope.includePublic = false;
            } else if ($rootScope.tenant !== 'carnet') {
              $scope.includePublic = true;
            }
            $scope.getPermissions = false;
            if ($rootScope.userOrganization) {
              organizationId = $rootScope.userOrganization.organizationId;
            }
            $scope.withPermissionsForGroupId = $routeParams.groupId;
          };



          AuthoringService.listDER($scope.currentSearchTerm, $scope.includePublic, currentFilter, $scope.currentPage, itemsPerPage, !$scope.showMine, $scope.currentSort, false, false, $scope.dos, null, $scope.withPermissionsForGroupId, $scope.getPermissions, organizationId)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.ders = res.data.items;
                $scope.dersOriginal = angular.copy(res.data.items);

                if (res.data.totalItems === 0) {
                  $scope.noResults = true;
                } else {
                  $scope.noResults = false;
                  $scope.NoPages = Math.ceil(res.data.totalItems / itemsPerPage);
                  $scope.pages = [];
                  for (var i = 0; i < $scope.NoPages; i++) {
                    $scope.pages.push(i);
                  }

                }
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.DERchildren = function (parentDerId, type, getPermissions) {
          $rootScope.isLoading = true;

          var withPermissionsForGroupId = $routeParams.groupId;
          AuthoringService.getDER(parentDerId, null, false, false, true, 1, withPermissionsForGroupId, getPermissions)
            .then(function (res) {
              if (res.err === 0) {
                if (type === "dos") {
                  $scope.modules = res.data.children;
                  $scope.modulesOriginal = angular.copy(res.data.children);
                  $scope.parentDerId = parentDerId;
                } else if (type === "module") {
                  $scope.units = res.data.children;
                  $scope.grandparentDerId = parentDerId;
                }
              }
            })
            .finally(function () {
              $rootScope.isLoading = false;
            });
        };

        $scope.saveDerPermissions = function () {

          var originalList = [];
          var newlist = [];

          var newViewDerIds = [];
          var newModifyDerIds = [];
          var newCommentDerIds = [];

          var oldViewDerIds = [];
          var oldModifyDerIds = [];
          var oldCommentDerIds = [];


          angular.forEach($scope.ders, function (d) {

            var derPermissions = {
              "derId": d.derId,
              "MODIFY": d.groupPermissions.MODIFY,
              "VIEW": d.groupPermissions.VIEW,
              "COMMENT": d.groupPermissions.COMMENT
            };

            newlist.push(derPermissions);

            if (d.groupPermissions.MODIFY) {
              newModifyDerIds.push(d.derId);
            }

            if (d.groupPermissions.VIEW) {
              newViewDerIds.push(d.derId);
            }

            if (d.groupPermissions.COMMENT) {
              newCommentDerIds.push(d.derId);
            }

          });

          angular.forEach($scope.dersOriginal, function (d) {

            var derPermissions = {
              "derId": d.derId,
              "MODIFY": d.groupPermissions.MODIFY,
              "VIEW": d.groupPermissions.VIEW,
              "COMMENT": d.groupPermissions.COMMENT
            };

            originalList.push(derPermissions);

            if (d.groupPermissions.MODIFY) {
              oldModifyDerIds.push(d.derId);
            }

            if (d.groupPermissions.VIEW) {
              oldViewDerIds.push(d.derId);
            }

            if (d.groupPermissions.COMMENT) {
              oldCommentDerIds.push(d.derId);
            }

          });

          var modifyRemovedOnDerIds = jQuery(oldModifyDerIds).not(newModifyDerIds).get();
          var modifyAddedOnDerIds = jQuery(newModifyDerIds).not(oldModifyDerIds).get();

          var viewRemovedOnDerIds = jQuery(oldViewDerIds).not(newViewDerIds).get();
          var viewAddedOnDerIds = jQuery(newViewDerIds).not(oldViewDerIds).get();

          var commentRemovedOnDerIds = jQuery(oldCommentDerIds).not(newCommentDerIds).get();
          var commentAddedOnDerIds = jQuery(newCommentDerIds).not(oldCommentDerIds).get();


          var changeCount = 0;
          var ugroupIds = [$routeParams.groupId];
          if (modifyAddedOnDerIds && modifyAddedOnDerIds.length) {
            changeCount++;
            PermissionService.derAdd(modifyAddedOnDerIds, "MODIFY", null, ugroupIds)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                } else {
                  $rootScope.isLoading = false;
                }
              });
          }

          if (modifyRemovedOnDerIds && modifyRemovedOnDerIds.length) {
            changeCount++;
            PermissionService.derRemove(modifyRemovedOnDerIds, "MODIFY", null, ugroupIds)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                } else {
                  $rootScope.isLoading = false;
                }
              });
          }

          if (viewAddedOnDerIds && viewAddedOnDerIds.length) {
            changeCount++;
            PermissionService.derAdd(viewAddedOnDerIds, "VIEW", null, ugroupIds)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                } else {
                  $rootScope.isLoading = false;
                }
              });
          }

          if (viewRemovedOnDerIds && viewRemovedOnDerIds.length) {
            changeCount++;
            PermissionService.derRemove(viewRemovedOnDerIds, "VIEW", null, ugroupIds)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                } else {
                  $rootScope.isLoading = false;
                }
              });
          }

          if (commentAddedOnDerIds && commentAddedOnDerIds.length) {
            changeCount++;
            PermissionService.derAdd(commentAddedOnDerIds, "COMMENT", null, ugroupIds)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                } else {
                  $rootScope.isLoading = false;
                }
              });
          }

          if (commentRemovedOnDerIds && commentRemovedOnDerIds.length) {
            changeCount++;
            PermissionService.derRemove(commentRemovedOnDerIds, "COMMENT", null, ugroupIds)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                } else {
                  $rootScope.isLoading = false;
                }
              });
          }

          if (changeCount) {
            ngToast.create({
              dismissOnTimeout: true,
              content: 'Dozvole ažurirane.',
              className: 'success'
            });
            changeCount = 0;
            $scope.DERlist();
          }
        };

        $scope.getMobileList = function () {
          AuthoringService.getDERMobileList()
            .then(function (res) {
              $rootScope.isLoading = false;
            });
        };

        $scope.DOMsearch = function (newSearchTerm) {

          var gotoUrl = getGotoUrl();

          if (newSearchTerm && newSearchTerm != "undefined") {
            $location.path(gotoUrl + "0/" + newSearchTerm);
          } else {
            $location.path(gotoUrl + "0");
          }

        };

        $scope.DOMsearchWithType = function (newSearchTerm, derTypeId) {

          if ($rootScope.userOrganization && $rootScope.userOrganization.organizationId) {
            var organizationId = $rootScope.userOrganization.organizationId;
          }
          if (organizationId === -1) {
            organizationId = null;
          }

          AuthoringService.listDER(newSearchTerm, true, derTypeId, 0, 500, false, null, false, false, null, null, null, false, organizationId)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.searchResultDers = res.data.items;
                if (res.data.totalItems === 0) {
                  $scope.noResults = true;
                } else {
                  $scope.noResults = false;
                  $scope.NoPages = Math.ceil(res.data.totalItems / itemsPerPage);

                  $scope.pages = [];
                  for (var i = 0; i < $scope.NoPages; i++) {
                    $scope.pages.push(i);
                  }
                }
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.DOMsearchChangeType = function () {
          var gotoUrl = getGotoUrl();
          if ($scope.currentSearchTerm) {
            $location.path(gotoUrl + "0/" + $scope.currentSearchTerm);
          } else {
            $location.path(gotoUrl + "0");
          }
        };

        $scope.DOMDosFilter = function () {
          var gotoUrl = getGotoUrl();
          $scope.paramChanged();

          if ($scope.currentSearchTerm) {
            $location.path(gotoUrl + "0/" + $scope.currentSearchTerm);
          } else {
            $location.path(gotoUrl + "0");
          }
        };

        $scope.DOMselect = function (derId) {
          $location.path('/knjiga/?derid=' + derId);
        };

        $scope.changePage = function (newPage) {
          var gotoUrl = getGotoUrl();

          if ($scope.currentSearchTerm) {
            $location.path(gotoUrl + newPage + "/" + $scope.currentSearchTerm);
          } else {
            $location.path(gotoUrl + newPage);
          }

        };

        var getGotoUrl = function () {
          var gotoUrl = '/knjige/';
          switch ($scope.searchFilter) {
            case "presentation":
              gotoUrl = '/prezentacije/';
              break;
            case "image":
              gotoUrl = '/slike/';
              break;
            case "video":
              gotoUrl = '/video-snimke/';
              break;
            case "audio":
              gotoUrl = '/audio-snimke/';
              break;
            case "test":
              gotoUrl = '/kvizovi/';
              break;
            case "text":
              gotoUrl = '/tekstovi/';
              break;
            case "module":
              gotoUrl = '/moduli/';
              break;
            case "dos":
              gotoUrl = '/dosevi/';
              break;
            case "list":
              gotoUrl = '/list/';
              break;
            case "geogebra":
              gotoUrl = '/interakcije/';
              break;
          }
          return gotoUrl;
        };

        //$scope.searchFilter = 'image';
        $scope.editorSearchTerm = null;
        $scope.editorCurrentPage = 0;

        $scope.initSearchFilter = function () {
          $scope.searchFilter = Search.getEditorSearchFilter();
          $scope.noResults = true;
        };

        $scope.initSearchFilterQuiz = function () {
          $scope.searchFilter = 'image';
          $scope.noResults = true;
        };

        $scope.quizSearchInit = function () {
          $scope.searchFilter = 'test';
          $scope.noResults = true;
        };

        $scope.editorSearch = function (newPage) {
          $scope.editorCurrentPage = newPage;
          AuthoringService.listDER($scope.editorSearchTerm, true, $scope.searchFilter, newPage, itemsPerPage, false, $scope.dos)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.ders = res.data.items;
                if (res.data.totalItems === 0) {
                  $scope.noResults = true;
                } else {
                  $scope.noResults = false;
                  $scope.NoPages = Math.ceil(res.data.totalItems / itemsPerPage);

                  $scope.pages = [];
                  for (var i = 0; i < $scope.NoPages; i++) {
                    $scope.pages.push(i);
                  }
                }
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.editorChangePage = function (newPage) {
          $scope.ders = {};
          $scope.editorSearch(newPage);
        };

        $scope.$on('filterType', function (event, args) {
          $scope.searchFilter = args.filter;
          $scope.currentPage = 0;
        });

        $scope.$on('resourceAdded', function (event, args) {
          $scope.DERlist();
        });

        //$scope.createDer = function(derTypeId, derId) {
        //	$scope.editMetadata('new', derTypeId, derId);
        //};

        $scope.editDer = function (derTypeId, derId, parentsDOS, parentDOSIndex, parentsModules, parentModuleIndex) {

          if (derTypeId === 'module') {
            var parentDOS = parentsDOS[parentDOSIndex];
          } else if (derTypeId === 'eBook') {
            var parentDOS = parentsDOS[parentDOSIndex];
            var parentModule = parentsModules[parentModuleIndex];
          }
          $scope.editMetadata('edit', derTypeId, derId, parentDOS, parentModule);
        };

        $scope.copyDer = function (derTypeId, derId) {
          $scope.editMetadata('copy', derTypeId, derId);
        };

        $scope.editMetadata = function (status, derTypeId, derId, parentDOS, parentModule) {
          ModalService.showModal({
            templateUrl: "/views/partials/bookOptionsModal.html",
            controller: "BookOptionsModalController",
            inputs: {
              modalTitle: status === 'copy' ? "Kopiranje" : "Postavke",
              status: status,
              style: null,
              visibility: null,
              copyright: null,
              bookTitle: null,
              description: null,
              ordering: null,
              derId: derId,
              type: null,
              currentImg: null,
              newParent: null,
              currentParentRelationId: null,
              derTypeId: derTypeId,
              parentDOS: parentDOS ? parentDOS : null,
              parentModule: parentModule ? parentModule : null
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (inputs) {
              if (inputs.save) {
                if (status && status === 'copy') {
                  AuthoringService.copyDER(derId, inputs.newParent.derId)
                    .then(function (res) {
                      if (res.err != 0) {
                        throw res;
                      }
                      return res;
                    })

                    .then(function (res) {
                      ngToast.create({
                        dismissOnTimeout: true,
                        content: 'Kopirano!',
                        className: 'success'
                      });
                    })

                    // ----- handle errors
                    .catch(function (err) {
                      console.log("Error copying book", err);
                    })

                    .finally(function () {
                      $rootScope.isLoading = false;
                    });

                } else {
                  // ----- update metadata
                  AuthoringService.updateMetadata(derId, inputs.bookTitle, inputs.visibility, inputs.style, inputs.copyright, inputs.lastVersion, inputs.description, null, inputs.ordering)
                    .then(function (res) {
                      if (res.err != 0) {
                        throw res;
                      }
                      return res;
                    })

                    // ----- add new parent relation
                    .then(function (newDer) {
                      if (inputs.newParent) {
                        return AuthoringService.addRelation(derId, newDer.data.lastVersion, [{
                          targetDerId: inputs.newParent.derId,
                          targetDerVersion: inputs.newParent.lastVersion,
                          relationTypeId: 'parent'
                        }]);
                      }
                    })
                    .then(function (res) {
                      if (res && res.err != 0) {
                        throw res;
                      }
                      return res;
                    })

                    // ----- remove existing parent relation
                    .then(function () {
                      if (inputs.currentParentRelationId) {
                        return AuthoringService.delRelation(derId, [inputs.currentParentRelationId]);
                      }
                    })
                    .then(function (res) {
                      if (res && res.err != 0) {
                        throw res;
                      }
                      return res;
                    })

                    // -- user notification
                    .then(function () {
                      ngToast.create({
                        dismissOnTimeout: true,
                        content: 'Spremljeno!',
                        className: 'success'
                      });
                    })

                    // ----- handle errors
                    .catch(function (err) {
                      console.log("Error saving book options", err);
                    })

                    .finally(function () {
                      $rootScope.isLoading = false;
                    });
                }
              }

            });
          });
        }

      }
    ])

    .controller('textAngularController', ['$scope', '$rootScope', '$sce', '$filter', '$routeParams', '$location', '$timeout', '$interval', '$window', '$anchorScroll', 'taApplyCustomRenderers', 'User', 'AuthoringService', 'ResourceService', 'ModalService', 'sweet', 'ngToast', 'modalService', 'Chapters', 'Page', 'FormulaData',
      function textAngularController($scope, $rootScope, $sce, $filter, $routeParams, $location, $timeout, $interval, $window, $anchorScroll, taApplyCustomRenderers, User, AuthoringService, ResourceService, ModalService, sweet, ngToast, modalService, Chapters, Page, FormulaData) {
        var deleteInProgress = false;
        var initLoad = true;
        var creatingFile = false;

        const autoSaveInterval = $rootScope.tenant === 'carnet' ? 300000 : 600000;

        $scope.currentImg = null;

        resetEditor();

        /**
         * DOM manipulations start
         */

        $scope.initEditor = function () {
          $scope.isReadOnly = false;
          //$scope.currentDerId = $routeParams.derId;
          $scope.currentDerId = parseInt($location.search().derid);
          $scope.DERget($scope.currentDerId);
          resetEditor();
        };

        $scope.resetView = function () {
          $scope.fromTheTop = true;
          $scope.prezLoaded = false;
        };

        $scope.newDialogShown = false;
        $scope.oldDialogShown = false;
        $scope.fromTheTop = true;
        $scope.prezLoaded = false;
        $scope.slideLoaded = false;

        $scope.newClicked = function () {
          $scope.newDialogShown = true;
          $scope.oldDialogShown = false;
          $scope.currentPrez = null;
          $scope.fromTheTop = false;
        };

        /*$scope.oldClicked = function () {
         $scope.newDialogShown = false;
         $scope.oldDialogShown = true;
         $scope.newTitle = null;
         $scope.fromTheTop = false;
         $scope.DERlist();
         };*/

        function resetEditor() {
          $scope.htmlContent = '';
          $scope.cssStyle = "noSpecialStyle";
          //$scope.safeHtml = '';
        }

        /**
         * DOM manipulations end
         */

        /* $scope.refreshView = function () {
         $scope.safeHtml = $sce.trustAsHtml(taApplyCustomRenderers($scope.htmlContent));
         console.log(taApplyCustomRenderers($scope.htmlContent));
         }; */

        $scope.changeStyle = function (style) {
          $scope.chosenStyle = style;
          switch (style) {
            case "stil.0":
              $scope.bookStyleClass = "bookstyle00";
              break;
            case "stil.1":
              $scope.bookStyleClass = "bookstyle01";
              break;
            case "stil.2":
              $scope.bookStyleClass = "bookstyle02";
              break;
          }
        };




        $scope.loadSegment = function (index) {

          //  if (index !== $scope.currentSegmentIndex) {
          $rootScope.$broadcast('clearUndoBuffer');
          // }

          if (!currentSegmentDeleting && !initLoad) {
            // autosave($scope.currentSegmentIndex);
          }
          currentSegmentDeleting = false;
          initLoad = false;

          angular.forEach($scope.current.segments, function (s) {
            if (s.ordering === index) {
              //$scope.currentSegment = s;
              // $scope.htmlContent = s.content;
              $rootScope.currentSegmentIndex = index;

              $scope.currentSegmentContentOnLoad = angular.copy($scope.current.segments[$rootScope.currentSegmentIndex].content);
              $scope.currentSegmentTitleOnLoad = angular.copy($scope.current.segments[$rootScope.currentSegmentIndex].title);
              //TODO: treba li focusati i kad se promijeni poglavlje?
              //$scope.focusedIndex = index;
              $scope.isQuiz = false;
              if ($scope.current.segments[$rootScope.currentSegmentIndex].content.indexOf("ThisIsAQuiz") > -1) {
                $scope.isQuiz = true;
              }
            }
          });


        };

        $scope.DERget = function (derId, derVersion) {
          Chapters.setChapters(null);
          AuthoringService.getDER(derId, derVersion, true, true, false, 2)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.current = res.data;
                $scope.current.lastVersion = res.data.lastVersion;
                Chapters.setNewDerVersion(res.data.lastVersion);
                //if ($scope.current.permissions || $scope.current.canEdit) {
                //  $scope.isReadOnly = false;
                //}
                //if ($scope.current.derInfo.derTypeId === 'eBook') {
                //  checkIfSegmentsChanged();
                //}
                $scope.prezLoaded = true;
                $scope.slideLoaded = false;
                resetEditor();
                if (res.data.derInfo.copyright) {
                  $scope.chosenCopyright = res.data.derInfo.copyright.copyrightId;
                }
                $scope.chosenVisibility = res.data.visibility;
                if (res.data.derInfo) {
                  $scope.changeStyle(res.data.derInfo.derStyleId);
                }

                $rootScope.currentSegmentIndex = 0;

                if (res.data.images) {
                  angular.forEach(res.data.images, function (i) {
                    if (i.relationTypeId == 'cover.web') {
                      $scope.currentImg = i.urlRemote;
                    }

                  });
                }
                var chapters = [];
                angular.forEach($scope.current.segments, function (s) {
                  chapters.push({
                    title: s.title,
                    ordering: s.ordering,
                    derSegmentId: s.derSegmentId
                  });
                });
                Chapters.setChapters(chapters);

                Page.setTitle($scope.current.derInfo.title + ' | DOS uređivač');
                $scope.loadSegment(0);

              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.DERupdateVersionAfterSave = function (derId, derVersion) {
          AuthoringService.getDER(derId, derVersion, true, false, false)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.current = res.data;
                $scope.current.lastVersion = res.data.lastVersion;
                Chapters.setNewDerVersion(res.data.lastVersion);
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        var show = true;
        $scope.toggleHTMLvisibility = function () {
          if (show) {
            $('#canEditHTML').show();
          } else {
            $('#canEditHTML').hide();
          }
          show = !show;
        };

        $scope.DERdelete = function (derId, lastVersion) {
          deleteInProgress = true;
          sweet.show({
            title: 'Hmmm...',
            text: 'Želite li stvarno obrisati cijeli sadržaj (i sve sadržaje koji se nalaze ispod u hijerarhiji)?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: "Da, želim obrisati!",
            closeOnConfirm: false
          }, function () {

            AuthoringService.deleteDER(derId, lastVersion)
              .then(function (res) {
                if (res.err === 0) {
                  $rootScope.isLoading = false;
                  sweet.show('Uspjeh!', 'Brisanje obavljeno.', 'success');
                  //ngToast.create({dismissOnTimeout: true, content: 'Brisanje uspješno obavljeno!', className: 'success'});
                  $scope.current = null;
                  $location.path("/list");
                } else {
                  $rootScope.isLoading = false;
                }
              })

          });
        };


        $scope.segmentCreating = false;

        var segmentCreateClicked = function () {
          $scope.segmentCreating = true;
          $timeout(function () {
            $scope.segmentCreating = false;
          }, 1000);
        };

        $scope.segmentCopy = function (s) {
          var newSegmentOrder = 0;
          if ($scope.current.segments.length > 0) {
            angular.forEach($scope.current.segments, function (s) {
              if (s.ordering > newSegmentOrder) {
                newSegmentOrder = s.ordering;
              }
            });
            newSegmentOrder = newSegmentOrder + 1;
          }

          var contentTags = s.contentTags;
          if (!s.contentTags || !s.contentTags.length) {
            contentTags = null;
          } else {
            contentTags = s.contentTags[0];
          }

          AuthoringService.createSegment($scope.current.derId, newSegmentOrder, s.title, s.content, Chapters.getNewDerVersion(), contentTags)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.isQuiz = false;
                if (res.data.content.indexOf("ThisIsAQuiz") > -1) {
                  $scope.isQuiz = true;
                }
                var newSegment = {
                  derSegmentId: res.data.derSegmentId,
                  title: res.data.title,
                  content: res.data.content,
                  ordering: res.data.ordering,
                  contentTags: res.data.contentTags,
                  segmentBorders: res.data.segmentBorders
                };
                $scope.htmlContent = res.data.content;
                $scope.current.segments.push(newSegment);
                $scope.slideLoaded = true;
                $scope.current.lastVersion = res.data.lastVersion;
                Chapters.setNewDerVersion(res.data.lastVersion);
                $scope.current.createdOn = res.data.createdOn;

                $rootScope.currentSegmentIndex = $scope.current.segments.length - 1;
                var chapters = [];
                angular.forEach($scope.current.segments, function (s) {
                  chapters.push({
                    title: s.title,
                    ordering: s.ordering,
                    derSegmentId: s.derSegmentId
                  });
                });
                Chapters.setChapters(chapters);
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.segmentCreate = function (quizHtml, geogebraHtml, title) {

          segmentCreateClicked();

          //  AuthoringService.updateSegment($scope.current.derId, $scope.current.segments[$rootScope.currentSegmentIndex].derSegmentId, $scope.current.segments[$rootScope.currentSegmentIndex].ordering, $scope.current.segments[$rootScope.currentSegmentIndex].title, $scope.current.segments[$rootScope.currentSegmentIndex].content, Chapters.getNewDerVersion(), $scope.current.segments[$rootScope.currentSegmentIndex].contentTags)
          //   .then(function (res2) {

          //  if (res2.err == 0) {
          if (!creatingFile) {
            $rootScope.isLoading = false;
          }
          // $scope.current.segments[$rootScope.currentSegmentIndex].derSegmentId = res2.data.derSegmentId;

          // ngToast.create({
          //      dismissOnTimeout: true,
          //      content: 'Automatski spremljeno!',
          //      className: 'success'
          //    });

          // $scope.current.lastVersion = res2.data.lastVersion;
          // Chapters.setNewDerVersion(res2.data.lastVersion);
          //  $scope.current.createdOn = res2.data.createdOn;
          resetEditor();


          if (quizHtml && title) {
            $scope.htmlContent = quizHtml;
            $scope.segmentTitle = title;
            var contentTags = "script";
          } else if (quizHtml) {
            $scope.segmentTitle = "Interakcija srednje razine";
            $scope.htmlContent = quizHtml;
            var contentTags = "script";
          } else if (geogebraHtml) {
            $scope.segmentTitle = "Interakcija visoke razine";
            $scope.htmlContent = geogebraHtml;
            var contentTags = "script";
          } else {
            $scope.segmentTitle = "Tekst";
            //$scope.htmlContent = "<p>Kliknite da biste uredili poglavlje...</p>";
            var contentTags = null;
          }

          if (!$scope.current.segments) {
            $scope.current.segments = [];
          }

          var newSegmentOrder = 0;
          if ($scope.current.segments.length > 0) {
            angular.forEach($scope.current.segments, function (s) {
              if (s.ordering > newSegmentOrder) {
                newSegmentOrder = s.ordering;
              }
            });
            newSegmentOrder = newSegmentOrder + 1;
          }
          AuthoringService.createSegment($scope.current.derId, newSegmentOrder, $scope.segmentTitle, $scope.htmlContent, Chapters.getNewDerVersion(), contentTags)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.isQuiz = false;
                if (res.data.content.indexOf("ThisIsAQuiz") > -1) {
                  $scope.isQuiz = true;
                }
                var newSegment = {
                  derSegmentId: res.data.derSegmentId,
                  title: res.data.title,
                  content: res.data.content,
                  ordering: res.data.ordering,
                  contentTags: res.data.contentTags,
                  segmentBorders: res.data.segmentBorders
                };
                $scope.htmlContent = res.data.content;
                $scope.current.segments.push(newSegment);
                $scope.slideLoaded = true;
                $scope.current.lastVersion = res.data.lastVersion;
                Chapters.setNewDerVersion(res.data.lastVersion);
                $scope.current.createdOn = res.data.createdOn;

                $rootScope.currentSegmentIndex = $scope.current.segments.length - 1;
                var chapters = [];
                angular.forEach($scope.current.segments, function (s) {
                  chapters.push({
                    title: s.title,
                    ordering: s.ordering,
                    derSegmentId: s.derSegmentId
                  });
                });
                Chapters.setChapters(chapters);
                $scope.DERsave();
              } else {
                $rootScope.isLoading = false;
              }
            });
          //  } else {
          //    $rootScope.isLoading = false;
          //  }
          //  })
        };

        /* $scope.onKeyPress = function ($event) {
         alert(" (keyCode: " + (window.event ? $event.keyCode : $event.which) + ")");
         };
         */
        $scope.hideSaveAlert = function () {
          $rootScope.saveInProgress = false;
        };



        $scope.DERsave = function (versionComment) {

          $rootScope.saveInProgress = true;

          var DERarray = [];
          for (var i = 0; i < $scope.current.segments.length; i++) {

            var segmentToSave = {
              "derId": $scope.current.derId,
              "derSegmentId": $scope.current.segments[i].derSegmentId,
              "ordering": $scope.current.segments[i].ordering,
              "title": $scope.current.segments[i].title,
              "content": $scope.current.segments[i].content,
              "glossaryTerm": $scope.current.segments[i].glossaryTerm,
              "audioDerRelationId": $scope.current.segments[i].audioDerRelationId,
              "lastVersion": Chapters.getNewDerVersion(),
              "forceOverwrite": true,
              "contentTags": $scope.current.segments[i].contentTags,
              "descriptionOfVersion": versionComment,
              "segmentBorders": $scope.current.segments[i].segmentBorders ? $scope.current.segments[i].segmentBorders : "CLOSED"
            };
            DERarray.push(segmentToSave);
          }
          AuthoringService.updateAllSegments(DERarray)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $timeout(function () {
                  $rootScope.saveInProgress = false;
                }, 200);
                for (var i = 0; i < $scope.current.segments.length; i++) {
                  $scope.current.segments[i].derSegmentId = res.data[i].derSegmentId;
                }
                //$scope.current.segments = res.data;
                Chapters.setNewDerVersion(res.data[0].lastVersion);
                //dohvat kako bi se obnovila current verzija
                if (versionComment) {
                  $scope.DERupdateVersionAfterSave($scope.currentDerId);
                }
              }
            });

        };

        $scope.segmentSave = function (isAuto, quizHtml, segmentToSaveIndex, versionComment, pushToQuiz) {

          if (isAuto === 'false') {
            isAuto = false;
          }

          if (!segmentToSaveIndex && segmentToSaveIndex !== 0) {
            segmentToSaveIndex = $rootScope.currentSegmentIndex;
          }

          var ord = $scope.current.segments[segmentToSaveIndex].ordering;


          if (quizHtml) {
            $scope.current.segments[segmentToSaveIndex].content = quizHtml;
          }

          var html = $scope.current.segments[segmentToSaveIndex].content;
          var title = $scope.current.segments[segmentToSaveIndex].title;

          if (isAuto) {
            versionComment = null;
          } else if (!versionComment) {
            versionComment = "Spremljeno bez komentara";
          }

          var newDerVersion = Chapters.getNewDerVersion();
          if (newDerVersion > $scope.current.lastVersion) {
            $scope.current.lastVersion = newDerVersion;
            //Chapters.setNewDerVersion(null);
          }

          AuthoringService.updateSegment($scope.current.derId, $scope.current.segments[segmentToSaveIndex].derSegmentId, ord, title, html, Chapters.getNewDerVersion(), $scope.current.segments[segmentToSaveIndex].contentTags, versionComment)
            .then(function (res) {

              if (res.err == 0) {
                if (!creatingFile) {
                  $rootScope.isLoading = false;
                }
                $scope.current.segments[segmentToSaveIndex].derSegmentId = res.data.derSegmentId;

                if (!isAuto && $scope.current.lastVersion == res.data.lastVersion) {
                  AuthoringService.updateMetadata($scope.current.derId, $scope.current.derInfo.title, $scope.current.visibility, $scope.current.derInfo.derStyleId, $scope.current.derInfo.copyright.copyrightId, $scope.current.lastVersion, $scope.current.derInfo.description, versionComment, $scope.current.derInfo.ordering)
                    .then(function (res2) {
                      if (res2.err == 0) {
                        $rootScope.isLoading = false;
                        $scope.current.lastVersion = res2.data.derVersion;
                        Chapters.setNewDerVersion(res2.data.lastVersion);
                        $scope.current.createdOn = res2.data.createdOn;
                      } else {
                        $rootScope.isLoading = false;
                      }
                    });
                } else {
                  $scope.current.lastVersion = res.data.lastVersion;
                  Chapters.setNewDerVersion(res.data.lastVersion);
                  $scope.current.createdOn = res.data.createdOn;
                }
                if (isAuto) {
                  ngToast.create({
                    dismissOnTimeout: true,
                    content: 'Automatski spremljeno!',
                    className: 'success'
                  });
                } else {
                  ngToast.create({
                    dismissOnTimeout: true,
                    content: 'Spremljeno!',
                    className: 'success'
                  });
                }

                var chapters = [];
                angular.forEach($scope.current.segments, function (s) {
                  chapters.push({
                    title: s.title,
                    ordering: s.ordering,
                    derSegmentId: s.derSegmentId
                  });
                });
                Chapters.setChapters(chapters);

              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        var currentSegmentDeleting = false;
        $scope.segmentDelete = function (s) {

          if (s.content == "<p>Kliknite da biste uredili element...</p>") {
            segmentDeleteConfirmed(s);

          } else {
            sweet.show({
              title: 'Hmmm...',
              text: 'Želite li stvarno obrisati element?',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: "Da, želim obrisati!",
              closeOnConfirm: true
            }, function () {

              segmentDeleteConfirmed(s);
            })
          }
        };

        var segmentDeleteConfirmed = function (s) {

          /*var hasRelations = true;
           var content = s.content;
           var relationId = null;
           var relationIds = [];
           while (hasRelations) {
           relationId = getRelationId(content);
           if (isNaN(relationId) || !relationId) {
           hasRelations = false;
           }
           else {
           relationIds.push(relationId);
           content = content.replace('data-relation-id="' + relationId, "");
           }
           }
  
           if (relationIds.length > 0) {
           AuthoringService.delRelation($scope.current.derId, relationIds, Chapters.getNewDerVersion())
           .then(function (res) {
           if (res.err == 0) {
           segmentDeleteNoRelations(s);
           Chapters.setNewDerVersion(res.data.lastVersion);
           } else {
           $rootScope.isLoading = false;
           }
           });
           } else {
  
           }
           */
          segmentDeleteNoRelations(s);
        };


        var segmentDeleteNoRelations = function (s) {

          $rootScope.saveInProgress = true;

          var DERarray = [];
          for (var i = 0; i < $scope.current.segments.length; i++) {

            var segmentToSave = {
              "derId": $scope.current.derId,
              "derSegmentId": $scope.current.segments[i].derSegmentId,
              "ordering": $scope.current.segments[i].derSegmentId.ordering,
              "title": $scope.current.segments[i].title,
              "glossaryTerm": $scope.current.segments[i].glossaryTerm,
              "audioDerRelationId": $scope.current.segments[i].audioDerRelationId,
              "content": $scope.current.segments[i].content,
              "lastVersion": Chapters.getNewDerVersion(),
              "forceOverwrite": true,
              "contentTags": $scope.current.segments[i].contentTags,
              "descriptionOfVersion": "Spremanje pri brisanju elementa",
              "segmentBorders": $scope.current.segments[i].segmentBorders ? $scope.current.segments[i].segmentBorders : "CLOSED"
            };
            DERarray.push(segmentToSave);
          }
          AuthoringService.updateAllSegments(DERarray)
            .then(function (res2) {

              if (res2.err === 0) {
                for (var i = 0; i < $scope.current.segments.length; i++) {
                  if (s.derSegmentId === $scope.current.segments[i].derSegmentId) {
                    s.derSegmentId = res2.data[i].derSegmentId;
                  }
                  $scope.current.segments[i].derSegmentId = res2.data[i].derSegmentId;
                }
                Chapters.setNewDerVersion(res2.data.lastVersion);
                AuthoringService.deleteSegment($scope.current.derId, s.derSegmentId, Chapters.getNewDerVersion())
                  .then(function (res) {

                    if (res.err === 0) {
                      $rootScope.isLoading = false;
                      $timeout(function () {
                        $rootScope.saveInProgress = false;
                      }, 100);
                      var index = $scope.current.segments.indexOf(s);
                      if (index > -1) {
                        $scope.current.segments.splice(index, 1);
                      }
                      $scope.current.lastVersion = res.data.lastVersion;
                      Chapters.setNewDerVersion(res.data.lastVersion);
                      $scope.current.createdOn = res.data.createdOn;


                      if (index == $rootScope.currentSegmentIndex) {
                        currentSegmentDeleting = true;
                        $scope.loadSegment(0);
                      }

                      if ($scope.current.segments == null) {
                        $scope.current.segments = [];
                      }

                      /*
                       if (index < $rootScope.currentSegmentIndex) {
                       $scope.loadSegment($rootScope.currentSegmentIndex - 1);
  
                       }*/
                      reorder($rootScope.currentSegmentIndex);

                    } else {
                      $rootScope.isLoading = false;
                      currentSegmentDeleting = false;
                    }
                  });

              }
            });
        };



        $scope.openDiffModal = function (segment, currentDer) {

          ModalService.showModal({
            templateUrl: "/views/partials/diffModal.html",
            controller: "DiffModalController",
            inputs: {
              derSegment: segment,
              currentDer: currentDer
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              //nothing for now
            });
          });
        };

        $scope.changeNesting = function (i, segmentBorders) {
          $scope.current.segments[i].segmentBorders = segmentBorders;
        };


        $scope.showPreview = function () {

          AuthoringService.getPreview($scope.current.derId, Chapters.getNewDerVersion())
            .then(function (res) {
              $rootScope.isLoading = false;
              res.data = res.data.replace(new RegExp('<p id="resultsModal" hidden ', 'g'), '<p id="resultsModal" ');
              res.data = res.data.replace(new RegExp('"quiz_', 'g'), '"previewQuiz_');
              res.data = res.data.replace(new RegExp("'quiz_", 'g'), "'previewQuiz_");
              res.data = res.data.replace(new RegExp("sortable1", 'g'), "previewQuiz_sortable1");
              res.data = res.data.replace(new RegExp("sortable2", 'g'), "previewQuiz_sortable2");
              res.data = res.data.replace(new RegExp("_questionId_", 'g'), "_playQuizQuestionId_");
              //res.data = res.data.replace(new RegExp("#", 'g'), "&#35;");

              // TODO HH: added temporary, remove l8er
              // use only for local testing, comment for test or production
              /*if ($rootScope.server.indexOf("localhost") !== -1) {
                res.data = res.data.replace(new RegExp("10.7.71.21", 'g'), "localhost:55356");
                res.data = res.data.replace(new RegExp("explain/static/preview/1.1", 'g'), "preview");
              }*/

              var trustedHTML = $sce.trustAsResourceUrl('data:text/html;charset=utf-8,' + res.data);
              $scope.previewHTML = trustedHTML;

              var isChrome = !!window.chrome && !!window.chrome.webstore;

              //if (!isChrome){
              //console.log("data:text/html;charset=utf-8," +  res.data);
              //window.open("data:text/html;charset=utf-8," +  res.data, "", "_blank");

              var newWindow = window.open();
              newWindow.document.write(res.data);
              newWindow.document.close();


              /*ModalService.showModal({
                 templateUrl: "/views/partials/previewModal.html",
                 controller: "PreviewModalController",
                 inputs: {
                   previewHTML: res.data
                 }
               }).then(function (modal) {
                 modal.element.modal();
                 modal.close.then(function (res) {
 
                 });
               });*/
              //}
            })

        };


        $scope.editOutcomes = function () {

          ModalService.showModal({
            templateUrl: "/views/partials/outcomeModal.html",
            controller: "OutcomeModalController",
            inputs: {
              der: $scope.current
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {

            });
          });

        };


        $window.triggerFormulaDataSave = function (mathML) {
          FormulaData.setMathML(mathML);
        };

        $window.getInitFormulaData = function () {
          return FormulaData.getAndResetOldMathML();
        };

        $window.resetFormulaDataElement = function () {
          return FormulaData.resetElement();
        };

        $scope.nestingTypes = [{
            id: "CLOSED",
            label: "Element nema ugnježđivanje"
          },
          {
            id: "NESTED",
            label: "Element je ugnježđen u drugi element"
          },
          {
            id: "CLOSED",
            label: "Element ima ugnježđeni element i nakon i prije sebe"
          },
          {
            id: "CLOSED",
            label: "Element ima ugnježđeni element samo nakon sebe"
          },
          {
            id: "CLOSED",
            label: "Element ima ugnježđeni element samo prije sebe"
          }
        ];

        if ($rootScope.tenant !== 'carnet') {
          $scope.titles = [{
              id: "DOSTEXT",
              label: "Tekst"
            },
            {
              id: "DOSPODN",
              label: "Podnaslov"
            },
            {
              id: "DOSMOT",
              label: "Uvodni dio"
            },
            {
              id: "DOSDEF",
              label: "Definicija"
            },
            {
              id: "DOSSLIKA",
              label: "Slika"
            },
            {
              id: "DOSAUDIO",
              label: "Audio"
            },
            {
              id: "DOSVIDEO",
              label: "Video"
            },
            {
              id: "DOSPRIMJER",
              label: "Primjer"
            },
            {
              id: "DOSZAD",
              label: "Zadatak u tekstu"
            },
            {
              id: "DOSBLOKZ",
              label: "Blok zadataka"
            },
            //{id: "DOSINT-S", label: "Interakcija srednje razine"},
            //{id: "DOSINT-V", label: "Interakcija visoke razine"},
            {
              id: "DOSRJ",
              label: "Rješenje"
            },
            {
              id: "DOSFORM",
              label: "Istaknuta formula"
            },
            {
              id: "DOSTVRD",
              label: "Istaknuta tvrdnja"
            },
            {
              id: "DOSZAN",
              label: "Zanimljivost"
            },
            {
              id: "DOSKOR",
              label: "Korelacija"
            },
            {
              id: "DOSKRAJ",
              label: "Završni dio"
            },
            {
              id: "DOSPOKUS",
              label: "Pokus"
            },
            {
              id: "DOSPRAKT",
              label: "Praktična vježba"
            },
            {
              id: "DOSIZRADI",
              label: "Izradi vježbu"
            },
            {
              id: "DOSVISE",
              label: "Za one koji žele znati više"
            },
            {
              id: "DOSDOD",
              label: "Dodatna tema"
            },
            {
              id: "DOSIZB",
              label: "Izborna tema"
            },
            {
              id: "DOSPROJEKT",
              label: "Projekt"
            },
            {
              id: "PROCJENA",
              label: "Procjena znanja"
            },
            {
              id: "DOSNAP",
              label: "Napomena"
            }
          ];
        } else if ($rootScope.tenant === 'carnet') {
          $scope.titles = [{
              id: "DOSTEXT",
              label: "Tekst"
            },
            {
              id: "DOSMOT",
              label: "Uvodni dio"
            },
            {
              id: "DOSPODN",
              label: "Podnaslov"
            },
            {
              id: "DOSDEF",
              label: "Definicija"
            },
            {
              id: "DOSVISE",
              label: "Za one koji žele znati više"
            },
            {
              id: "DOSSLIKA",
              label: "Slika"
            },
            {
              id: "DOSAUDIO",
              label: "Audio"
            },
            {
              id: "DOSVIDEO",
              label: "Video"
            },
            //{id: "DOSINT-S", label: "Interakcija srednje razine"},
            //{id: "DOSINT-V", label: "Interakcija visoke razine"},
            {
              id: "DOSIGRA",
              label: "Igra"
            },
            {
              id: "DOSKLIZAC",
              label: "Klizač"
            },
            {
              id: "DOSKRAJ",
              label: "Završni dio"
            },
            {
              id: "PROCJENA",
              label: "Procjena znanja"
            }
          ];
        }

        if ($rootScope.tenant === 'algebra') {
          $scope.titles.push({
            id: "FLASHCARDFRONT",
            label: "Flash card - front"
          });
          $scope.titles.push({
            id: "FLASHCARDBACK",
            label: "Flash card - back"
          });
          $scope.titles.push({
            id: "DOSISTRAZIMO",
            label: "Istražimo"
          });
        }

        $scope.DERGetFile = function (derId, derVersion, type) {
          switch (type) {
            case 'epub':
              if ($scope.current.files[0].contentTypeId === 'epub') {
                if ($scope.current.files[0].drmType === 'web-accessible') {
                  downloadFileFromOpenUrl($scope.current.files[0].urlRemote, $scope.current.derInfo.title + '.epub');
                } else {
                  downloadFile($scope.current.files[0].urlRemote);
                }
              } else if ($scope.current.files[1].contentTypeId === 'epub') {
                if ($scope.current.files[1].drmType === 'web-accessible') {
                  downloadFileFromOpenUrl($scope.current.files[1].urlRemote, $scope.current.derInfo.title + '.epub');
                } else {
                  downloadFile($scope.current.files[1].urlRemote);
                }
              }
              break;
            case 'pdf':
              if ($scope.current.files[0].contentTypeId === 'pdf') {
                if ($scope.current.files[0].drmType === 'web-accessible') {
                  downloadFileFromOpenUrl($scope.current.files[0].urlRemote, $scope.current.derInfo.title + '.pdf');
                } else {
                  downloadFile($scope.current.files[0].urlRemote);
                }
              } else if ($scope.current.files[1].contentTypeId === 'pdf') {
                if ($scope.current.files[1].drmType === 'web-accessible') {
                  downloadFileFromOpenUrl($scope.current.files[1].urlRemote, $scope.current.derInfo.title + '.pdf');
                } else {
                  downloadFile($scope.current.files[1].urlRemote);
                }
              }
              break;
            case 'scorm':
              downloadFile($rootScope.server + '/der/file/downloadscorm?derId=' + derId + '&derVersion=' + derVersion);
              break;
          }

        };

        $scope.dragControlListeners = {
          accept: function (sourceItemHandleScope, destSortableScope) {
            if ($scope.current) {
              return true
            } else {
              return false
            }
          }, //override to determine drag is allowed or not. default is true.
          itemMoved: function (event) {},
          orderChanged: function (event) {
            reorder(event.dest.index);

          }
        };

        function reorder(index) {

          var newOrdering = [];
          var n = null;
          var i = 0;
          var newsegments = [];

          angular.forEach($scope.current.segments, function (s) {
            n = {
              "derSegmentId": s.derSegmentId,
              "ordering": i
            };
            newOrdering.push(n);
            newsegments.push(s);
            newsegments[i].ordering = i;
            i++;
          });
          AuthoringService.reorderSegments($scope.current.derId, Chapters.getNewDerVersion(), newOrdering)
            .then(function (res) {

              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.current.segments = newsegments;
                $scope.current.lastVersion = res.data.lastVersion;
                Chapters.setNewDerVersion(res.data.lastVersion);
                $scope.current.createdOn = res.data.createdOn;
                $scope.DERget($scope.current.derId);

              } else {
                $rootScope.isLoading = false;
              }
            })
        }


        $scope.moveToOrdering = function (currentIndex, newIndexString) {
          var newIndex = parseInt(newIndexString);
          if (typeof newIndex == "number" && currentIndex !== newIndex && newIndex < $scope.current.segments.length && newIndex > -1) {
            $rootScope.isLoading = true;
            var oldIndex = newIndex;
            for (var k = 0; k < $scope.current.segments.length; k++) {
              if ($scope.current.segments[k].ordering === currentIndex) {
                oldIndex = k;
                break;
              }
            }

            var segmentToMove = angular.copy($scope.current.segments[oldIndex]);
            var newSegmentsOrder = [];
            if (oldIndex < newIndex) {
              for (var i = 0; i < $scope.current.segments.length; i++) {
                if (i < oldIndex) {
                  newSegmentsOrder.push($scope.current.segments[i]);
                } else if (i >= oldIndex && i < newIndex) {
                  newSegmentsOrder.push($scope.current.segments[i + 1]);
                } else if (i === newIndex) {
                  newSegmentsOrder.push(segmentToMove);
                } else if (i > newIndex) {
                  newSegmentsOrder.push($scope.current.segments[i]);
                }
              }
            } else if (newIndex < oldIndex) {
              for (var j = 0; j < $scope.current.segments.length; j++) {
                if (j < newIndex) {
                  newSegmentsOrder.push($scope.current.segments[j]);
                } else if (j === newIndex) {
                  newSegmentsOrder.push(segmentToMove);
                } else if (j > newIndex && j <= oldIndex) {
                  newSegmentsOrder.push($scope.current.segments[j - 1]);
                } else if (j > oldIndex) {
                  newSegmentsOrder.push($scope.current.segments[j]);
                }
              }
            }
            $scope.current.segments = angular.copy(newSegmentsOrder);
            reorder();
          }
        };


        $scope.gotoAnchor = function (x, event) {
          event.preventDefault();
          event.stopPropagation();
          var newHash = 'dos-editor' + x;
          if ($location.hash() !== newHash) {
            // set the $location.hash to `newHash` and
            // $anchorScroll will automatically scroll to it
            $location.hash('dos-editor' + x);
          } else {
            // call $anchorScroll() explicitly,
            // since $location.hash hasn't changed
            $anchorScroll();
          }
        };

        $scope.createDer = function (derTypeId) {
          $scope.editMetadata('new', derTypeId);
        };

        $scope.editMetadata = function (status, derTypeId) {

          if (!$scope.currentImg && $scope.current && $scope.current.images && $scope.current.images.length > 1) {
            $scope.currentImg = $scope.current.images[0].type === 'web' ? $scope.current.images[0].url : $scope.current.images[1].url;
          }

          ModalService.showModal({
            templateUrl: "/views/partials/bookOptionsModal.html",
            controller: "BookOptionsModalController",
            inputs: {
              modalTitle: status === 'new' ? "Stvaranje novog sadržaja" : "Postavke",
              style: status === 'new' ? null : $scope.current.derInfo.derStyleId,
              visibility: status === 'new' ? null : $scope.current.visibility,
              copyright: status === 'new' ? null : $scope.current.derInfo.copyright.copyrightId,
              bookTitle: status === 'new' ? null : $scope.current.derInfo.title,
              description: status === 'new' ? null : $scope.current.derInfo.description,
              ordering: status === 'new' ? null : $scope.current.derInfo.ordering,
              derId: status === 'new' ? null : $scope.derId,
              type: status,
              currentImg: status === 'new' ? null : $scope.currentImg,
              status: status,
              derTypeId: derTypeId,
              parentDOS: status === 'new' ? null : $scope.current.parents[0],
              parentModule: null
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (inputs) {

              var CONTRIBUTORID = 2;
              if ($rootScope.relatedContributors && $rootScope.relatedContributors[0]) {
                CONTRIBUTORID = $rootScope.relatedContributors[0].contributorId;
              } else {
                CONTRIBUTORID = {
                  firstname: User.getFirstname(),
                  lastname: User.getLastname()
                };
              }

              if (status === 'new' && inputs.save && inputs.bookTitle) {
                if (derTypeId === 'eBook') {
                  // create ebook
                  AuthoringService.createMetadata("eBook", inputs.bookTitle, inputs.visibility, inputs.style, inputs.copyright, CONTRIBUTORID, null, inputs.description, inputs.ordering)
                    .then(function (res) {
                      if (res.err !== 0) {
                        throw res;
                      }
                      return res.data;
                    })

                    // ----- create parent relation
                    .then(function (der) {
                      return AuthoringService.addRelation(der.derId, der.lastVersion, [{
                        targetDerId: inputs.module.derId,
                        targetDerVersion: inputs.module.lastVersion,
                        relationTypeId: 'parent'
                      }]);
                    })
                    .then(function (res) {
                      if (res.err !== 0) {
                        throw res;
                      }
                      return res.data;
                    })

                    // ----- create segment
                    .then(function (relation) {
                      return AuthoringService.createSegment(relation.derId, 0, " ", "<p>&nbsp;</p>", relation.lastVersion)
                    })
                    .then(function (res) {
                      if (res.err !== 0) {
                        throw res;
                      }
                      return res.data;
                    })

                    // ----- go to new eBook
                    .then(function (segment) {
                      var chapters = [];
                      chapters.push({
                        title: " ",
                        ordering: 0,
                        derSegmentId: segment.derSegmentId
                      });
                      Chapters.setChapters(chapters);

                      $location.path("/knjiga/").search({
                        derid: segment.derId
                      });
                      sweet.show('Super!', 'Jedinica je kreirana', 'success');
                    })

                    .finally(function () {
                      $rootScope.isLoading = false;
                    });
                } else if (derTypeId === 'module') {
                  // ------ create module
                  AuthoringService.createMetadata("module", inputs.bookTitle, inputs.visibility, inputs.style, inputs.copyright, CONTRIBUTORID, null, inputs.description, inputs.ordering)
                    .then(function (res) {
                      if (res.err !== 0) {
                        throw res;
                      }
                      return res.data;
                    })

                    // ----- create parent relation
                    .then(function (der) {
                      return AuthoringService.addRelation(der.derId, der.lastVersion, [{
                        targetDerId: inputs.dos.derId,
                        targetDerVersion: inputs.dos.lastVersion,
                        relationTypeId: 'parent'
                      }])
                    })
                    .then(function (res) {
                      if (res.err !== 0) {
                        throw res;
                      }
                      return res.data;
                    })

                    // ----- go to new module
                    .then(function (relation) {
                      $location.path("/modul/").search({
                        derid: relation.derId
                      });

                      sweet.show('Super!', 'Modul je kreiran', 'success');
                    })

                    .finally(function () {
                      $rootScope.isLoading = false;
                    });
                } else if (derTypeId === 'dos') {
                  // ------ create module
                  AuthoringService.createMetadata("dos", inputs.bookTitle, inputs.visibility, inputs.style, inputs.copyright, CONTRIBUTORID, null, inputs.description)
                    .then(function (res) {
                      if (res.err !== 0) {
                        throw res;
                      }
                      return res.data;
                    })

                    // ----- go to new module
                    .then(function (relation) {
                      $location.path("/dos/").search({
                        derid: relation.derId
                      });

                      sweet.show('Super!', 'DOS je kreiran', 'success');
                    })

                    .finally(function () {

                      $rootScope.isLoading = false;
                    });
                }
              }
              /*else if (status === 'der') {
                if (res.save) {
  
                  AuthoringService.updateMetadata($scope.current.derId, res.bookTitle, res.visibility, res.style, res.copyright, $scope.current.lastVersion, res.description)
                    .then(function (res2) {
  
                      if (res2.err == 0) {
                        $rootScope.isLoading = false;
                      $scope.current.visibility = res.visibility;
                      $scope.current.derInfo.copyright.copyrightId = res.copyright;
                      $scope.current.derInfo.derStyleId = res.style;
                      $scope.current.derInfo.title = res.bookTitle;
                      $scope.current.derInfo.description = res.description;
                      $scope.changeStyle(res.style);
                      if (res.inputImgResizedWEB && res.inputImgResizedMOB) {
                        $scope.isNew = false;
                        uploadFile(res.inputImgResizedWEB, res.inputImgResizedMOB, res.originalImg, res.bookTitle, CONTRIBUTORID);
                        $scope.currentImg = res.inputImgResizedWEB;
                      }
                      } else {
                      $rootScope.isLoading = false;
                      }
                    });
                }
              } else if (status === 'prez') {
                if (res.save) {
  
                  AuthoringService.updateMetadata($scope.current.derId, res.bookTitle, res.visibility, res.style, res.copyright, $scope.current.lastVersion, null)
                    .then(function (res2) {
                      if (res2.err == 0) {
                        $rootScope.isLoading = false;
                        $scope.current.visibility = res.visibility;
                        $scope.current.derInfo.copyright.copyrightId = res.copyright;
                        $scope.current.derInfo.derStyleId = res.style;
                        $scope.current.derInfo.title = res.bookTitle;
                        $scope.current.derInfo.description = null;
                        $scope.changeStyle(res.style);
                      }
                      else {
                        $rootScope.isLoading = false;
                      }
                    });
                }
              }*/
            });
          });
        };

        $scope.openLicenceModal = function () {
          ModalService.showModal({
            templateUrl: "/views/partials/bookLicenceModal.html",
            controller: "BookLicenceModalController",
            inputs: {
              visibility: $scope.current.visibility,
              copyright: $scope.current.derInfo.copyright.copyrightId,
              derId: $scope.current.derId

            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                AuthoringService.updateMetadata($scope.current.derId, $scope.current.derInfo.title, res.visibility, $scope.current.derInfo.derStyleId, res.copyright, $scope.current.lastVersion, $scope.current.derInfo.description)
                  .then(function (res2) {
                    if (res2.err == 0) {
                      $rootScope.isLoading = false;
                      $scope.current.visibility = res.visibility;
                      $scope.current.derInfo.copyright.copyrightId = res.copyright;
                      $scope.current.lastVersion = res2.data.derVersion;
                      Chapters.setNewDerVersion(res2.data.derVersion);
                      $scope.current.createdOn = res2.data.createdOn;
                    } else {
                      $rootScope.isLoading = false;
                    }
                  });
              }
            });
          });
        };

        $scope.openCoverModal = function () {

          ModalService.showModal({
            templateUrl: "/views/partials/bookCoverModal.html",
            controller: "BookCoverModalController",
            inputs: {
              description: $scope.current.derInfo.description,
              currentImg: $scope.currentImg
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                var CONTRIBUTORID = 2;
                if ($rootScope.relatedContributors && $rootScope.relatedContributors[0]) {
                  CONTRIBUTORID = $rootScope.relatedContributors[0].contributorId;
                } else {
                  CONTRIBUTORID = {
                    firstname: User.getFirstname(),
                    lastname: User.getLastname()
                  };
                }

                AuthoringService.updateMetadata($scope.current.derId, $scope.current.derInfo.title, $scope.current.visibility, $scope.current.derInfo.derStyleId, $scope.current.derInfo.copyright.copyrightId, Chapters.getNewDerVersion(), res.description)
                  .then(function (res2) {

                    if (res2.err == 0) {
                      $rootScope.isLoading = false;
                      $scope.current.derInfo.description = res.description;
                      if (res.inputImgResizedWEB && res.inputImgResizedMOB) {
                        $scope.isNew = false;
                        uploadFile(res.inputImgResizedWEB, res.inputImgResizedMOB, res.originalImg, $scope.current.derInfo.title, CONTRIBUTORID);
                        $scope.currentImg = res.inputImgResizedWEB;
                      }
                      $scope.current.lastVersion = res2.data.lastVersion;
                      Chapters.setNewDerVersion(res2.data.lastVersion);
                      $scope.current.createdOn = res2.data.createdOn;
                    } else {
                      $rootScope.isLoading = false;
                    }
                  });
              }
            });
          });
        };

        // as per: http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
        function b64toBlob(b64Data, contentType, sliceSize) {
          contentType = contentType || '';
          sliceSize = sliceSize || 512;

          var byteCharacters = atob(b64Data);
          var byteArrays = [];

          for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
          }

          var blob = new Blob(byteArrays, {
            type: contentType
          });
          return blob;

        }

        //multipart/form-data

        var uploadFile = function (inputImgResizedWEB, inputImgResizedMOB, originalImg, title, contributor, lastVersion) {
          $rootScope.isLoading = true;
          $scope.returnedReqs = 0;
          $scope.failedReqs = 0;


          var address = $rootScope.server + "/der/create/upload";
          var webBlob = b64toBlob(inputImgResizedWEB.substring(23), 'image/jpeg');
          var mobBlob = b64toBlob(inputImgResizedMOB.substring(23), 'image/jpeg');
          var orgBlob = b64toBlob(originalImg.substring(22), 'image/png');

          $scope.imgLinks = [];

          if (!title) {
            title = "Slika";
          }

          var req = {
            "derInfo": {
              "derTypeId": "image",
              "title": title,
              "copyrightId": 1

            },
            "uploadFileInfo": {
              "drmType": "web-accessible",
              "contentTypeId": "png",
              //"contentTypeId": "jpg",
              "contentLength": webBlob.size
            }
          };

          if (!contributor) {
            contributor = 1;
          }
          var contributors = [];

          if (typeof contributor === 'number') {
            contributors.push({
              "contributorId": contributor,
              "role": "author"
            })
          } else {
            contributors.push({
              "firstname": contributor.firstname,
              "lastname": contributor.lastname,
              "role": "author"
            })
          }

          req.contributors = contributors;

          var fd1 = new FormData();
          fd1.append("fileInfo", JSON.stringify(req));
          fd1.append("fileStream", webBlob);
          var xhr1 = new XMLHttpRequest();
          xhr1.open("POST", address);
          xhr1.withCredentials = true;
          xhr1.addEventListener("load", function () {
            $scope.returnedReqs = $scope.returnedReqs + 1;
          }, false);
          xhr1.addEventListener("error", function () {
            $scope.failedReqs = $scope.failedReqs + 1;
          }, false);

          xhr1.onreadystatechange = function () {
            if (xhr1.readyState === 4) {
              var res = JSON.parse(xhr1.responseText);
              if (res.err === 0) {
                $scope.imgLinks.push({
                  targetDerId: res.data.derId,
                  targetDerVersion: res.data.derVersion,
                  relationTypeId: "cover.web",
                  targetContentTypeId: res.data.files[0].contentTypeId
                })
              }

            }
          };
          try {
            xhr1.send(fd1);
          } catch (e) {
            sweet.show('Ups...', 'Naslovnica nije uspješno postavljena. Molimo postavite naslovnicu ponovno.', 'warning');
          }

          req.uploadFileInfo.contentLength = mobBlob.size;

          var fd2 = new FormData();
          fd2.append("fileInfo", JSON.stringify(req));
          fd2.append("fileStream", mobBlob);
          var xhr2 = new XMLHttpRequest();
          xhr2.open("POST", address);
          xhr2.withCredentials = true;
          xhr2.addEventListener("load", function () {
            $scope.returnedReqs = $scope.returnedReqs + 1;
          }, false);
          xhr2.addEventListener("error", function () {
            $scope.failedReqs = $scope.failedReqs + 1;
          }, false);

          xhr2.onreadystatechange = function () {
            if (xhr2.readyState === 4) {
              var res = JSON.parse(xhr2.responseText);
              if (res.err === 0) {
                $scope.imgLinks.push({
                  targetDerId: res.data.derId,
                  targetDerVersion: res.data.derVersion,
                  relationTypeId: "cover.mobile",
                  targetContentTypeId: res.data.files[0].contentTypeId
                })
              }
            }
          };
          try {
            xhr2.send(fd2);
          } catch (e) {
            sweet.show('Ups...', 'Naslovnica nije uspješno postavljena. Molimo postavite naslovnicu ponovno.', 'warning');
          }

          req.uploadFileInfo.contentLength = orgBlob.size;

          var fd3 = new FormData();
          fd3.append("fileInfo", JSON.stringify(req));
          fd3.append("fileStream", orgBlob);
          var xhr3 = new XMLHttpRequest();
          xhr3.open("POST", address);
          xhr3.withCredentials = true;
          xhr3.addEventListener("load", function () {
            $scope.$apply($scope.returnedReqs = $scope.returnedReqs + 1);
          }, false);
          xhr3.addEventListener("error", function () {
            $scope.$apply($scope.failedReqs = $scope.failedReqs + 1);
          }, false);
          xhr3.onreadystatechange = function () {
            if (xhr3.readyState === 4 && xhr3.status === 200) {
              var res = JSON.parse(xhr3.responseText);
              if (res.err == 0) {
                $scope.imgLinks.push({
                  targetDerId: res.data.derId,
                  targetDerVersion: res.data.derVersion,
                  relationTypeId: "cover.book",
                  targetContentTypeId: res.data.files[0].contentTypeId
                });
                //  if(!$scope.isNew){
                if (Chapters.getNewDerVersion() > lastVersion) {
                  lastVersion = Chapters.getNewDerVersion();
                }
                console.log('Naslovnice se pridjeljuju knjizi (relAdd): derVersion, time, datoteke');
                var d = new Date();
                console.log(lastVersion, d.getHours(), d.getMinutes(), d.getSeconds(), JSON.stringify($scope.imgLinks));
                $scope.$apply($scope.relAdd($scope.imgLinks, lastVersion));
                //  }
              }
            } else if (xhr3.readyState === 4 && xhr3.status !== 200) {
              sweet.show('Ups...', 'Naslovnica nije uspješno postavljena. Molimo postavite naslovnicu ponovno.', 'warning');
            }
          };
          try {
            xhr3.send(fd3);
          } catch (e) {
            sweet.show('Ups...', 'Naslovnica nije uspješno postavljena. Molimo postavite naslovnicu ponovno.', 'warning');
          }
        };

        $scope.$watch('failedReqs', function (newVal) {
          if (newVal == 1) {
            $rootScope.isLoading = false;

            var modalOptions = {
              showCancel: false,
              isError: true,
              errorNo: res.err,
              closeButtonText: 'Cancel',
              actionButtonText: 'OK',
              headerText: 'GREŠKA (' + res.err + ')',
              bodyText: 'Upload slike nije uspio. Molimo pokušajte ponovno.'
            };

            modalService.showModal({}, modalOptions);
          }
        });

        function autosave() {
          $scope.DERsave();
        }

        $scope.$on('$routeChangeStart', function (next, current) {
          if (!deleteInProgress && !$rootScope.logoutClicked) {
            // autosave();
          }
          if (angular.isDefined(timer)) {
            $interval.cancel(timer);
            timer = undefined;
          }

        });

        //$(window).on('beforeunload', function (){alert('window kloz')});

        var myEvent = window.attachEvent || window.addEventListener,
          chkevent = window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compatable

        myEvent(chkevent, function (e) { // For >=IE7, Chrome, Firefox
          //autosave();
        });

        //svake 3 minute autosave
        var timer = $interval(function () {
          if (!$scope.isInPreviewMode && !$rootScope.mathEditorOpen) {
            autosave(null);
            console.log($rootScope.mathEditorOpen);
            console.log('autosaved');
          }
        }, autoSaveInterval); //prod 600000

        $scope.$on('$destroy', function () {
          if (angular.isDefined(timer)) {
            $interval.cancel(timer);
            timer = undefined;
          }
        });

        $scope.$on('$routeChangeSuccess', function () {
          $timeout(function () {
            var $window = $(window);

            $window.scroll(function () {
              var $stickydiv = $('.mainmenu-panel');
              var width = $(".book-editor-wrapper").outerWidth();
              var height = $stickydiv.outerHeight();
              var scrollTop = $window.scrollTop();
              $stickydiv.toggleClass('sticky-mainmenu-panel', scrollTop > 230);
              if (scrollTop > 230) {
                $stickydiv.css('width', width);
                $('.wysiwyg-placeholder').css('height', height);
              } else {
                $stickydiv.css('width', '');
                $('.wysiwyg-placeholder').css('height', 0);
              }
            });
          });


        });


        $scope.openParserModal = function () {
          ModalService.showModal({
            templateUrl: "/views/partials/uploadWordModal.html",
            controller: "ParseWordCtrl",
            inputs: {
              derId: $scope.current.derId,
              lastVersion: $scope.current.lastVersion
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                $scope.DERget($scope.currentDerId);
              }
            });
          });
        };

        /**
         * RESOURCES
         */
        $scope.relAdd = function (targets, lastVersion) {

          var derId = $scope.newDerId;
          var version = lastVersion;
          if ($scope.current) {
            derId = $scope.current.derId;
            version = $scope.current.lastVersion;
          }

          AuthoringService.addRelation(derId, Chapters.getNewDerVersion(), targets)
            .then(function (res) {
              if (res.err == 0) {
                Chapters.setNewDerVersion(res.data.lastVersion);

                if ($scope.current) {
                  $scope.current.lastVersion = res.data.lastVersion;
                  $scope.current.createdOn = res.data.createdOn;
                }
                $rootScope.isLoading = false;
              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.$on('removeRelation', function (event, args) {

          AuthoringService.delRelation(args.derId, [args.relationId])
            .then(function (res) {

              if (res.err == 0) {
                $rootScope.isLoading = false;
                if ($scope.current.lastVersion < res.data.lastVersion) {
                  $scope.current.lastVersion = res.data.lastVersion;
                }
                if (args.doSave) {
                  //$scope.segmentSave(true);
                }
              } else {
                $rootScope.isLoading = false;
              }
            });

        });

        $scope.$on('versionChanged', function (event, args) {
          if ($scope.current.lastVersion < args.lastVersion) {
            $scope.current.lastVersion = args.lastVersion;
            $scope.current.createdOn = args.createdOn;
          }
        });

        $scope.addResource = function () {
          var fileType = null;

          if ($location.url().substring(0, 6) === '/slike') {
            fileType = 'img';
          } else if ($location.url().substring(0, 13) === '/video-snimke') {
            fileType = 'vid';
          } else if ($location.url().substring(0, 13) === '/audio-snimke') {
            fileType = 'aud';
          }

          ModalService.showModal({
            templateUrl: "/views/partials/uploadResourceModal.html",
            controller: "UploadImageModalCtrl",
            inputs: {
              hasAltText: false,
              fileMultimediaData: null,
              fileType: fileType,
              actionType: 'insertToResources',
            }
          }).then(function (modal) {

            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                $rootScope.$broadcast('resourceAdded', {});
              }

            });

          });
          return false;
        };

        $scope.editResource = function (r) {
          ModalService.showModal({
            templateUrl: "/views/partials/resourceModal.html",
            controller: "ResourceModalController",
            inputs: {
              resource: r,
              resourceURL: (r && r.files.length) ? $sce.trustAsResourceUrl(r.files[0].urlRemote) : null
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save && res.resource) {
                $scope.DERUpdate(res.resource.derId, res.resource.derInfo.title, res.resource.visibility, null, res.copyright, res.resource.derVersion, res.resource.derInfo.description, res.resource.ordering, true);

              }
            });
          });
        };

        $scope.saveAs = function () {
          AuthoringService.copyDER($scope.current.derId, $scope.current.lastVersion)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $location.path("/knjiga/").search({
                  derid: res.data.derId
                });
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.DERUpdate = function (derId, title, visibility, style, copyright, version, description, ordering, isResource) {
          AuthoringService.updateMetadata(derId, title, visibility, style, copyright, version, description, null, ordering)
            .then(function (res) {

              if (res.err == 0) {
                $rootScope.isLoading = false;
                if ($scope.current) {
                  $scope.current.lastVersion = res.data.lastVersion;
                  Chapters.setNewDerVersion(res.data.lastVersion);
                  $scope.current.createdOn = res.data.createdOn;
                }
                if (isResource) {
                  $rootScope.$broadcast('resourceAdded', {});
                }
              } else {
                $rootScope.isLoading = false;
              }
            });
        };


        function makeQuizHtml(quiz, relationId, derId) {
          var quizHTML = '<div id="quiz_' + derId + '"><p id="ThisIsAQuiz" data-relation-id="' + relationId + '" data-der-id="' + derId + '" style="display:none;"></p><p class="quiz-chapter"/>';
          angular.forEach(quiz, function (q, i) {
            var outcomeData = ' ';

            if (q.outcome !== undefined && q.outcome !== null) {
              outcomeData += 'data-outcome-id="' + q.outcome.outcomeId + '" ' +
                'data-outcome-name="' + q.outcome.name + '" ';
              var der = q.outcome.der;
              if (q.outcome.hasOwnProperty('outcomeDerRelations')) {
                var derData = '';
                q.outcome.outcomeDerRelations.forEach(function (relation) {
                  if (derData.length > 0) {
                    derData += '|';
                  }
                  derData += 'id:' + relation.der.derId + ';name:' + relation.der.derInfo.title + ';url:';
                });

                outcomeData += 'data-outcome-der="' + derData + '" ';
              } else if (der !== undefined && der !== null) {
                outcomeData += 'data-outcome-der="id:' + der.derId + ';name:' + der.derInfo.title + ';url:" ';
              }
            }

            var questionId = derId + "_questionId_" + i;
            quizHTML = quizHTML + '<div id="' + questionId + '" ' + outcomeData + ' class="row clearfix" data-question-type="' + q.questionName + '"><div class="col-md-12 question draggable-area">';
            //quizHTML = quizHTML + '<div class="question-name">';
            //quizHTML = quizHTML + '<strong>' + (i + 1) + '. ' + '</strong>';
            //quizHTML = quizHTML + '<strong>' + q.questionName + '</strong></p>';
            //quizHTML = quizHTML + "</div>";
            quizHTML = quizHTML + '<div class="question-text">';
            if (q.type !== 'wordInsert' && q.type !== 'wordSelect' && q.type !== 'dragElements' && q.type !== 'numericValue') {
              quizHTML = quizHTML + "<p>" + q.questionText + "</p>";
              if (q.imageURL && q.type !== 'dragOnPicture') {
                quizHTML = quizHTML + '<p><img src="' + q.imageURL + '" height="100"/></p>';
              }
            }
            quizHTML = quizHTML + "</div>";
            quizHTML = quizHTML + '<div class="question-body">';


            if (q.type === 'truefalse' || q.type === 'pair') {

              angular.forEach(q.answers, function (a, j) {
                if ($(a.feedbackText).text().trim().length > 0) {
                  quizHTML = quizHTML + '<div><input type="radio" name="q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" data-is-correct="' + a.isCorrect + '" class="quiz-input"/><label for="' + derId + "_" + 'q' + i + 'a' + j + '">' + collapseTags(a.answerText) + '</label><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small><div id="' + derId + "_" + 'q' + i + 'a' + j + 'feedbackText" class="feedback question-element-hide">' + collapseTags(a.feedbackText) + '</div></div>';
                } else {
                  quizHTML = quizHTML + '<div><input type="radio" name="q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" data-is-correct="' + a.isCorrect + '" data-clicked-feedback=" " class="quiz-input"/><label for="' + derId + "_" + 'q' + i + 'a' + j + '">' + collapseTags(a.answerText) + '</label><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small></div>';
                }
                if (a.imageURL) {
                  quizHTML = quizHTML + '<p><img src="' + a.imageURL + '" height="100"/></p>';
                }
              });

            } else if (q.type === 'multi') {

              angular.forEach(q.answers, function (a, j) {
                if ($(a.feedbackText).text().trim().length > 0) {
                  quizHTML = quizHTML + '<div><input type="checkbox" name="' + derId + "_" + 'q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" data-is-correct="' + a.isCorrect + '"  style="margin-left: 15px;"/><label for="' + derId + "_" + 'q' + i + 'a' + j + '">' + collapseTags(a.answerText) + '</label><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small><div id="' + derId + "_" + 'q' + i + 'a' + j + 'feedbackText" class="feedback question-element-hide">' + collapseTags(a.feedbackText) + '</div></div>';
                } else {
                  quizHTML = quizHTML + '<div><input type="checkbox" name="' + derId + "_" + 'q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" data-is-correct="' + a.isCorrect + '" data-clicked-feedback=" " style="margin-left: 15px;"/><label for="' + derId + "_" + 'q' + i + 'a' + j + '">' + collapseTags(a.answerText) + '</label><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small></div>';
                }
                if (a.imageURL) {
                  quizHTML = quizHTML + '<p><img src="' + a.imageURL + '" height="100"/></p>';
                }
              });

            } else if (q.type === 'matching') {

              var prompts = [];
              var answers = [];

              angular.forEach(q.answers, function (a, j) {
                prompts.push({
                  promptText: collapseTags(a.pairPrompt),
                  index: j
                });
                answers.push({
                  answerText: a.answerText,
                  index: j
                })
              });

              shuffle(answers);


              angular.forEach(prompts, function (p, j) {
                quizHTML = quizHTML + '<div>' + p.promptText + ' : <select>';

                var options = '';
                angular.forEach(answers, function (a, k) {
                  options = options + '<option value="' + a.index + '" id="' + derId + "_" + 'q' + i + 'a' + j + 'o' + k + '">' + a.answerText + '</option>';
                });

                quizHTML = quizHTML + options + '</select><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small></div>';
              });


            } else if (q.type === 'wordInsert') {


              if (q.questionText) {
                quizHTML = quizHTML + collapseTags(q.questionText) + " ";
              }

              angular.forEach(q.answers, function (a, j) {
                /*
                 extension.push({text: a.extensionText, index: j});
                 answers.push({answerText: a.answerText, index: j})
                 */

                if ($(a.feedbackText).text().trim().length > 0) {

                  quizHTML = quizHTML + '<input type="text" name="' + derId + "_" + 'q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" correct-data="' + a.answerText + '" style="width:20%;display:inline;"/><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small><div id="' + derId + "_" + 'q' + i + 'a' + j + 'feedbackText" class="feedback question-element-hide">' + collapseTags(a.feedbackText) + '</div>';
                } else {

                  quizHTML = quizHTML + '<input type="text" name="' + derId + "_" + 'q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" correct-data="' + a.answerText + '" data-clicked-feedback=" " style="width:20%;display:inline;"/><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small>';
                }
                if (a.extensionText) {
                  quizHTML = quizHTML + collapseTags(a.extensionText) + " ";
                }


              });

            } else if (q.type === 'wordSelect') {

              quizHTML = quizHTML + '<p style="display:inline;">';
              if (q.questionText) {
                quizHTML = quizHTML + collapseTags(q.questionText) + " ";
              }


              angular.forEach(q.answers, function (a, j) {

                var allAnswers = a.allAnswersText.split(';');
                shuffle(allAnswers);
                var options = '<select>';
                for (var k = 0; k < allAnswers.length; k++) {
                  options = options + '<option value="';
                  if (a.answerText.trim().toLowerCase() == allAnswers[k].trim().toLowerCase()) {
                    /**
                     iz nekog razloga se tocan odgovor pohranjuje na ovaj nacin
                     **/
                    options = options + j;
                  } else {
                    options = options + (j + 1).toString();
                  }

                  options = options + '" id="' + derId + "_" + 'q' + i + 'a' + j + 'o' + k + '">' + allAnswers[k] + '</option>';
                }

                quizHTML = quizHTML + options + '</select><small id="' + derId + "_" + 'q' + i + 'a' + j + 'f"></small>';

                if (a.extensionText) {
                  quizHTML = quizHTML + collapseTags(a.extensionText) + " ";
                }


              });


              quizHTML = quizHTML + '</p>';
            } else if (q.type === 'dragElements') {
              quizHTML = quizHTML + '<p style="display:inline;">';
              if (q.questionText) {
                quizHTML = quizHTML + collapseTags(q.questionText) + " ";
              }
              angular.forEach(q.answers, function (a, j) {

                quizHTML = quizHTML + '<div id="' + derId + "_" + 'q' + i + 'a' + j + '" class="dropzone drop-element">Dovucite odgovor</div>';

                quizHTML = quizHTML + '<small id="' + derId + "_" + 'q' + i + 'a' + j + 'f" style="visibility:hidden;">' + " " + '</small>';

                if (a.extensionText) {
                  quizHTML = quizHTML + collapseTags(a.extensionText) + " ";
                }
              });

              quizHTML = quizHTML + '</p><p>';
              var allAnswers = new Array();
              angular.forEach(q.answers, function (a, j) {
                allAnswers.push(('<div class="draggable drag-drop drag" group="' + j + '"> ' + collapseTags(a.answerText) + '</div>'));
              });

              shuffle(allAnswers);
              for (var k = 0; k < allAnswers.length; k++) {
                quizHTML = quizHTML + allAnswers[k];
              }

              quizHTML = quizHTML + '</p>';
            } else if (q.type === 'dragPairs') {
              var sortable1_ID = "sortable1_" + derId + '_q' + i;
              var sortable2_ID = "sortable2_" + derId + '_q' + i;

              var prompts = [];
              var answers = [];

              angular.forEach(q.answers, function (a, j) {
                prompts.push({
                  promptText: collapseTags(a.pairPrompt),
                  index: derId + "_" + "q" + i + 'a' + j
                });
                answers.push({
                  answerText: collapseTags(a.answerText),
                  index: derId + "_" + "q" + i + 'a' + j
                })
              });

              shuffle(prompts);
              shuffle(answers);
              quizHTML = quizHTML + '<ul id="' + sortable1_ID + '" class="drag-pairs">';
              angular.forEach(prompts, function (p, j) {
                quizHTML = quizHTML + '<li class="ui-state-default pair left" id="' + p.index + '" correct-pair="' + p.index + '">' + p.promptText + '</li>';
              });
              quizHTML = quizHTML + "</ul>";
              quizHTML = quizHTML + '<ul id="' + sortable2_ID + '" class="drag-pairs drag-right-side">';
              angular.forEach(answers, function (a, j) {
                quizHTML = quizHTML + '<li class="ui-state-default pair right" id="' + a.index + '" correct-pair="' + a.index + '">' + a.answerText + '</li>';
              });
              quizHTML = quizHTML + "</ul>";
              //quizHTML = quizHTML + '<script>$( function() {$( "#sortable1_' + derId + '_q' + i + ', #sortable2_' + derId + '_q' + i + '");</script>';
              //quizHTML = quizHTML + '<script>$( function() {$( "#sortable1,#sortable2").sortable().disableSelection();})</script>';
              //quizHTML = quizHTML + '<script>setSortableElements("' + sortable1_ID + '", "' + sortable2_ID + '")</script>';
              quizHTML = quizHTML + '<script>setSortableElements("' + sortable1_ID + '", "' + sortable2_ID + '");</script>';

            } else if (q.type === 'dragSortElements') {

              quizHTML = quizHTML + '<div class="col-md-12">';
              angular.forEach(q.answers, function (a, j) {
                quizHTML = quizHTML + '<div class="sortgroup col-md-6"><h4 class="sortheader">' + collapseTags(a.groupName) + '</h4><div id="' + derId + "_" + 'q' + i + 'a' + j + '" class="dropzone dropgroup"></div></div>';
              });
              quizHTML = quizHTML + '</div>';
              var allElements = [];
              angular.forEach(q.answers, function (a, j) {

                //var groupElements = a.answerText.split(';');
                var groupElements = a.answerList;
                for (var k = 0; groupElements && k < groupElements.length; k++) {
                  allElements.push(('<div class="draggable drag-drop dragsort" group="' + j + '"> ' + collapseTags(groupElements[k].elementText) + '<small class="f" style="visibility:hidden;">' + " " + String.fromCharCode(10004) + '</small>' + '</div>'));
                }
              });

              shuffle(allElements);
              for (var k = 0; k < allElements.length; k++) {
                quizHTML = quizHTML + allElements[k];
              }

            } else if (q.type === 'dragOrderElements') {

              var sortableOrder1_ID = "sortableOrder1_" + derId + "_q" + i;
              var sortableOrder2_ID = "sortableOrder2_" + derId + "_q" + i;
              var sortableGroup = "sortableGroup_" + derId + "_q" + i;
              var answers = [];
              angular.forEach(q.answers, function (a, j) {
                answers.push({
                  answerText: collapseTags(a.answerText),
                  index: derId + "_" + "q" + i + 'a' + j
                })
              });
              shuffle(answers);
              quizHTML = quizHTML + '<div class="col-md-12">';

              quizHTML = quizHTML + '<div class="sortgroup-order col-md-6"><h4 class="sortheader">' + "Elementi" + '</h4><ul id="' + sortableOrder1_ID + '" class="sortableOrder ' + sortableGroup + '">';
              angular.forEach(answers, function (a, j) {
                quizHTML = quizHTML + '<li class="ui-state-default" id="' + a.index + '">' + a.answerText + '</li>';
              });
              quizHTML = quizHTML + '</ul></div>';

              quizHTML = quizHTML + '<div class="sortgroup-order col-md-6"><h4 class="sortheader">' + "Poredani odgovori" + '</h4><ul id="' + sortableOrder2_ID + '" class="sortableOrder sortableOrderedElements ' + sortableGroup + '">';

              quizHTML = quizHTML + '</ul></div>';
              //quizHTML = quizHTML + '<script>$( function() {$( "#sortableOrder1, #sortableOrder2" ).sortable({connectWith: ".sortableOrder"}).disableSelection();} );</script>';
              quizHTML = quizHTML + '<script>setSortableWitchConnection("' + sortableOrder1_ID + '", "' + sortableOrder2_ID + '", "' + sortableGroup + '");</script>';
              quizHTML = quizHTML + '</div>';

            } else if (q.type === 'numericValue') {

              if (q.questionText) {
                quizHTML = quizHTML + collapseTags(q.questionText) + " ";
              }
              angular.forEach(q.answers, function (a, j) {
                if ($(a.feedbackText).text().trim().length > 0) {
                  quizHTML = quizHTML + '<input type="number" step=any name="' + derId + "_" + 'q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" minValue="' + a.minValue + '" maxValue="' + a.maxValue + '" style="width:20%;display:inline;"/><small id="' + derId + "_" + 'q' + i +
                    'a' + j + 'f"></small><div id="' + derId + "_" + 'q' + i + 'a' + j + 'feedbackText" class="feedback question-element-hide">' + collapseTags(a.feedbackText) + '</div>';
                } else {
                  quizHTML = quizHTML + '<input type="number" step=any name="' + derId + "_" + 'q' + i + '" id="' + derId + "_" + 'q' + i + 'a' + j + '" minValue="' + a.minValue + '" maxValue="' + a.maxValue + '" style="width:20%;display:inline;"/><small id="' +
                    derId + "_" + 'q' + i + 'a' + j + 'f"></small>';
                }
                if (a.extensionText) {
                  quizHTML = quizHTML + collapseTags(a.extensionText) + " ";
                }
              });
            } else if (q.type === 'dragOnPicture') {

              quizHTML = quizHTML + '<div class="picture-droparea"><img src="' + q.imageURL + '" alt="Slika pitanja"  class="picture-dropzone-quiz" /></div>';
              angular.forEach(q.answers, function (a, j) {
                quizHTML = quizHTML + '<div id="' + derId + "_" + 'q' + i + 'a' + j + '" percentage-x="' + a.percentageX + '" percentage-y="' + a.percentageY + '" class="draggable-restricted drag-drop drag dd-solution">' + a.answerText + '</div>';

              });

              // REPL start
            } else if (q.type === 'repl') {
              quizHTML += "<p>Ovo je REPL zadatak vrste " + q.replType + "</p>";
              // REPL end
            } else {
              console.log('Nepoznati oblik pitanja!');
            }
            quizHTML = quizHTML + "</div>";


            //Show help and results
            quizHTML = quizHTML + '<div class="row clearfix col-md-12 quiz-buttons">';
            //Provjera ispravnosti samo jednog pitanja
            quizHTML = quizHTML + '<button type="button" onclick="checkQuestionAnswer(' + "'" + questionId + "'" + ');" class="btn btn-primary btn-md quiz-button-validate"></button>';

            if (q.helpText && $(q.helpText).text().trim().length > 0) {
              q.helpText = "Pomoć: " + q.helpText;
              quizHTML = quizHTML + '<button type="button" onclick="showHideQuizElement(' + "'" + derId + "_" + 'questionHelp' + i + "'" + ',' + "'" + "quiz_" + derId + "'" + ');" class="btn btn-primary btn-md quiz-button-help"></button>';
            }
            if (q.procedureText && $(q.procedureText).text().trim().length > 0) {
              q.procedureText = "Postupak: " + q.procedureText;
              quizHTML = quizHTML + '<button type="button" onclick="showHideQuizElement(' + "'" + derId + "_" + 'questionResult' + i + "'" + ',' + "'" + "quiz_" + derId + "'" + ');" class="btn btn-primary btn-md quiz-button-solution"></button>';
            }
            quizHTML = quizHTML + '<button type="button" onclick="resetQuizQuestion(' + "'" + questionId + "'" + ')" class="btn btn-primary btn-md quiz-button-reset"></button>';
            quizHTML = quizHTML + '</div>';
            quizHTML = quizHTML + '<div id="' + derId + "_" + 'questionHelp' + i + '" class="row clearfix col-md-12 question-help question-element-hide">' + q.helpText + '</div>';
            quizHTML = quizHTML + '<div id="' + derId + "_" + 'questionResult' + i + '" class="row clearfix col-md-12 question-result question-element-hide">' + q.procedureText + '</div>';
            quizHTML = quizHTML + '</div></div>';
          });

          //quizHTML = quizHTML + '<p id="resultsModal" hidden onclick="checkQuizAnswers(' + "'quiz_" + derId + "'" + ');">Provjeri odgovore!</p>';
          quizHTML = quizHTML + '</div>';
          return quizHTML;
        }

        function shuffle(array) {
          var currentIndex = array.length,
            temporaryValue, randomIndex;

          // While there remain elements to shuffle...
          while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
          }

          return array;
        }

        $scope.openSaveCommentModal = function () {
          ModalService.showModal({
            templateUrl: "/views/partials/saveCommentModal.html",
            controller: "SaveCommentModalController",
            inputs: {
              lastVersion: $scope.current.lastVersion
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                //$scope.segmentSave(false, null, null, res.versionComment);
                $scope.DERsave(res.versionComment);
              }
            });
          });

        };

        $scope.saveCN = function () {
          $scope.DERsave("---");
        };

        $scope.quizXMLExport = function (quizSegment) {

          var relationId = getRelationId(quizSegment.content);
          var qDerId = null;
          var qDerVersion = null;

          ModalService.showModal({
            templateUrl: "/views/partials/quizCategoryModal.html",
            controller: "QuizCategoryModalController"
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                angular.forEach($scope.current.relations, function (r) {
                  if (r.derRelationId == relationId) {
                    qDerId = r.targetDerId;
                    qDerVersion = r.targetDerVersion;
                    downloadFile($rootScope.server + '/der/file/test/moodlexml?derId=' + qDerId + '&derVersion=' + qDerVersion + '&category=' + res.qCategory);

                  }
                })
              }
            });
          });


        };

        $scope.quizPlay = function (quizSegment) {
          ModalService.showModal({
            templateUrl: "/views/partials/quizPlayModal.html",
            controller: "QuizPlayModalController",
            inputs: {
              quizHTML: quizSegment.content
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {

            });
          });
        };

        $scope.quizCreate = function (quizSegment, title) {

          if (quizSegment) {
            var startIndex = quizSegment.content.indexOf('data-der-id="');
            var quizId = quizSegment.content.substring(startIndex);
            var endIndex = quizId.substring(13).indexOf('"');
            quizId = quizId.substring(13, 13 + endIndex);

            var segmentIndex = quizSegment.ordering;

            for (var k = 0; k < $scope.current.segments.length; k++) {
              if ($scope.current.segments[k].ordering === quizSegment.ordering) {
                segmentIndex = k;
                break;
              }
            }

            if (!isNaN(quizId)) {
              var organizationId = null;
              if ($rootScope.userOrganization && $rootScope.userOrganization.organizationId) {
                organizationId = $rootScope.userOrganization.organizationId
              }
              AuthoringService.getQuizDER(quizId, true, organizationId)
                .then(function (res) {
                  if (res.err === 0) {
                    $rootScope.isLoading = false;
                    if (res.data.relations) {
                      showQuizModal(JSON.parse(res.data.derInfo.description), quizId, res.data.relations, res.data.lastVersion, segmentIndex, quizSegment);
                    } else {
                      showQuizModal(JSON.parse(res.data.derInfo.description), quizId, null, res.data.lastVersion, segmentIndex, quizSegment);
                    }

                  } else {
                    $rootScope.isLoading = false;
                  }
                });
            } else {
              console.log('Kviz se nije dobro učitao.');
            }
          } else {
            showQuizModal(null, null, null, null, null, {
              title
            });
          }
        };


        var showQuizModal = function (quiz, quizId, relations, version, segmentIndex, segment) {

          /**
           * segment could be an object with only title property, not always a full blown segment
           */

          if (typeof quiz == "String") {
            quiz = JSON.parse(quiz);
          }


          ModalService.showModal({
            templateUrl: "/views/partials/quizModal.html",
            controller: "QuizModalController",
            inputs: {
              quiz: angular.copy(quiz),
              relations: angular.copy(relations),
              quizId: quizId,
              version: version,
              segment: segment,
              derId: $scope.current.derId,
              derTypeId: $scope.current.derInfo.derTypeId
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                AuthoringService.updateMetadata(res.quizId, "Kviz", null, null, null, null, JSON.stringify(res.quiz))
                  .then(function (res2) {
                    if (res2.err === 0) {
                      AuthoringService.addRelation($scope.current.derId, Chapters.getNewDerVersion(), [{
                          targetDerId: res2.data.derId,
                          targetDerVersion: res2.data.derVersion,
                          relationTypeId: "uses",
                          targetContentTypeId: null
                        }])
                        .then(function (res3) {
                          if (res3.err === 0) {
                            var quizHtml = makeQuizHtml(res.quiz, res3.data.relations[0].derRelationId, res2.data.derId);

                            if ($scope.current) {
                              $scope.current.lastVersion = res3.data.lastVersion;
                              Chapters.setNewDerVersion(res3.data.lastVersion);
                              $scope.current.createdOn = res3.data.createdOn;
                              if (!$scope.current.relations) {
                                $scope.current.relations = [];
                              }
                              $scope.current.relations.push(res3.data.relations[0]);
                            }
                            if (res.isNewQuiz) {
                              $scope.segmentCreate(quizHtml, null, segment.title);

                            } else {
                              $scope.segmentSave(false, quizHtml, segmentIndex);
                            }

                            $rootScope.isLoading = false;
                          } else {
                            $rootScope.isLoading = false;
                          }
                        });

                    } else {
                      $rootScope.isLoading = false;
                    }
                  });
              }
            });
          });
        };

        $scope.openVersionModal = function () {
          ModalService.showModal({
            templateUrl: "/views/partials/versionModal.html",
            controller: "VersionModalController",
            inputs: {
              previousVersions: $scope.current.previousVersions,
              derId: $scope.current.derId,
              lastVersion: $scope.current.lastVersion,
              canChangeVersion: $scope.current.userPermissions.CHANGE_VERSION
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              if (res.save) {
                $scope.DERget($scope.currentDerId, res.derVersion);
              }
            });
          });
        };


        $scope.openCommentModal = function (s) {
          ModalService.showModal({
            templateUrl: "/views/partials/commentModal.html",
            controller: "CommentModalController",
            inputs: {
              derSegmentId: s.derSegmentId,
              canComment: $scope.current.userPermissions.COMMENT && $scope.current.locked && ($scope.current.userPermissions.UNLOCK || $scope.lockedByMe)
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              s.numOfComments = res.numOfComments;
            });
          });
        };


        $scope.selectedTags = [];
        //$scope.possibleTags = ["script", "inclusive", "original", "online", "offline", "mobile-screen", "tablet-screen", "monitor-screen", "projector-screen", "lijevo", "desno"];
        $scope.possibleTags = ["script", "horizontal-only", "vertical-only", "responsive-cognita", "nonresponsive-cognita", "interaction-matija", "lijevo", "desno", "zadatak-čitanja", "zadatak-slušanja", "zadatak-pričanja", "zadatak-promatranja"];

        if ($rootScope.tenant === 'carnet') {
          $scope.possibleTags = ["lijevi stupac", "desni stupac", "samo online", "samo offline", "inkluzivni prikaz"];
        }

        $scope.editTags = function (s) {
          $scope.selectedTags = angular.copy(s.contentTags);
          $scope.selectedSegmentToEditTags = s.derSegmentId;
        };

        // Toggle selection for a tag by name
        $scope.toggleSelection = function toggleSelection(tag, s) {
          var idx = $scope.selectedTags.indexOf(tag);

          // Is currently selected
          if (idx > -1) {
            $scope.selectedTags.splice(idx, 1);
            s.contentTags.splice(idx, 1);
          }

          // Is newly selected
          else {
            $scope.selectedTags.push(tag);
            s.contentTags.push(tag);
          }
        };

        $scope.handleEditSegmentMultimediaButton = (segment) => {
          $scope.selectedSegmentToEditMultimedia = segment.derSegmentId;
        };

        $scope.saveSegmentMultimediaData = (segment, multimediaData) => {
          const newMultimediaJSON = JSON.stringify(multimediaData);

          segment.content = newMultimediaJSON;
        };

        $scope.getMultimediaJSONdata = (content) => {
          let multimediaData;
          if (content && isStringJSONobject(content)) {
            multimediaData = JSON.parse(content);
          } else {
            multimediaData = {};
          }

          return multimediaData;
        };

        const isStringJSONobject = (string) => {
          try {
            const json = JSON.parse(string);
            if (!json || typeof json !== 'object') {
              return false;
            }
          } catch (e) {
            return false;
          }
          return true;
        }

        $scope.createMemoryGame = function (s) {
          s.content = JSON.stringify({
            "feedback": "Bravo!",
            "resourceDerIds": ["172", "172", "172", "172", "172", "172", "172", "172"]
          });
        };

        $scope.openInsertResourceModal = (segment, fileType, multimediaData) => {
          ModalService.showModal({
              templateUrl: "/views/partials/uploadResourceModal.html",
              controller: "UploadImageModalCtrl",
              inputs: {
                hasAltText: true,
                fileMultimediaData: multimediaData,
                fileType: fileType,
                actionType: 'insertToEditor',
              }
            })
            .then((modal) => {

              modal.element.modal();
              modal.close.then((res) => {
                if (res.save) {
                  newData = JSON.parse(res.res);
                  multimediaData.title = newData.title;
                  multimediaData.resourceDerId = newData.resourceDerId;
                  multimediaData.relationId = newData.relationId;
                  if (newData.description) {
                    multimediaData.description = newData.description;
                  }
                  if (newData.altText) {
                    multimediaData.altText = newData.altText;
                  }

                  segment.content = res.res;
                  $scope.DERsave();
                }
              });

            });
        };

        $scope.openAlternativeAudioPicker = function (s) {
          ModalService.showModal({
            templateUrl: "/views/partials/uploadResourceModal.html",
            controller: "UploadImageModalCtrl",
            inputs: {
              hasAltText: false,
              fileMultimediaData: null,
              fileType: 'aud',
              actionType: 'insertToEditor',
            }
          }).then(function (modal) {

            modal.element.modal();
            modal.close.then((res) => {
              if (res.save) {
                const segmentAudio = JSON.parse(res.res);

                s.audioDerRelationId = segmentAudio.relationId;
                s.content = s.content + "<span></span>";
              }
            });

          });
        };


        var createGeogebraHtml = function (geogebraFileUrl, interactiveContentType, geogebraParams) {
          var html = "";
          if (interactiveContentType === "GEOGEBRA" && geogebraParams && geogebraParams.length > 0) {
            html = '<iframe scrolling="no" title="" src="' + geogebraFileUrl + '"' +
              ' style="overflow: hidden; position: relative; border:0px;"' +
              ' height="100%" width="100%">' +
              '</iframe>';
          } else {
            html = '<iframe scrolling="no" title="" src="' + geogebraFileUrl + '"' +
              ' style="overflow: hidden; position: relative; border:0px;"' +
              ' height="100%" width="100%">' +
              '</iframe>';
          }
          return html;
        };

        $scope.openUploadGeogebraModal = function () {
          ModalService.showModal({
            templateUrl: "/views/partials/uploadGeogebraModal.html",
            controller: "UploadGeogebraModalCtrl",
            inputs: {
              editor: null,
              fileType: 'geogebra_zip',
              actionType: 'insertGeogebra'
            }
          }).then(function (modal) {
            modal.element.modal();
            modal.close.then(function (res) {
              var geogebraHtml = createGeogebraHtml(res.geogebraUrl, res.interactiveContentType, res.geogebraParams);

              if (res.save) {
                AuthoringService.updateMetadata($scope.current.derId, $scope.current.derInfo.title, $scope.current.visibility, $scope.current.derInfo.derStyleId, $scope.current.derInfo.copyright.copyrightId, $scope.current.lastVersion, $scope.current.derInfo.description, null)
                  .then(function (res2) {
                    if (res2.err == 0) {
                      AuthoringService.addRelation($scope.current.derId, Chapters.getNewDerVersion(), [{
                        targetDerId: res2.data.derId,
                        targetDerVersion: res2.data.derVersion,
                        relationTypeId: "uses",
                        targetContentTypeId: null
                      }]).then(function (res3) {
                        if (res3.err == 0) {
                          if ($scope.current) {
                            $scope.current.lastVersion = res3.data.lastVersion;
                            Chapters.setNewDerVersion(res3.data.lastVersion);
                            $scope.current.createdOn = res3.data.createdOn;
                            if (!$scope.current.relations) {
                              $scope.current.relations = [];
                            }
                            $scope.current.relations.push(res3.data.relations[0]);
                          }
                          $scope.segmentCreate(null, geogebraHtml);
                          $rootScope.isLoading = false;
                        } else {
                          $rootScope.isLoading = false;
                        }
                      });
                    } else {
                      $rootScope.isLoading = false;
                    }
                  });
              }
            });
          });
        };

        $scope.isInPreviewMode = true;
        $scope.setPreview = function () {
          $scope.isInPreviewMode = !$scope.isInPreviewMode;
          if ($scope.isInPreviewMode) {
            $scope.lockForEdit(false);
          } else {
            $scope.lockForEdit(true);
          }
        };

        $scope.lockForEdit = function (locked) {
          AuthoringService.lockDER($scope.current.derId, Chapters.getNewDerVersion(), locked)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.current.locked = res.data.locked;
              } else {
                $rootScope.isLoading = false;
                if (locked) {
                  $scope.isInPreviewMode = true;
                }

              }
            });
        };

        $scope.lockedByMe = false;
        $scope.lockForComments = function (locked) {
          AuthoringService.lockDER($scope.current.derId, Chapters.getNewDerVersion(), locked)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.current.locked = res.data.locked;
                $scope.lockedByMe = locked;
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.changeStatusForward = function () {
          AuthoringService.changeDERStatus($scope.current.derId, $scope.current.lastVersion, $scope.current.derInfo.derStatus.derStatusId + 1)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.current.lastVersion = res.data.derVersion;
                $scope.current.derInfo.derStatus = res.data.derInfo.derStatus;
                //ngToast.create({dismissOnTimeout: true, content: 'Status ažuriran u '+res.data.derInfo.derStatus.label +' .', className: 'success'});
                sweet.show('Super!', 'Status promijenjen u ' + res.data.derInfo.derStatus.label + '.', 'success');
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.changeStatusBackward = function () {
          AuthoringService.changeDERStatus($scope.current.derId, $scope.current.lastVersion, $scope.current.derInfo.derStatus.derStatusId - 1)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.current.lastVersion = res.data.derVersion;
                $scope.current.derInfo.derStatus = res.data.derInfo.derStatus;
                //ngToast.create({dismissOnTimeout: true, content: 'Status ažuriran u '+res.data.derInfo.derStatus.label +' .', className: 'success'});
                sweet.show('Super!', 'Status promijenjen u ' + res.data.derInfo.derStatus.label + '.', 'success');
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.checkIfSegmentsChanged = function () {
          getDerForDiff($scope.current.derId, $scope.current.derVersion, onGetDerForDiffCallback);
        };

        var onGetDerForDiffCallback = function (currentVersionDer, previousVersionDer) {
          if (!previousVersionDer) {
            return;
          }
          if (!currentVersionDer.segments || !previousVersionDer.segments) {
            return;
          }
          for (var i in currentVersionDer.segments) {
            currentVersionDer.segments[i];
            if (previousVersionDer.segments[i]) {
              if (currentVersionDer.segments[i].content == previousVersionDer.segments[i].content &&
                currentVersionDer.segments[i].glossaryTerm == previousVersionDer.segments[i].glossaryTerm &&
                currentVersionDer.segments[i].audioDerRelationId == previousVersionDer.segments[i].audioDerRelationId &&
                currentVersionDer.segments[i].contentTags == previousVersionDer.segments[i].contentTags) {
                $scope.current.segments[i].changed = false;
              } else {
                $scope.current.segments[i].changed = true;
              }
            } else {
              $scope.current.segments[i].changed = true;
            }
          }
        };

        //duplicirani kod iz diff modala!!!
        var getDerForDiff = function (derId, derVersion, callback) {
          $rootScope.isLoading = true;
          //dohvat trenutnog der-a  s objektom previousversions popunjenim
          //s relevantnom starijom verzijom (od autora koji je razlicit od trenutno ulogiranog)
          AuthoringService.getDerForDiff(derId, derVersion)
            .then(function (res) {
              if (res.err == 0) {
                if (res.data.previousVersions.length > 0) {
                  var lastVersionDerId = res.data.previousVersions[0].derId;
                  var lastVersionDerVersion = res.data.previousVersions[0].derVersion;
                  //dohvat prethodne verzije der-a
                  AuthoringService.getDER(lastVersionDerId, lastVersionDerVersion, true, false, false)
                    .then(function (res2) {
                      if (res2.err == 0) {
                        $rootScope.isLoading = false;
                        callback(res.data, res2.data);
                      } else {
                        $rootScope.isLoading = false;
                        callback(res.data, null);
                      }
                      $scope.stopAudioVideoLoad();
                    });
                } else {
                  $rootScope.isLoading = false;
                  callback(res.data, null);
                  $scope.stopAudioVideoLoad();
                }
              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.stopAudioVideoLoad = function () {
          if ($scope.currentSegmentContentOnLoad !== undefined &&
            ($scope.currentSegmentContentOnLoad.indexOf('<video') > -1 ||
              $scope.currentSegmentContentOnLoad.indexOf('<audio') > -1)) {
            $timeout(function () {
              if (window.stop !== undefined) {
                window.stop();
              } else if (document.execCommand !== undefined) {
                document.execCommand("Stop", false);
              }
            }, 1000);
          }
        };

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true,
            numOfComments: $scope.comments ? $scope.comments.length : 0
          }, 500);
        };
      }
    ])


    .controller('VersionModalController', [
      '$scope', '$rootScope', 'previousVersions', 'derId', 'lastVersion', 'canChangeVersion', 'AuthoringService', '$element', 'close',
      function ($scope, $rootScope, previousVersions, derId, lastVersion, canChangeVersion, AuthoringService, $element, close) {

        $scope.currentPage = 0;
        $scope.versions = previousVersions;
        $scope.canChangeVersion = canChangeVersion;

        $scope.getMoreVersions = function () {
          AuthoringService.getOldVersionsList(derId, $scope.currentPage)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.versions = res.data.items;

                if (res.data.totalItems == 0) {
                  $scope.noResults = true;
                } else {
                  $scope.noResults = false;
                  $scope.NoPages = Math.ceil(res.data.totalItems / 5);

                  $scope.pages = [];
                  for (var i = 0; i < $scope.NoPages; i++) {
                    $scope.pages.push(i);
                  }
                }

              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.getMoreVersions();

        $scope.changePage = function (newPage) {
          $scope.currentPage = newPage;
          $scope.getMoreVersions();
        };

        $scope.close = function (derVersion) {
          $element.modal('hide');
          close({
            derVersion: derVersion,
            save: true
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])

    .controller('CommentModalController', [
      '$scope', '$rootScope', 'derSegmentId', 'canComment', 'AuthoringService', '$element', 'close',
      function ($scope, $rootScope, derSegmentId, canComment, AuthoringService, $element, close) {

        $scope.canComment = canComment;
        $scope.newCommentText = null;

        $scope.getComments = function () {
          AuthoringService.getCommentsForSegment(derSegmentId)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.comments = res.data.items;

              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.addComment = function () {
          AuthoringService.addCommentToSegment(derSegmentId, $scope.newCommentText)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.newCommentText = null;
                $scope.getComments();
              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.deleteComment = function (derCommentId) {
          AuthoringService.deleteCommentFromSegment(derCommentId)
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.getComments();
              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.getComments();

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true,
            numOfComments: $scope.comments ? $scope.comments.length : 0
          }, 500);
        };

      }
    ])

    .controller('DiffModalController', [
      '$scope', '$rootScope', '$sce', 'derSegment', 'currentDer', 'AuthoringService', '$element', 'close',
      function ($scope, $rootScope, $sce, derSegment, currentDer, AuthoringService, $element, close) {
        var init = function () {
          //dohvat trenutnog der-a s objektom previousversions popunjenim s
          //relevantnom prijasnjom verzijom (samo od razlicitog autora)
          //nakon dohvata poziva se callback
          getDerForDiff(currentDer.derId, currentDer.derVersion, getDerForDiffCallback);
        };
        var getDerForDiffCallback = function (currentVersionDer, previousVersionDer) {
          if (!currentVersionDer || !previousVersionDer || !previousVersionDer.segments) {
            setDiffNotFound();
            return;
          }
          var currentSegment = currentVersionDer.segments.filter(function (segment) {
            return segment.derSegmentId == derSegment.derSegmentId;
          })[0];
          //ako nije pronađen segment po id-u, trazim segment s istim orderingom
          //moguci problem ako su  u medjuvremenu segmenti preslagivani i ubacivani
          if (!currentSegment) {
            currentSegment = currentVersionDer.segments.filter(function (segment) {
              return segment.ordering == derSegment.ordering;
            })[0];
            if (!currentSegment) {
              setDiffNotFound();
              return;
            }
          }

          var previousSegment = previousVersionDer.segments.filter(function (segment) {
            return segment.derSegmentId == derSegment.derSegmentId;
          })[0];
          //ako nije pronađen segment po id-u, trazim segment s istim orderingom
          //moguci problem ako su  u medjuvremenu segmenti preslagivani i ubacivani
          if (!previousSegment) {
            previousSegment = previousVersionDer.segments.filter(function (segment) {
              return segment.ordering == derSegment.ordering;
            })[0];
            if (!previousSegment) {
              setDiffNotFound();
              return;
            }
          }
          setDiffHeaders(currentVersionDer, currentVersionDer.previousVersions[0]);
          calculateSegmentDiffs(currentSegment, previousSegment);
        }

        var setDiffNotFound = function () {
          $scope.diffLeft = "<h3 style='color:red'>Nije pronađena verzija za usporedbu</h3>";
          $scope.diffRight = "";
          $scope.diffRightHeader = {
            changed: true
          }
        }

        var setDiffHeaders = function (currentVersionDer, previousVersionDer) {
          $scope.diffLeftHeader = {
            title: "Prethodna verzija",
            createdOn: new Date(previousVersionDer.createdOn),
            createdBy: previousVersionDer.createdBy ? previousVersionDer.createdBy.firstname + " " + previousVersionDer.createdBy.lastname : "nepoznato",
            version: previousVersionDer.derVersion
          }
          $scope.diffRightHeader = {
            title: "Trenutna verzija",
            changed: false,
            createdOn: new Date(currentVersionDer.createdOn),
            createdBy: currentVersionDer.createdBy ? currentVersionDer.createdBy.firstname + " " + currentVersionDer.createdBy.lastname : "nepoznato",
            version: currentVersionDer.derVersion
          }
        }

        var calculateSegmentDiffs = function (currentSegment, previousSegment) {
          var previous = sanitizeForDiff(previousSegment.content);
          var current = sanitizeForDiff(currentSegment.content);

          //var before =  htmldiff(current, previous);
          var after = htmldiff(previous, current);

          $scope.diffLeft = previous;
          $scope.diffRight = after;
          if (after.indexOf('<ins>') !== -1 ||
            after.indexOf('<del>') !== -1) {
            $scope.diffRightHeader.changed = true;
          } else {
            $scope.diffRightHeader.changed = false;
          }
        }

        var sanitizeForDiff = function (htmlContent) {
          var sanitized = htmlContent.replace(/<img[^>]*>/g, "");
          //sanitized = sanitized.replace(/&nbsp;/g,"");
          return sanitized;
        }

        var getDerForDiff = function (derId, derVersion, callback) {
          $rootScope.isLoading = true;
          //dohvat trenutnog der-a  s objektom previousversions popunjenim
          //s relevantnom starijom verzijom (od autora koji je razlicit od trenutno ulogiranog)
          AuthoringService.getDerForDiff(derId, derVersion)
            .then(function (res) {
              if (res.err == 0) {
                if (res.data.previousVersions.length > 0) {
                  var lastVersionDerId = res.data.previousVersions[0].derId;
                  var lastVersionDerVersion = res.data.previousVersions[0].derVersion;
                  //dohvat prethodne verzije der-a
                  AuthoringService.getDER(lastVersionDerId, lastVersionDerVersion, true, false, false)
                    .then(function (res2) {
                      if (res2.err == 0) {
                        $rootScope.isLoading = false;
                        callback(res.data, res2.data);
                      } else {
                        $rootScope.isLoading = false;
                        callback(res.data, null);
                      }
                    });
                } else {
                  $rootScope.isLoading = false;
                  callback(res.data, null);
                }
              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true,
            numOfComments: $scope.comments ? $scope.comments.length : 0
          }, 500);
        };

        init();
      }
    ])

    .controller('DRMModalController', [
      '$scope', '$rootScope', 'type', '$element', 'close',
      function ($scope, $rootScope, type, $element, close) {

        $scope.type = type;
        $scope.drmType = null;

        $scope.close = function () {
          $element.modal('hide');
          close({
            drmType: $scope.drmType,
            save: true
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])

    .controller('SaveCommentModalController', [
      '$scope', '$element', 'close', 'lastVersion',
      function ($scope, $element, close, lastVersion) {

        $scope.versionComment = '(v' + lastVersion + ') ';

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true,
            versionComment: $scope.versionComment
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])

    .controller('QuizCategoryModalController', [
      '$scope', '$element', 'close',
      function ($scope, $element, close) {

        $scope.qCategory = '';

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true,
            qCategory: $scope.qCategory
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])


    .controller('OutcomeModalController',
      ['$scope', '$rootScope', '$element', '$location', 'AuthoringService', '$window', 'close', 'der',
        function ($scope, $rootScope, $element, $location, AuthoringService, $window, close, der) {
          $scope.der = der;
          $scope.selectedDers = [];


          $scope.getOutcomeList = function () {
            AuthoringService.listOutcomes(der.derId, false)
              .then(function (res) {
                if (res.err === 0) {
                  $scope.outcomes = res.data.items;
                  AuthoringService.getDER(der.derId, null, true, false, true, 1, null, false)
                    .then(function (res2) {
                      if (res2.err === 0) {
                        $rootScope.isLoading = false;
                        $scope.der = res2.data;

                      } else {
                        $rootScope.isLoading = false;
                      }
                    });

                } else {
                  $rootScope.isLoading = false;
                }
              })
          };

          $scope.getOutcomeList();

          $scope.newOutcome = null;

          $scope.addNewOutcome = function () {
            var derRelations = [];
            $scope.selectedDers.forEach(function (derId) {
              if (derId !== undefined && derId !== null) {
                derRelations.push({
                  derId: derId
                });
              }
            });
            AuthoringService.createOutcome(parseInt($location.search().derid), $scope.newOutcome, true, null, derRelations)
              .then(function (res) {
                if (res.err == 0) {
                  $rootScope.isLoading = false;
                  $scope.getOutcomeList();
                  $scope.newOutcome = null;
                } else {
                  $rootScope.isLoading = false;
                }
              });
            $scope.selectedDers.length = 0;
          };

          $scope.deleteOutcome = function (outcomeId) {
            AuthoringService.deleteOutcome(outcomeId)
              .then(function (res) {
                if (res.err == 0) {
                  $rootScope.isLoading = false;
                  $scope.getOutcomeList();
                } else {
                  $rootScope.isLoading = false;
                }
              })
          };

          $scope.updateViewFlag = [];

          $scope.toggleOutcomeUpdate = function (index) {
            $scope.updateViewFlag[index] = !$scope.updateViewFlag[index];
            for (var j = 0; j < $scope.outcomes.length; j++) {
              if (j != index) {
                $scope.updateViewFlag[j] = false
              }
            }
            $scope.outcome = {
              ders: []
            };
            if ($scope.outcomes[index].outcomeDerRelations) {
              for (var k = 0; k < $scope.outcomes[index].outcomeDerRelations.length; k++) {
                $scope.outcome.ders.push($scope.outcomes[index].outcomeDerRelations[k].derId);
              }
            }
          };


          $scope.updateOutcome = function (outcomeId, name, index) {
            var outcomeDerRelations = [];
            for (var k = 0; k < $scope.outcome.ders.length; k++) {
              outcomeDerRelations.push({
                "derId": $scope.outcome.ders[k]
              });
            }
            $scope.toggleOutcomeUpdate(index);

            AuthoringService.updateOutcome(outcomeId, name, outcomeDerRelations)
              .then(function (res) {
                if (res.err == 0) {
                  $rootScope.isLoading = false;
                  $scope.getOutcomeList();

                } else {
                  $rootScope.isLoading = false;
                }
              })
          }
        }
      ])

    .controller('PreviewModalController', [
      '$scope', '$rootScope', '$element', '$window', 'previewHTML', '$sce', 'close',
      function ($scope, $rootScope, $element, $window, previewHTML, $sce, close) {

        var trustedHTML = $sce.trustAsResourceUrl('data:text/html;charset=utf-8,' + previewHTML);
        $scope.previewHTML = trustedHTML;

        /* $scope.initPreview = function () {
         var iframe = document.getElementById('previewFrame');
         var cssLink = document.createElement("link");
         cssLink.href = "/preview/css/main.css";
         cssLink.rel = "stylesheet";
         cssLink.type = "text/css";
         iframe.contentDocument.head.appendChild(cssLink);
         console.log(iframe.contentDocument.head);
         };
         */

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])


    .controller('BookOptionsModalController', [
      '$scope', '$rootScope', '$element', '$timeout', '$location', 'modalTitle', 'status', 'style', 'visibility', 'copyright', 'bookTitle', 'description', 'derId', 'type', 'currentImg', 'parentDOS', 'parentModule', 'derTypeId', 'close', 'resizeService', 'readLocalPicService', 'AuthoringService',
      function ($scope, $rootScope, $element, $timeout, $location, modalTitle, status, style, visibility, copyright, bookTitle, description, derId, type, currentImg, parentDOS, parentModule, derTypeId, close, resizeService, readLocalPicService, AuthoringService) {

        // init properties
        $scope.isEdit = false;
        $scope.lastVersion = null;

        $scope.module = parentModule;
        $scope.doslist = [];
        $scope.moduleList = [];
        $scope.doslist.push(parentDOS);
        $scope.dos = parentDOS;
        $scope.moduleList.push(parentModule);

        var organizationId = null;
        if ($rootScope.userOrganization && $rootScope.userOrganization.organizationId) {
          organizationId = $rootScope.userOrganization.organizationId;
        }

        var includePublic = false;
        if ($rootScope.tenant !== 'carnet') {
          includePublic = true;
        }

        var loadInitialDOSList = function () {
          AuthoringService.listDER('', includePublic, 'dos', 0, 100, false, 'title_az', false, false, '', 0, null, false, organizationId)

            .then(function (res) {
              if (res.err === 0) {
                $scope.doslist = res.data.items;
                if (parentDOS) {
                  $scope.doslist
                    .forEach(function (dos) {
                      if (dos.derId === parentDOS.derId) {
                        $scope.dos = dos;
                      }
                    });
                }
                /*$scope.doslist.forEach(function(dos) {
                  if (dos.children) {
                    dos.children.forEach(function(module) {
                      $scope.moduleFullList.push(module);
                    });
                  }
                });*/
              }
              $rootScope.isLoading = false;
            })
            .then(function () {

              if (derId) {
                //$scope.isEdit = true;
                AuthoringService.getDER(derId, null, true)
                  .then(function (res) {
                    $rootScope.isLoading = false;

                    var der = res.data;
                    if (res.err === 0) {
                      $scope.currentDer = der;

                      $scope.bookTitle = der.derInfo.title;
                      $scope.ordering = der.derInfo.ordering;
                      $scope.description = der.derInfo.description;
                      $scope.lastVersion = der.lastVersion;

                      /*
                      der.relations
                        .filter(function(relation) { return relation.relationTypeId === "parent"; })
                        .forEach(function(relation) {
                          if (der.derInfo.derTypeId === "eBook") {
                            $scope.doslist
                              .forEach(function(dos) {
                                var module = dos.children.filter(function(module) {
                                  return module.derId === relation.targetDerId;
                                }).shift();
                                if (module) {
                                  $scope.dos = dos;
                                  $scope.module = module;
                                  $scope.moduleList = dos.children.slice();
                                }
                              });
                          }
                          else if (der.derInfo.derTypeId === "module") {
                            $scope.doslist
                              .forEach(function(dos) {
                                if (dos.derId === relation.targetDerId) {
                                  $scope.dos = dos;
                                }
                              });
                          }
                        });
                      */
                    }
                  });
              }
            });

        };


        if (derTypeId === 'module' || (derTypeId === 'eBook' && $location.url().includes('/list'))) {
          loadInitialDOSList();
        }

        $scope.onDosChange = function () {
          // filter module list
          $scope.moduleList = [];

          AuthoringService.getDER($scope.dos.derId, null, true, false, true, 1)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.moduleList = res.data.children;
              }
              $rootScope.isLoading = false;
            });


          //if ($scope.dos.children) {
          //$scope.dos.children.forEach(function (child) {
          //  $scope.moduleList.push(child);
          //})


          // reset module to the first item in module list
          $scope.module = $scope.moduleList.slice(0, 1).shift();
        };

        $scope.isSaveButtonEnabled = function () {
          if (derTypeId === 'dos') {
            return ($scope.bookTitle && $scope.bookTitle.length > 0)
          } else if (derTypeId === 'module') {
            return ($scope.bookTitle && $scope.bookTitle.length > 0 && !$scope.isResizing && $scope.ordering &&
              $scope.dos) || $scope.isEdit
          } else
            return ($scope.bookTitle && $scope.bookTitle.length > 0 && !$scope.isResizing && $scope.ordering &&
                $scope.dos && ($scope.currentDer && $scope.currentDer.derInfo.derTypeId !== 'eBook' || $scope.module)) ||
              $scope.isEdit;
        };


        $scope.setStyle = function (style) {
          $scope.chosenStyle = style;
          switch (style) {
            case "stil.0":
              $scope.style0 = "style-selected";
              $scope.style1 = "";
              $scope.style2 = "";
              break;
            case "stil.1":
              $scope.style1 = "style-selected";
              $scope.style0 = "";
              $scope.style2 = "";
              break;
            case "stil.2":
              $scope.style2 = "style-selected";
              $scope.style1 = "";
              $scope.style0 = "";
              break;
            default:
              break;
          }
        };

        $scope.type = type;
        style = style ? style : "stil.0";
        if ($rootScope.tenant !== 'carnet') {
          visibility = visibility ? visibility : "public";
        } else if ($rootScope.tenant === 'carnet') {
          visibility = visibility ? visibility : "private";
        }
        copyright = copyright ? copyright : 1;

        $scope.derId = derId;
        $scope.modalTitle = modalTitle;
        $scope.status = status;
        $scope.derTypeId = derTypeId;

        $scope.chosenCopyright = copyright;
        $scope.chosenVisibility = visibility;
        $scope.chosenStyle = style;
        $scope.setStyle(style);

        $scope.bookTitle = bookTitle;
        $scope.description = description;
        $scope.currentImg = currentImg;

        $scope.isResizing = false;

        $scope.copyrights = [{
            id: 1,
            label: "Imenovanje (CC BY)"
          },
          {
            id: 2,
            label: "Imenovanje-Dijeli pod istim uvjetima (CC BY-SA)"
          },
          {
            id: 3,
            label: "Imenovanje-Bez prerada (CC BY-ND)"
          },
          {
            id: 4,
            label: "Imenovanje-Nekomercijalno (CC BY-NC)"
          },
          {
            id: 5,
            label: "Imenovanje-Nekomercijalno-Dijeli pod istim uvjetima (CC BY-NC-SA)"
          },
          {
            id: 6,
            label: "Imenovanje-Nekomercijalno-Bez prerada (CC BY-NC-ND)"
          },
          {
            id: 7,
            label: "Copyright - Sva prava pridržana"
          }
        ];

        $scope.visibilities = [{
            id: "public",
            label: "Dijeljeno"
          },
          {
            id: "private",
            label: "Ograničeno"
          }
        ];

        $scope.closingWithCrop = false;

        $scope.closeWithCrop = function () {
          if ($scope.imageCropStep === 2) {
            $scope.closingWithCrop = true;
            $rootScope.$broadcast('cropCover', {});
          } else {
            $scope.close();
          }
        };

        //  This close function doesn't need to use jQuery or bootstrap, because
        //  the button has the 'data-dismiss' attribute.
        $scope.close = function () {
          var newParent = null;
          if (!$scope.currentDer || $scope.currentDer.derInfo.derTypeId === "eBook") {
            newParent = $scope.module;
          } else if ($scope.currentDer.derInfo.derTypeId === "module") {
            newParent = $scope.dos;
          }
          var parentRelation = $scope.currentDer && $scope.currentDer.relations.filter(function (item) {
            return item.relationTypeId === 'parent'
          }).shift();

          close({
            copyright: $scope.chosenCopyright,
            visibility: $scope.chosenVisibility,
            style: $scope.chosenStyle,
            bookTitle: $scope.bookTitle,
            description: $scope.description,
            inputImgResizedMOB: $scope.inputImgResizedMOB,
            inputImgResizedWEB: $scope.inputImgResizedWEB,
            originalImg: $scope.originalImg,
            module: $scope.module,
            dos: $scope.dos,
            ordering: $scope.ordering,
            lastVersion: $scope.lastVersion,
            save: true,
            newParent: newParent,
            currentParentRelationId: parentRelation ? parentRelation.derRelationId : null
          }, 500); // close, but give 500ms for bootstrap to animate
        };

        //  This cancel function must use the bootstrap, 'modal' function because
        //  the doesn't have the 'data-dismiss' attribute.
        $scope.cancel = function () {

          //  Manually hide the modal.
          $element.modal('hide');
          //  Now call close, returning control to the caller.
          close({
            copyright: $scope.chosenCopyright,
            visibility: $scope.chosenVisibility,
            style: $scope.chosenStyle,
            bookTitle: $scope.bookTitle,
            description: $scope.description,
            inputImgResizedMOB: $scope.inputImgResizedMOB,
            inputImgResizedWEB: $scope.inputImgResizedWEB,
            originalImg: $scope.originalImg,
            save: false
          }, 500); // close, but give 500ms for bootstrap to animate
        };

        /**
         * IMAGE RESIZING START
         */

        $scope.imageCropResult = null;
        $scope.showImageCropper = false;

        var resizeFunctions = function () {

          this.resizeMob = function (newVal) {
            resizeService.resizeImage(newVal, {
              width: 107,
              height: 143,
              step: 1
            }, function (err, image) {
              if (err) {
                console.error(err);
                return;
              }
              $scope.inputImgResizedMOB = image;
              $scope.isResizing = false;
            });
            return this;
          };

          this.resizeWeb = function (newVal) {
            resizeService.resizeImage(newVal, {
              width: 180,
              height: 240,
              step: 1
            }, function (err, image) {
              if (err) {
                console.error(err);
                return;
              }
              $scope.inputImgResizedWEB = image;
              $scope.currentImg = image;
            });
            return this;
          };

          if (this instanceof resizeFunctions) {
            return this.resizeFunctions;
          } else {
            return new resizeFunctions();
          }

        };


        $scope.$watch('imageCropResult', function (newVal) {

          if (newVal) {
            $scope.originalImg = newVal;
            var resizer = new resizeFunctions();
            resizer.resizeMob(newVal).resizeWeb(newVal);
          }
        });

        $scope.$watch('inputImgResizedWEB', function (newVal) {
          if (newVal && $scope.closingWithCrop) {
            $scope.close();
          }
        });


        $scope.coverEditMode = false;
        $scope.$watch('imageCropStep', function (newVal) {
          $scope.imageCropStep = newVal;
          if (newVal === 2) {
            $scope.coverEditMode = true;
          } else {
            $scope.coverEditMode = false;
          }
        });

        /*  $scope.isResizing = false;
         var inputResize = function (file) {
         $scope.isResizing = true;
         readLocalPicService.readFileInput(file).then(function (img) {
  
         $scope.originalImg = img;
  
         resizeService.resizeImage(img, {width: 180, height: 240, step: 1}, function (err, image) {
         if (err) {
         //console.error(err);
         return;
         }
         $scope.inputImgResizedWEB = image;
         $scope.currentImg = image;
  
         });
         resizeService.resizeImage(img, {width: 107, height: 143, step: 1}, function (err, image) {
         if (err) {
         //console.error(err);
         return;
         }
         $scope.inputImgResizedMOB = image;
         $scope.isResizing = false;
         });
  
         }, function (err) {
         //console.error(err);
         });
         };
  
         $scope.setFiles = function (element) {
         $scope.$apply(function () {
         inputResize(element);
         });
         };*/
        /**
         * IMAGE RESIZING END
         */
      }
    ])

    .controller('BookLicenceModalController', [
      '$scope', '$rootScope', '$element', 'visibility', 'copyright', 'derId', 'close',
      function ($scope, $rootScope, $element, visibility, copyright, derId, close) {


        if ($rootScope.tenant !== 'carnet') {
          visibility = visibility ? visibility : "public";
        } else if ($rootScope.tenant === 'carnet') {
          visibility = visibility ? visibility : "private";
        }

        copyright = copyright ? copyright : 1;

        $scope.derId = derId;

        $scope.chosenCopyright = copyright;
        $scope.chosenVisibility = visibility;

        $scope.copyrights = [{
            id: 1,
            label: "Imenovanje (CC BY)"
          },
          {
            id: 2,
            label: "Imenovanje-Dijeli pod istim uvjetima (CC BY-SA)"
          },
          {
            id: 3,
            label: "Imenovanje-Bez prerada (CC BY-ND)"
          },
          {
            id: 4,
            label: "Imenovanje-Nekomercijalno (CC BY-NC)"
          },
          {
            id: 5,
            label: "Imenovanje-Nekomercijalno-Dijeli pod istim uvjetima (CC BY-NC-SA)"
          },
          {
            id: 6,
            label: "Imenovanje-Nekomercijalno-Bez prerada (CC BY-NC-ND)"
          },
          {
            id: 7,
            label: "Copyright - Sva prava pridržana"
          }
        ];

        $scope.visibilities = [{
            id: "public",
            label: "Dijeljeno"
          },
          {
            id: "private",
            label: "Ograničeno"
          }
        ];


        $scope.close = function () {
          close({
            copyright: $scope.chosenCopyright,
            visibility: $scope.chosenVisibility,
            save: true
          }, 500);
        };

        $scope.cancel = function () {

          $element.modal('hide');


          close({
            copyright: $scope.chosenCopyright,
            visibility: $scope.chosenVisibility,
            save: false
          }, 500);
        };

      }
    ])

    .controller('BookCoverModalController', [
      '$scope', '$rootScope', '$element', 'description', 'currentImg', 'close', 'resizeService', 'readLocalPicService', 'AuthoringService', '$location',
      function ($scope, $rootScope, $element, description, currentImg, close, resizeService, readLocalPicService, AuthoringService, $location) {


        $scope.imageCropResult = null;
        $scope.showImageCropper = false;


        $scope.$watch('imageCropResult', function (newVal) {
          if (newVal) {
            $scope.originalImg = newVal;
            resizeService.resizeImage(newVal, {
              width: 180,
              height: 240,
              step: 1
            }, function (err, image) {
              if (err) {
                console.error(err);
                return;
              }
              $scope.inputImgResizedWEB = image;
              $scope.currentImg = image;

            });
            resizeService.resizeImage(newVal, {
              width: 107,
              height: 143,
              step: 1
            }, function (err, image) {
              if (err) {
                console.error(err);
                return;
              }
              $scope.inputImgResizedMOB = image;
              $scope.isResizing = false;
            });


          }
        });

        $scope.coverEditMode = false;
        $scope.$watch('imageCropStep', function (newVal) {

          if (newVal === 2) {
            $scope.coverEditMode = true;
          } else {
            $scope.coverEditMode = false;
          }
        });


        $scope.description = description;
        if (currentImg) {
          $scope.currentImg = currentImg;
        } else {
          //AuthoringService.getDER($routeParams.derId, null, true)
          AuthoringService.getDER(parseInt($location.search().derid), null, true)
            .then(function (res) {
              $rootScope.isLoading = false;
              if (res.err == 0) {

                if (res.data.images) {
                  angular.forEach(res.data.images, function (i) {
                    if (i.relationTypeId == 'cover.web') {
                      $scope.currentImg = i.urlRemote;
                    }

                  });
                }
              }
            });
        }

        $scope.isResizing = false;

        $scope.closingWithCrop = false;

        $scope.closeWithCrop = function () {
          if ($scope.imageCropStep === 2) {
            $scope.closingWithCrop = true;
            $rootScope.$broadcast('cropCover', {});
          } else {
            $scope.close();
          }
        };

        $scope.close = function () {
          var d = new Date();
          close({
            description: $scope.description,
            inputImgResizedMOB: $scope.inputImgResizedMOB,
            inputImgResizedWEB: $scope.inputImgResizedWEB,
            originalImg: $scope.originalImg,
            save: true
          }, 500);
        };


        $scope.cancel = function () {

          $element.modal('hide');
          var d = new Date();
          close({
            description: $scope.description,
            inputImgResizedMOB: $scope.inputImgResizedMOB,
            inputImgResizedWEB: $scope.inputImgResizedWEB,
            originalImg: $scope.originalImg,
            save: false
          }, 500); // close, but give 500ms for bootstrap to animate
        };


        /**
         * IMAGE RESIZING START
         */

        var inputResize = function (file) {
          $scope.isResizing = true;
          readLocalPicService.readFileInput(file).then(function (img) {

            if (img.substring(0, 15) === 'data:image/jpeg') {

              $scope.originalImg = img;

              resizeService.resizeImage(img, {
                width: 107,
                height: 143,
                step: 1
              }, function (err, image) {
                if (err) {
                  console.error(err);
                  return;
                }
                $scope.inputImgResizedMOB = image;
                $scope.isResizing = false;
              });
              resizeService.resizeImage(img, {
                width: 180,
                height: 240,
                step: 1
              }, function (err, image) {
                if (err) {
                  console.error(err);
                  return;
                }
                $scope.inputImgResizedWEB = image;
                $scope.currentImg = image;

              });
            } else {
              alert('Odaberite sliku .jpg formata!');
            }
          }, function (err) {
            console.error(err);
          });
        };

        $scope.setFiles = function (element) {
          $scope.$apply(function () {
            inputResize(element);
          });
        };


        $scope.$watch('inputImgResizedWEB', function (newVal) {
          if (newVal && $scope.closingWithCrop) {
            $scope.close();
          }
        });

        /**
         * IMAGE RESIZING END
         */


      }
    ])


    .directive('onEnter', function () {
      var linkFn = function (scope, element, attrs) {
        element.bind("keypress", function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.$eval(attrs.onEnter);
            });
            event.preventDefault();
          }
        });
      };

      return {
        link: linkFn
      };
    })

    .directive('ngReallyClick', [function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('click', function () {
            var message = attrs.ngReallyMessage;
            if (message && confirm(message)) {
              scope.$apply(attrs.ngReallyClick);
            }
          });
        }
      }
    }])

    .directive('windowExit', function ($window) {
      return {
        restrict: 'AE',
        //performance will be improved in compile
        compile: function (element, attrs) {
          var myEvent = $window.attachEvent || $window.addEventListener,
            chkevent = $window.attachEvent ? 'onbeforeunload' : 'beforeunload'; /// make IE7, IE8 compatable

          myEvent(chkevent, function (e) { // For >=IE7, Chrome, Firefox
            var confirmationMessage = ' '; // a space
            (e || $window.event).returnValue = "Are you sure that you'd like to close the browser?";
            return confirmationMessage;
          });
        }
      };
    })

    .directive('spinnerboy', function ($timeout, $rootScope) {
      return {
        restrict: 'E',
        template: '<div class="spinner"><div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div><div class="rect5"></div></div>',
        scope: {
          show: '=',
          delay: '@'
        },
        link: function (scope, elem, attrs) {
          var showTimer;

          //This is where all the magic happens!
          // Whenever the scope variable updates we simply
          // show if it evaluates to 'true' and hide if 'false'
          $rootScope.$watch('isLoading', function (newVal) {
            newVal ? showSpinner() : hideSpinner();
          });

          function showSpinner() {
            //If showing is already in progress just wait
            if (showTimer) return;

            //Set up a timeout based on our configured delay to show
            // the element (our spinner)
            showTimer = $timeout(showElement.bind(this, true), 20);
          }

          function hideSpinner() {
            //This is important. If the timer is in progress
            // we need to cancel it to ensure everything stays
            // in sync.
            if (showTimer) {
              $timeout.cancel(showTimer);
            }

            showTimer = null;
            showElement(false);
          }

          function showElement(show) {
            show ? elem.css({
              display: ''
            }) : elem.css({
              display: 'none'
            });
          }

        }
      };
    })


    .controller('UploadGeogebraModalCtrl', [
      '$scope', '$rootScope', '$element', '$timeout', '$location', 'actionType', 'editor', 'fileType', 'AuthoringService', 'Search', 'User', 'ngToast', 'sweet', 'close', 'AppSettings', 'textAngularManager', 'Chapters',
      function ($scope, $rootScope, $element, $timeout, $location, actionType, editor, fileType, AuthoringService, Search, User, ngToast, sweet, close, AppSettings, textAngularManager, Chapters) {

        var editor = editor;

        $scope.actionType = actionType;
        $scope.fileType = fileType;

        var derTypeId = 'geogebra';
        var maxSize = AppSettings.getMaxVideoSize(); //TODO

        $scope.maxSize = (maxSize / (1024 * 1024)).toFixed(0) + ' MB';

        Search.setEditorSearchFilter(derTypeId);
        var derId = parseInt($location.search().derid);

        var contributors = [];
        if (User.getRelatedContributors()) {
          contributors.push({
            "contributorId": $rootScope.relatedContributors[0].contributorId,
            "role": "author"
          })
        } else {
          contributors.push({
            "firstname": User.getFirstname(),
            "lastname": User.getLastname(),
            "role": "author"
          })
        }

        $scope.setFiles = function (element) {
          $scope.$apply(function () {
            $scope.file = element.files[0];
          });
        };

        $scope.alertMissingData = function () {
          sweet.show('Ups...', 'Niste unijeli sve obavezne podatke!', 'warning');
        };

        //insert datoteke s računala
        $scope.uploadFile = function (title, desc) {

          if (maxSize && maxSize <= $scope.file.size) {
            alert('Datoteka je prevelika! Dodavanje nije uspjelo.');
            $scope.cancel();
          } else {
            $scope.showSpinner = true;
            var req = {
              "derInfo": {
                "derTypeId": derTypeId,
                "title": title,
                "description": desc,
                "copyrightId": 1
              },
              "contributors": contributors,
              "uploadFileInfo": {
                "drmType": "web-accessible",
                "contentTypeId": "zip",
                "contentLength": $scope.file.size
              }
            };

            if ($rootScope.tenant !== 'carnet') {
              req.visibility = "public";
            } else if ($rootScope.tenant === 'carnet') {
              req.visibility = "private";
            }

            var fd1 = new FormData();
            fd1.append("fileInfo", JSON.stringify(req));
            fd1.append("fileStream", $scope.file);

            var xhr = new XMLHttpRequest();
            xhr.open("POST", $rootScope.server + "/der/create/upload");
            xhr.withCredentials = true;

            xhr.onreadystatechange = function () {
              if (xhr.readyState === 4 && xhr.status === 200) {
                var res = JSON.parse(xhr.responseText);
                if (res.err === 0) {
                  if (actionType === 'insertGeogebra') {
                    $scope.close(res.data);
                  }
                } else {
                  $scope.$apply(function () {
                    $scope.showSpinner = false;
                    if (res.err === 44) {
                      if (res.data.indexOf('file too large') === 0) {
                        alert('Datoteka je prevelika!');
                      } else if (res.data.indexOf('bad content-type') === 0) {
                        alert('Format ove datoteke nije podržan!');
                      }
                    } else {
                      alert('Došlo je do greške! Molimo pokušajte ponovno.');
                    }
                  });

                }
              }
            };
            xhr.send(fd1);
          }
        };

        $scope.close = function (res) {
          $element.modal('hide');
          close({
            editor: editor,
            resURL: $scope.resURL,
            geogebraUrl: res.files[0].urlRemote,
            geogebraParams: res.files[0].geogebraParams,
            interactiveContentType: res.files[0].interactiveContentType,
            res: res,
            save: true
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])


    .controller('EditImageModalCtrl', [
      '$scope', '$element', 'alt', 'caption', 'captionId', 'elementId', 'relationId', 'editorScope',
      function ($scope, $element, alt, caption, captionId, elementId, relationId, editorScope) {

        $scope.resTitle = alt;
        $scope.resDesc = caption;

        $scope.type = elementId.substring(0, 3);

        $scope.close = function () {
          $element.modal('hide');
          if ($(captionId).text()) {
            $(captionId).text($scope.resDesc);
          } else if ($(elementId)[0].parentElement.nodeName === 'FIGURE') {
            var captionToInsert = '<figcaption style="text-align: center;" id="caption' + relationId + '">' + $scope.resDesc + '</figcaption>';
            $(elementId).after(captionToInsert);
          } else {
            $(elementId).wrap('<figure style="margin-left:auto;margin-right:auto;display:block; "></figure>');
            var captionToInsert = '<figcaption style="text-align: center;" id="caption' + relationId + '">' + $scope.resDesc + '</figcaption>';
            $(elementId).after(captionToInsert);
          }
          if ($(elementId).attr('alt')) {
            var imgSelector = "img[data-relation-id='" + relationId + "']";
            $(imgSelector).each(function (i, el) {
              $(el).attr('alt', $scope.resTitle);
            });
          } else {
            $(elementId).attr('aria-label', $scope.resTitle);
          }
          editorScope.displayElements.text.trigger('focus');
          close({
            save: true,
            alt: $scope.resTitle,
            caption: $scope.resDesc
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])

    .controller('DragAndDropModalCtrl', [
      '$scope', '$rootScope', '$element', '$timeout', 'wrapSelection', 'file', 'AuthoringService', 'User', 'close', '$location', 'AppSettings',
      function ($scope, $rootScope, $element, $timeout, wrapSelection, file, AuthoringService, User, close, $location, AppSettings) {

        var derTypeId = 'image';
        var maxSize = AppSettings.getMaxImageSize();
        if (file.type === 'video/mp4') {
          derTypeId = 'video';
          maxSize = AppSettings.getMaxVideoSize();
        } else if (file.type.substring(0, 5) === 'audio') {
          derTypeId = 'audio';
          maxSize = AppSettings.getMaxAudioSize();
        }

        var derId = parseInt($location.search().derid);

        $scope.file = file;

        $scope.fileTitle = null;
        $scope.fileDesc = null;

        var contributors = [];
        if (User.getRelatedContributors()) {
          contributors.push({
            "contributorId": $rootScope.relatedContributors[0].contributorId,
            "role": "author"
          })
        } else {
          contributors.push({
            "firstname": User.getFirstname(),
            "lastname": User.getLastname(),
            "role": "author"
          })
        }

        $scope.uploadFile = function () {

          if (maxSize && maxSize <= $scope.file.size) {
            alert('Datoteka je prevelika! Dodavanje nije uspjelo.');
            $scope.cancel();
          } else {
            $scope.showSpinner = true;

            var contentType = $scope.file.type.substring(6);
            if (contentType === 'jpeg') {
              contentType = 'jpg';
            }

            var req = {
              "derInfo": {
                "derTypeId": derTypeId,
                "title": $scope.fileTitle,
                "description": $scope.fileDesc,
                "copyrightId": 1
              },
              "contributors": contributors,
              "uploadFileInfo": {
                "drmType": "web-accessible",
                "contentTypeId": contentType,
                "contentLength": $scope.file.size
              }
            };
            if ($rootScope.tenant !== 'carnet') {
              req.visibility = "public";
            } else if ($rootScope.tenant === 'carnet') {
              req.visibility = "private";
            }

            var fd1 = new FormData();
            fd1.append("fileInfo", JSON.stringify(req));
            fd1.append("fileStream", $scope.file);
            var xhr = new XMLHttpRequest();
            xhr.open("POST", $rootScope.server + "/der/create/upload");
            xhr.withCredentials = true;

            xhr.onreadystatechange = function () {
              if (xhr.readyState === 4 && xhr.status === 200) {
                var res = JSON.parse(xhr.responseText);
                if (res.err === 0) {
                  $scope.$apply(function () {

                    AuthoringService.addRelation(derId, null, [{
                        targetDerId: res.data.derId,
                        targetDerVersion: res.data.derVersion,
                        relationTypeId: "uses",
                        targetContentTypeId: res.data.files[0].contentTypeId
                      }])
                      .then(function (res2) {
                        if (res2.err == 0) {
                          $rootScope.isLoading = false;
                          $rootScope.$broadcast('versionChanged', {
                            lastVersion: res2.data.lastVersion,
                            createdOn: res2.data.createdOn
                          });
                          $timeout(function () {

                            if (derTypeId === 'image') {
                              wrapSelection('insertImage', {
                                url: res.data.files[0].urlRemote,
                                relationId: res2.data.relations[0].derRelationId,
                                alt: $scope.fileTitle,
                                description: $scope.fileDesc
                              }, true);
                              return (res.data);
                            } else if (derTypeId === 'video') {
                              wrapSelection('insertvid', {
                                url: res.data.files[0].urlRemote,
                                relationId: res2.data.relations[0].derRelationId,
                                description: $scope.fileDesc
                              }, true);
                              return (res.data);
                            } else if (derTypeId === 'audio') {
                              wrapSelection('insertaud', {
                                url: res.data.files[0].urlRemote,
                                relationId: res2.data.relations[0].derRelationId,
                                description: $scope.fileDesc
                              }, true);
                              return (res.data);
                            }
                          }, 1000);
                        } else {
                          $rootScope.isLoading = false;
                        }
                      });


                  });
                  $scope.cancel();
                } else {
                  $scope.$apply(function () {
                    $scope.showSpinner = false;
                    if (res.err === 1 && res.data === 'contentSize') {
                      alert('Datoteka je prevelika!');
                    } else if (res.err == 1) {
                      alert('Unijeli ste neispravnu poveznicu, molimo pokušajte ponovno!');
                    } else if (res.err == 43) {
                      alert('Dohvaćanje resursa nije uspjelo te on nije dodan u dokument!');
                    } else if (res.err == 44) {
                      if (res.data.indexOf('file too large') === 0) {
                        alert('Datoteka je prevelika!');
                      } else if (res.data.indexOf('bad content-type') === 0) {
                        alert('Format ove datoteke nije podržan!');
                      }
                    } else if (res.err == 45) {
                      alert('Poveznica koju ste unijeli nalazi se na lokalnom serveru. Unesite datoteku preko resursa.');
                    } else if (res.err == 46) {
                      alert('Vrsta datoteke koju ubacujete ne odgovara vrsti resursa (za sliku odaberite ubacivanje slike, za video ubacivanje videa, za audio ubacivanje audio materijala).');
                    } else {
                      alert('Dodavanje datoteke nije uspjelo. Molimo pokušajte datoteku dodati na drugi način.');
                    }
                  });

                }
              }
            };
            xhr.send(fd1);
          }
        };

        $scope.close = function () {
          $element.modal('hide');

          close({

            resURL: $scope.resURL,
            save: true
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({

            save: false
          }, 500);
        };

      }
    ])


    .controller('ResourceModalController', [
      '$scope', '$rootScope', '$element', 'resource', 'resourceURL', 'close', '$routeParams', '$location', 'AuthoringService',
      function ($scope, $rootScope, $element, resource, resourceURL, close, $routeParams, $location, AuthoringService) {

        $scope.navigateToPermissions = function () {
          $location.path('/dozvole/' + resource.derId);
          $scope.cancel();
        };

        $scope.resource = resource;

        if ($rootScope.tenant === 'carnet') {
          AuthoringService.getDER(resource.derId, null, true)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.resource = res.data;

              } else {
                $rootScope.isLoading = false;
              }
            });
        };


        $scope.resourceURL = resourceURL;
        $scope.copyright = resource.derInfo.copyright.copyrightId;

        $scope.visibilities = [{
            id: "public",
            label: "Dijeljeno"
          },
          {
            id: "private",
            label: "Ograničeno"
          }
        ];

        $scope.drms = [{
            id: "web-accessible",
            label: "Dostupno svim korisnicima Interneta"
          },
          {
            id: "no-drm",
            label: "Dostupno samo korisnicima Explain sustava"
          },
          {
            id: "lamaro",
            label: "Zaštićeno DRM-om"
          }
        ];

        $scope.copyrights = [{
            id: 1,
            label: "Imenovanje (CC BY)"
          },
          {
            id: 2,
            label: "Imenovanje-Dijeli pod istim uvjetima (CC BY-SA)"
          },
          {
            id: 3,
            label: "Imenovanje-Bez prerada (CC BY-ND)"
          },
          {
            id: 4,
            label: "Imenovanje-Nekomercijalno (CC BY-NC)"
          },
          {
            id: 5,
            label: "Imenovanje-Nekomercijalno-Dijeli pod istim uvjetima (CC BY-NC-SA)"
          },
          {
            id: 6,
            label: "Imenovanje-Nekomercijalno-Bez prerada (CC BY-NC-ND)"
          },
          {
            id: 7,
            label: "Copyright - Sva prava pridržana"
          }
        ];

        $scope.setFiles = function (element) {
          $scope.$apply(function () {
            $scope.file = element.files[0];
          });
        };

        $scope.uploadNewFileVersion = function (derId) {
          // if (maxSize && maxSize <= $scope.file.size) {
          //   alert('Datoteka je prevelika! Dodavanje nije uspjelo.');
          //   $scope.cancel();
          // } else {
          $scope.showSpinner = true;

          var contentType = $scope.file.type.substring(6);
          if (contentType === 'jpeg') {
            contentType = 'jpg';
          }
          if (contentType === 'mpeg') {
            contentType = 'mp3';
          }
          if ($scope.file.name.substring($scope.file.name.length - 3, $scope.file.name.length) === 'vtt') {
            contentType = 'vtt';
          }

          var req = {
            "derId": derId,
            "derInfo": {},
            "uploadFileInfo": {
              "drmType": "web-accessible",
              "contentTypeId": contentType,
              "contentLength": $scope.file.size
            }
          };

          var fd1 = new FormData();
          if (contentType === 'vtt') {
            fd1.append("subtitleFileInfo", JSON.stringify(req));
            fd1.append("subtitleFileStream", $scope.file);
          } else {

            fd1.append("fileInfo", JSON.stringify(req));
            fd1.append("fileStream", $scope.file);
          }

          var xhr = new XMLHttpRequest();
          xhr.open("POST", $rootScope.server + "/der/update/upload");
          xhr.withCredentials = true;

          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
              var res = JSON.parse(xhr.responseText);
              if (res.err === 0) {
                $scope.$apply(function () {
                  $scope.showSpinner = false;
                  $scope.resourceURL = res.data.files[0].urlRemote;
                  $scope.resource.derVersion = res.data.derVersion;
                });
              } else {
                $scope.$apply(function () {
                  $scope.showSpinner = false;
                  if (res.err === 44) {
                    if (res.data.indexOf('file too large') === 0) {
                      alert('Datoteka je prevelika!');
                    } else if (res.data.indexOf('bad content-type') === 0) {
                      alert('Format ove datoteke nije podržan!');
                    }
                  } else {
                    alert('Došlo je do greške! Molimo pokušajte ponovno.');
                  }
                });

              }
            }
          };
          xhr.send(fd1);
          //}
        };

        $scope.showUnitsWhereUsed = function (derId) {
          $rootScope.isLoading = true;
          AuthoringService.listRelatedUnits(derId)
            .then(function (res) {
              if (res.err === 0) {
                $rootScope.isLoading = false;
                $scope.relatedUnits = res.data.items;

              } else {
                $rootScope.isLoading = false;
              }
            })

        };

        $scope.close = function () {
          close({
            resource: $scope.resource,
            copyright: $scope.copyright,
            save: true
          }, 500); // close, but give 500ms for bootstrap to animate
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500); // close, but give 500ms for bootstrap to animate
        };
      }
    ])

    .controller('LinkModalCtrl', [
      '$scope', '$rootScope', '$element', '$location', 'editor', 'close', 'Chapters', 'AuthoringService', 'DictionaryService',
      function ($scope, $rootScope, $element, $location, editor, close, Chapters, AuthoringService, DictionaryService) {

        $scope.internalURL = -1;
        $scope.chapters = Chapters.getChapters();

        $scope.getDERParents = function () {
          AuthoringService.getDER(parseInt($location.search().derid), null, true, true, false, 2, null, false)
            .then(function (res) {
              if (res.err === 0) {
                $scope.parentDOSId = res.data.parents[0].parents[0].derId;
                AuthoringService.getDER($scope.parentDOSId, null, true, false, true, 2, null, false)
                  .then(function (res2) {
                    if (res2.err === 0) {
                      $rootScope.isLoading = false;
                      $scope.modules = res2.data.children;

                    }
                  });
                DictionaryService.getDictionaryTermListForDER($scope.parentDOSId, 0, 1000, null)
                  .then(function (res3) {
                    if (res3.err === 0) {
                      $rootScope.isLoading = false;
                      $scope.dictionaryTermList = res3.data.items;

                    }
                  });
              } else {
                $rootScope.isLoading = false;
              }
            });
        };

        $scope.close = function (linkType, link) {
          $element.modal('hide');
          close({
            link: link,
            linkType: linkType,
            editor: editor,
            save: true
          }, 800); // close, but give 500ms for bootstrap to animate
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500); // close, but give 500ms for bootstrap to animate
        };
      }
    ])

    .controller('TableModalCtrl', [
      '$scope', '$element', '$timeout', 'editor', 'action', 'table', 'close',
      function ($scope, $element, $timeout, editor, action, table, close) {

        $scope.action = action;

        if (action === 'edit') {
          $scope.table = {
            row: table[0].rows.length,
            col: table[0].rows[0].cells.length,
            createTableHeader: table.children('thead').length > 0,
            initRow: table[0].rows.length,
            initCol: table[0].rows[0].cells.length
          };
        } else {
          $scope.table = {
            row: 2,
            col: 2
          };
        }

        function createTable(tableParams) {
          if (angular.isNumber(tableParams.row) && angular.isNumber(tableParams.col) &&
            tableParams.row > 0 && tableParams.col > 0) {
            var table = "<table class='table table-bordered'>";
            var thead = "<thead>";
            var tbody = "<tbody>";
            //var colWidth = 100/tableParams.col;
            for (var idxRow = 0; idxRow < tableParams.row; idxRow++) {
              var row = "<tr>";
              for (var idxCol = 0; idxCol < tableParams.col; idxCol++) {
                if (idxRow === 0 && tableParams.createTableHeader) {
                  row += '<th></th>';
                } else {
                  row += '<td></td>';
                }
              }

              if (idxRow === 0 && tableParams.createTableHeader) {
                thead += row + '</tr>';
              } else {
                tbody += row + "</tr>";
              }
            }

            if (tableParams.createTableHeader) {
              table += thead + '</thead>';
            }

            return table + tbody + "</tbody></table>";
          }
        }

        function updateTable(tableParams) {
          if (angular.isNumber(tableParams.row) && angular.isNumber(tableParams.initRow) && tableParams.row !== tableParams.initRow) {
            var rowDiff = tableParams.row - tableParams.initRow;
            var tableBody = getTableBody(table[0]);

            if (rowDiff > 0) {
              addTableRows(rowDiff, tableParams.col, tableBody);
            } else {
              removeTableRows(-1 * rowDiff, tableBody);
            }
          }

          if (angular.isNumber(tableParams.col) && angular.isNumber(tableParams.initCol) && tableParams.col !== tableParams.initCol) {
            var columnDiff = tableParams.col - tableParams.initCol;

            if (columnDiff > 0) {
              addTableColumns(columnDiff, tableParams.col, table[0], tableParams.createTableHeader);
            } else {
              removeTableColumns(-1 * columnDiff, tableParams.col, table[0]);
            }
          }
        }

        function addTableRows(amount, columnNumber, htmlTable) {
          for (i = 0; i < amount; i++) {
            var newRow = htmlTable.insertRow(htmlTable.rows.length);

            for (j = 0; j < columnNumber; j++) {
              newRow.insertCell(j);
            }
          }
        }

        function removeTableRows(amount, htmlTable) {
          for (i = 0; i < amount; i++) {
            htmlTable.deleteRow(htmlTable.rows.length - 1);
          }
        }

        function addTableColumns(amount, newColumnCount, htmlTable, createTableHeader) {
          for (i = 0; i < htmlTable.rows.length; i++) {
            var row = htmlTable.rows[i];
            if (row.cells.length < newColumnCount) {
              for (j = 0; j < amount; j++) {
                if (i === 0 && createTableHeader) {
                  row.appendChild(document.createElement('th'));
                } else {
                  row.insertCell(row.length);
                }
              }
            }
          }

          //updateFirstRowColumnWidth(htmlTable, newColumnCount);
        }

        function removeTableColumns(amount, newColumnCount, htmlTable) {
          for (i = 0; i < htmlTable.rows.length; i++) {
            var row = htmlTable.rows[i];
            if (row.cells.length > newColumnCount) {
              for (j = 0; j < amount; j++) {
                row.deleteCell(row.cells.length - 1);
              }
            }
          }

          //updateFirstRowColumnWidth(htmlTable, newColumnCount);
        }

        function getTableBody(table) {
          for (i = 0; i < table.children.length; i++)
            if (table.children[i].nodeName.toLowerCase() === 'tbody')
              return table.children[i];
        }

        /*function updateFirstRowColumnWidth(htmlTable, newColumnCount) {
         var colWidth = 100/newColumnCount;
  
         for (i = 0; i < htmlTable.rows[0].cells.length; i++) {
         htmlTable.rows[0].cells[i].style.width = colWidth+'%';
         }
         }*/

        $scope.tblSave = function () {
          $element.modal('hide');
          if (action === 'edit') {
            updateTable($scope.table);
          } else {
            $timeout(function () {
              editor.displayElements.text.trigger('focus');
              editor.wrapSelection('insertHTML', '<span></span>', true);
              editor.wrapSelection('insertHtml', createTable($scope.table), true);
            }, 1000);
          }

          close({
            result: 'OK'
          }, 500);
        };

        $scope.tblCancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])

    .controller('ParseWordCtrl', [
      '$scope', '$rootScope', '$element', '$location', 'close',
      function ($scope, $rootScope, $element, $location, close) {

        $scope.setFiles = function (element) {
          $scope.$apply(function () {
            $scope.file = element.files[0];
          });
        };

        $scope.uploadFile = function () {

          //  if (maxSize && maxSize <= $scope.file.size) {
          //   alert('Datoteka je prevelika! Dodavanje nije uspjelo.');
          //   $scope.cancel();
          // } else {
          $scope.showSpinner = true;

          var req = {
            "derId": parseInt($location.search().derid),
            "uploadFileInfo": {
              "drmType": "web-accessible",
              "contentLength": $scope.file.size
            }
          };

          var fd1 = new FormData();
          fd1.append("fileInfo", JSON.stringify(req));
          fd1.append("fileStream", $scope.file);
          var xhr = new XMLHttpRequest();
          xhr.open("POST", $rootScope.server + "/der/content/upload");
          xhr.withCredentials = true;

          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4 && xhr.status == 200) {
              var res = JSON.parse(xhr.responseText);
              if (res.err === 0) {
                $scope.$apply(function () {
                  $scope.showSpinner = false;
                });
                $scope.cancel();
              } else {
                $scope.$apply(function () {
                  $scope.showSpinner = false;
                  if (res.err === 1 && res.data === 'contentSize') {
                    alert('Datoteka je prevelika!');
                  } else if (res.err == 1) {
                    alert('Unijeli ste neispravnu poveznicu, molimo pokušajte ponovno!');
                  } else if (res.err == 43) {
                    alert('Dohvaćanje resursa nije uspjelo te on nije dodan u dokument!');
                  } else if (res.err == 44) {
                    if (res.data.indexOf('file too large') === 0) {
                      alert('Datoteka je prevelika!');
                    } else if (res.data.indexOf('bad content-type') === 0) {
                      alert('Format ove datoteke nije podržan!');
                    }
                  } else if (res.err == 45) {
                    alert('Poveznica koju ste unijeli nalazi se na lokalnom serveru. Unesite datoteku preko resursa.');
                  } else if (res.err == 46) {
                    alert('Vrsta datoteke koju ubacujete ne odgovara vrsti resursa (za sliku odaberite ubacivanje slike, za video ubacivanje videa, za audio ubacivanje audio materijala).');
                  } else {
                    alert('Dodavanje datoteke nije uspjelo. Molimo pokušajte datoteku dodati na drugi način.');
                  }
                });

              }
            }
          };
          xhr.send(fd1);
          // }
        };

        $scope.close = function () {
          $element.modal('hide');
          close({}, 800); // close, but give 500ms for bootstrap to animate
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({}, 500); // close, but give 500ms for bootstrap to animate
        };
      }
    ])

    .controller('ReportsController',
      ['$scope', '$rootScope', 'ReportService', 'AuthoringService', '$location',
        function ($scope, $rootScope, ReportService, AuthoringService, $location) {

          $scope.reportHeaders = [];
          $scope.reportData = [];

          AuthoringService.listDER('', true, 'dos', 0, 100, false, 'title_az')
            .then(function (res) {
              if (res.err === 0) {
                $scope.doslist = res.data.items;
                $scope.selectedDos = res.data.items[0];
              }
              $rootScope.isLoading = false;
            });

          $scope.showReportData = function (report, derTypeId) {
            if (report.id === 3) {
              ReportService.getDetailedReportForDer($scope.selectedDos.derId)
                .then(function (res) {
                  if (res.err === 0) {
                    createDetailedReportData(res.data);
                  }
                  $rootScope.isLoading = false;
                });
            } else {
              ReportService.getSummaryReportForDerType(derTypeId)
                .then(function (res) {
                  if (res.err === 0) {
                    createSummaryReportData(res.data.items);
                  }
                  $rootScope.isLoading = false;
                });
            }

            $scope.selectedReport = report;
          }

          $scope.getReportCsvFile = function (report, derId) {
            ReportService.getReportCsvFile(report.id, report.derTypeId, derId)
              .then(function (res) {
                if (res.err === undefined) {
                  var csvString = unescape(encodeURIComponent(res));

                  var a = document.createElement('a');

                  a.href = 'data:attachment/csv;base64,77u/' + btoa(csvString);

                  a.target = '_blank';
                  a.download = report.name + ".csv";

                  document.body.appendChild(a);
                  a.click();
                }
                $rootScope.isLoading = false;
              });
          }

          $scope.reportList = [{
              id: 1,
              name: 'Sumarni izvještaj - DOS-ovi',
              derTypeId: 'dos'
            },
            {
              id: 2,
              name: 'Sumarni izvještaj - moduli',
              derTypeId: 'module'
            },
            {
              id: 3,
              name: 'Detaljni izvještaj za DOS',
              derTypeId: 'dos'
            }
          ];

          $scope.reset = function () {
            $scope.reportHeaders = [];
            $scope.reportData = [];
            $scope.selectedReport = null;
          }

          function createDetailedReportData(data) {
            $scope.reportHeaders = ['Modul', 'Jedinica', 'Status jedinice'];

            if (data !== undefined && data.hasOwnProperty('derChildrenDetailedDataDto')) {
              data.derChildrenDetailedDataDto.forEach(function (item) {
                $scope.reportData.push({
                  derId: item.derId,
                  derName: item.derName,
                  derTypeId: item.derTypeId,
                  status: item.derStatusName
                });

                if (item.hasOwnProperty('derChildrenDetailedDataDto')) {
                  item.derChildrenDetailedDataDto.forEach(function (item) {
                    $scope.reportData.push({
                      derId: item.derId,
                      derName: item.derName,
                      derTypeId: item.derTypeId,
                      status: item.derStatusName
                    });
                  });
                }
              });
            }
          }

          function createSummaryReportData(items) {
            $scope.reportData = items;
            $scope.reportHeaders.push(items[0].derTypeId === 'dos' ? 'DOS' : 'Modul');
            $scope.reportHeaders.push('Ukupan broj jedinica');
            items[0].derChildrenStatusCount.forEach(function (item) {
              $scope.reportHeaders.push(item.statusName);
            })
          }
        }
      ])


    .controller('HelpController', [
      '$scope', '$anchorScroll', '$location', 'AppSettings',
      function ($scope, $anchorScroll, $location, AppSettings) {

        $scope.scrollTo = function (id) {
          $location.hash(id);
          $anchorScroll();
        };

        $scope.maxSizeImage = AppSettings.getMaxImageSize();
        $scope.maxSizeVideo = AppSettings.getMaxVideoSize();
        $scope.maxSizeAudio = AppSettings.getMaxAudioSize();

      }
    ])

  /* TODO: this creates many problems with focus/blur listeners (causes multiple event ocurrences) so this has been disabled until proven obsolete
     * maybe this is legacy from older projects so feel free to remove later if no problems have been detected
    .directive('ngFocus', function ($timeout) {
      return {
        link: function (scope, element, attrs) {
          scope.$watch(attrs.ngFocus, function (val) {
            if (angular.isDefined(val) && val) {
              $timeout(function () {
                element[0].focus();
              });
            }
          }, true);
  
          element.bind('blur', function () {
            if (angular.isDefined(attrs.ngFocusLost)) {
              scope.$apply(attrs.ngFocusLost);
  
            }
          });
        }
      };
    })*/
  ;

  function getRelationId(source) {
    var startIndex = source.indexOf('data-relation-id="');
    var relId = source.substring(startIndex);
    var endIndex = relId.indexOf('" ');
    return parseInt(relId.substring(18, endIndex));
  }

  function getResourceDerId(source) {
    var startIndex = source.indexOf('data-resource-der-id="');
    var resId = source.substring(startIndex);
    var endIndex = resId.indexOf('" ');
    return parseInt(resId.substring(22, endIndex));
  }

  function downloadFile(url) {
    var oReq = new XMLHttpRequest();
    oReq.open("GET", url, true);
    oReq.responseType = "arraybuffer";
    oReq.withCredentials = true;
    oReq.onload = function (oEvent) {
      // var res = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(oReq.response)));
      // if (res.err == 0) {
      var blob = new Blob([oReq.response], {
        type: "application/octet-stream"
      });
      var a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.target = '_blank';
      // var filename = 'knjiga.pdf';
      // if (oReq.getResponseHeader("Content-Disposition")) {
      var filename = oReq.getResponseHeader("Content-Disposition").substring(21);
      // }
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      // } else if (res.err == 48) {
      //   alert("Ova knjiga ne sadrži tekstualna poglavlja pa SCORM verzija ne bi imala sadržaj. Kvizove u Moodle prebacite u XML formatu.");
      // }
    };

    oReq.send();
  }

  function downloadFileFromOpenUrl(href, title) {
    var a = document.createElement('a');
    a.href = href;
    a.target = '_blank';
    a.download = title;
    document.body.appendChild(a);
    a.click();
  }
};