'use strict';

angular.module('explainApp', ['ngRoute', 'restangular', 'ngCookies', 'ngStorage', 'ui.bootstrap', 'textAngular', 'ngSanitize', 'ui.sortable', 'ngAnimate', 'angularModalService', 'images-resizer', 'hSweetAlert', 'ngToast', 'ImageCropper', 'angulartics', 'angulartics.google.analytics', 'windowsPopupConfig', 'windowsPopup', 'checklist-model'])

    .config(['$provide', '$locationProvider', '$routeProvider', 'RestangularProvider', '$httpProvider', '$sceDelegateProvider', "ngToastProvider",
        function ($provide, $locationProvider, $routeProvider, RestangularProvider, $httpProvider, $sceDelegateProvider, ngToast) {
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false
            });

            $routeProvider
                .when('/', {
                    templateUrl: '/views/start.html'
                })
                .when('/admin', {
                    templateUrl: '/views/admin.html'
                })
                .when('/audio-snimke', {
                    templateUrl: '/views/resources.html'
                })
                .when('/audio-snimke/:page', {
                    templateUrl: '/views/resources.html'
                })
                .when('/audio-snimke/:page/:searchTerm', {
                    templateUrl: '/views/resources.html'
                })
                .when('/dozvole/:derId', {
                    templateUrl: '/views/permissions.html'
                })
                .when('/grupa/:groupId', {
                    templateUrl: '/views/group.html',
                    controller: 'PermissionsController'
                })
                .when('/grupa/:groupId/dozvole/:groupName', {
                    templateUrl: '/views/groupPermissions.html',
                    controller: 'PermissionsController'
                })
                .when('/grupe', {
                    templateUrl: '/views/groups.html',
                    controller: 'PermissionsController'
                })
                .when('/interakcije', {
                    templateUrl: '/views/resources.html'
                })
                .when('/interakcije/:page', {
                    templateUrl: '/views/resources.html'
                })
                .when('/interakcije/:page/:searchTerm', {
                    templateUrl: '/views/resources.html'
                })
                .when('/knjiga/', {
                    templateUrl: '/views/DOMeditor.html'
                })
                .when('/knjiga/:derId', {
                    templateUrl: '/views/DOMeditor.html'
                })
                .when('/knjiga/:derId/dozvole', {
                    templateUrl: '/views/permissions.html'
                })
                .when('/knjiga/:derId/rjecnik', {
                    templateUrl: '/views/dictionary.html'
                })
                .when('/knjiga/:derId/rjecnik/:searchTerm', {
                    templateUrl: '/views/dictionary.html'
                })
                .when('/knjige', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/knjige/:page', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/knjige/:page/:searchTerm', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/korisnici/', {
                    templateUrl: '/views/users.html',
                    controller: 'PermissionsController'
                })
                .when('/list/', {
                    templateUrl: '/views/list.html'
                })
                .when('/modul/', {
                    templateUrl: '/views/DOMeditor.html'
                })
                .when('/modul/:derId', {
                    templateUrl: '/views/DOMeditor.html'
                })
                .when('/modul/:derId/dozvole', {
                    templateUrl: '/views/permissions.html'
                })
                .when('/moduli', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/moduli/:page', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/moduli/:page/:searchTerm', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/dos/', {
                    templateUrl: '/views/DOMeditor.html'
                })
                .when('/dos/:derId', {
                    templateUrl: '/views/DOMeditor.html'
                })
                .when('/dos/:derId/dozvole', {
                    templateUrl: '/views/permissions.html'
                })
                .when('/dosevi', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/dosevi/:page', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/dosevi/:page/:searchTerm', {
                    templateUrl: '/views/DOMlist.html'
                })
                .when('/izvjestaji', {
                    templateUrl: '/views/reports.html'
                })
                .when('/kviz/', {
                    templateUrl: '/views/QuizEditor.html'
                })
                .when('/kviz/:derId', {
                    templateUrl: '/views/QuizEditor.html'
                })
                .when('/kvizovi', {
                    templateUrl: '/views/resources.html'
                })
                .when('/kvizovi/:page', {
                    templateUrl: '/views/resources.html'
                })
                .when('/kvizovi/:page/:searchTerm', {
                    templateUrl: '/views/resources.html'
                })
                .when('/nocookies', {
                    templateUrl: '/views/noCookies.html',
                    caseInsensitiveMatch: true
                })
                .when('/organizacije', {
                    templateUrl: '/views/organizations.html',
                    controller: 'PermissionsController'
                })
                .when('/organizacije/:organizationId', {
                    templateUrl: '/views/organization.html',
                    controller: 'PermissionsController'
                })
                .when('/pomoc', {
                    templateUrl: '/views/help.html'
                })
                .when('/pretrazivanje', {
                    templateUrl: '/views/search.html'
                })
                .when('/pretrazivanje/:page', {
                    templateUrl: '/views/search.html'
                })
                .when('/pretrazivanje/:page/:searchTerm', {
                    templateUrl: '/views/search.html'
                })
                .when('/resursi', {
                    templateUrl: '/views/resources.html'
                })
                .when('/slike', {
                    templateUrl: '/views/resources.html'
                })
                .when('/slike/:page', {
                    templateUrl: '/views/resources.html'
                })
                .when('/slike/:page/:searchTerm', {
                    templateUrl: '/views/resources.html'
                })
                .when('/video-snimke', {
                    templateUrl: '/views/resources.html'
                })
                .when('/video-snimke/:page', {
                    templateUrl: '/views/resources.html'
                })
                .when('/video-snimke/:page/:searchTerm', {
                    templateUrl: '/views/resources.html'
                })
                .when('/tekstovi', {
                    templateUrl: '/views/resources.html'
                })
                .when('/tekstovi/:page', {
                    templateUrl: '/views/resources.html'
                })
                .when('/tekstovi/:page/:searchTerm', {
                    templateUrl: '/views/resources.html'
                })
                .when('/404', {
                    templateUrl: '/views/404.html'
                })
                .otherwise({
                    redirectTo: '/404'
                });

            $httpProvider.defaults.useXDomain = true;
            delete $httpProvider.defaults.headers.common["X-Requested-With"];
            $httpProvider.defaults.withCredentials = true;
            RestangularProvider.setDefaultHttpFields({
                withCredentials: true
            });

            $sceDelegateProvider.resourceUrlWhitelist(['self', 'blob', 'http://localhost:9000']);

            RestangularProvider.setBaseUrl('');

            ngToast.configure({
                verticalPosition: 'top',
                horizontalPosition: 'center',
                maxNumber: 2
            });

            $provide.decorator("$exceptionHandler", ['$delegate', function ($delegate) {
                return function (exception, cause) {
                    if (exception.codeName !== 'INDEX_SIZE_ERR') {
                        $delegate(exception, cause);
                    }
                };
            }]);

        }
    ])

    .run(['$rootScope', 'Restangular', '$location', '$routeParams', 'RedoRequestService', 'modalService', 'Page', 'User',
        function ($rootScope, Restangular, $location, $routeParams, RedoRequestService, modalService, Page, User) {

            /* var appid = new XMLHttpRequest();
             appid.open("GET", "/appid.txt", false);
             appid.onreadystatechange = function () {
                 if (appid.readyState === 4) {
                     if (appid.status === 200 || appid.status == 0) {
                         var allText = appid.responseText;
                         $rootScope.APPID = allText;
                     }
                 }
             };
             appid.send(null);
             */
            $rootScope.APPID = 0;
            $rootScope.minDate = '2019-09-15';
            $rootScope.maxDate = Date.now();

            /* lan   */
            //$rootScope.server = 'https://explain.tookbook.lan/services/v0';

            //cloudLMS
            //$rootScope.server = 'https://cloudlms.tookbook.com/services/v2';

            //Algebra produkcija
            //$rootScope.server = 'https://172.16.213.99/explain/services/v2';
            //$rootScope.server = 'https://dos.sedamcloud.com/explain/services/v2';

            //Algebra razvoj
            //$rootScope.server = 'http://10.7.71.21:8080/explain/services/v2';

            $rootScope.server = AppConfigService.getValue('api.baseUrl');
            $rootScope.packagingServer = AppConfigService.getValue('api.packagingBaseUrl');

            $rootScope.tenant = AppConfigService.getValue('app.tenant');
            //$rootScope.tenant = 'algebra';

            $rootScope.environment = AppConfigService.getValue('app.environment'); //dev, test, production

            $rootScope.$on('$routeChangeStart', function (scope, next, current) {

                $rootScope.saveInProgress = false;

                if ($location.path().indexOf('knjiga') === 1) {

                } else {
                    Page.setTitle('DOS');
                }

                if (next.templateUrl === '/views/noCookies.html') {
                    $rootScope.isMobile = true;
                } else {
                    $rootScope.isMobile = false;
                }

            });

            Restangular.setRequestInterceptor(function (request, operation, what) {

                $rootScope.isLoading = true;
                // console.log($rootScope.whats);
                return request;
            });

            Restangular.setResponseExtractor(function (res, operation, what, url) {
                //$rootScope.reqs.pop();
                //$rootScope.whats.pop();

                var errorText = "Došlo je do greške! Molimo pokušajte ponovno.";

                switch (res.err) {
                    case 3:
                        errorText = "Problemi s konekcijom. Molimo pokušajte ponovno.";
                        break;
                    case 4:
                        errorText = "Niste ovlašteni za ovu akciju!";
                        break;
                    case 12:
                        errorText = "Materijal je već zaključan! Osvježite svoj prikaz.";
                        break;
                    case 21:
                        errorText = "Unijeli ste pogrešne podatke za prijavu.";
                        break;
                    case 22:
                        errorText = "Niste prijavljeni. Molimo prijavite se za nastavak rada.";
                        $rootScope.isLoggedIn = false;
                        break;
                    case 23:
                        errorText = "Već ste prijavljeni drugim korisnikom. Molimo odjavite se pa ponovno prijavite.";
                        break;
                    case 25:
                        errorText = "Da biste mogli koristiti aplikaciju morate prihvatiti uvjete korištenja.";
                        $location.path("/aai/registracija");
                        break;
                    case 26:
                        errorText = "Ovaj korisnički račun je deaktiviran te se njime nije moguće prijaviti u aplikaciju.";
                        break;
                    case 27:
                        errorText = "Korisnik s ovim korisničkim imenom već postoji, upišite drugo korisničko ime.";
                        break;
                    case 48:
                        errorText = "Ova knjiga ne sadrži tekstualna poglavlja pa SCORM verzija ne bi imala sadržaj. Kvizove u Moodle prebacite u XML formatu.";
                        break;
                    case 100:
                        errorText = "Niste ovlašteni za ovu akciju!";
                        break;
                    case 101:
                        errorText = "Verzija koju uređujete nije najnovija verzija! Prije promjena učitajte novu verziju osvježavanjem prikaza.";
                        break;
                    case 111:
                        errorText = "Niste ovlašteni za ovu akciju!";
                        break;
                    case 112:
                        errorText = "Ovo predavanje nije u odgovarajućem statusu za akciju koju želite provesti. Osvježite prikaz predavanja.";
                        break;
                    case 170:
                        var errors = '';
                        angular.forEach(res.data.warnings, function (e) {
                            errors = errors + "; " + e;
                        });
                        errorText = "Kreiranje ePuba nije uspjelo! Navedeni su sljedeći razlozi: " + errors;
                        break;
                    case 320:
                        errorText = "Već postoji tečaj s tim kratkim imenom. Molimo odaberite drugo kratko ime tečaja.";
                        break;
                    case 321:
                        errorText = "Kreiranje tečaja nije uspjelo. Molimo pokušajte ponovno ili kontaktiraje korisničku podršku.";
                        break;
                    case 420:
                        errorText = "Korisnički račun kojim ste prijavljeni u sustav nema ovlasti za pokrenutu akciju.";
                        break;
                    case 655:
                        errorText = "Kreiranje grupe nije uspijelo jer već postoji grupa s tim aliasom. Unesite drugačiji alias grupe ili iskoristite već postojeću grupu.";
                        break;
                    case 700:
                        errorText = "Organizacija s ovim imenom već postoji. Odaberite drugo ime.";
                        break;
                    case 701:
                        errorText = "Akciju nije moguće izvršiti jer bi time bio nadmašen maksimalni broj korisničkih računa.";
                        break;
                    case 702:
                        errorText = "Akciju nije moguće izvršiti jer bi time bio nadmašen maksimalni broj korisničkih računa u organizaciji.";
                        break;
                    case 703:
                        errorText = "Korisnik nije član organizacije koja ima ovlasti za tu akciju.";
                        break;
                    case 704:
                        errorText = "Ovaj korisnik već pripada drugoj organizaciji.";
                        break;
                    case 800:
                        errorText = "Ovu akciju trebali biste raditi na razini DOS-a.";
                        break;
                    default:
                        errorText = "Došlo je do greške! Molimo pokušajte ponovno.";
                        break;

                }

                if (res.err === 1 && what === 'acl/ugroup/create') {
                    errorText = "Došlo je do greške! Molimo pokušajte ponovno. " + res.msg.substring(1, res.msg.length - 1);
                }

                if (res.err !== undefined && ((res.err != 0 && res.err != 25 && $rootScope.isInitCall == false) || (res.err != 22 && res.err != 0 && $rootScope.isInitCall == true)) && !(res.err == 22 && what === 'session/logout') && (res.err != 301)) {
                    console.error(errorText, res);

                    var modalOptions = {
                        showCancel: false,
                        isError: true,
                        errorNo: res.err,
                        closeButtonText: 'Cancel',
                        actionButtonText: 'OK',
                        headerText: 'GREŠKA (' + res.err + ')',
                        bodyText: errorText
                    };

                    modalService.showModal({}, modalOptions);
                    $rootScope.isLoading = false;
                    if (res.err === 22) {
                        $location.path("/");
                    }
                }

                if (res.err !== undefined && res.err !== 0 && res.err !== 21 && res.err !== 22 && res.err !== 25 && $rootScope.isInitCall === false) {

                    var tags = {};
                    tags.username = User.getUsername();

                    var responseData = angular.copy(res);
                    responseData.timestamp = Date.now();
                    responseData.username = User.getUsername();
                    responseData.location = $location.url();

                    //console.log(JSON.stringify(responseData));
                    ContactService.sendBackendErrorData(responseData.err, responseData, tags);
                }

                return res;
            });

            Restangular.setErrorInterceptor(function (e) {
                $rootScope.isLoading = false;
                var modalOptions = {
                    showCancel: false,
                    isError: true,
                    errorNo: $rootScope.errNo,
                    closeButtonText: 'Cancel',
                    actionButtonText: 'OK',
                    headerText: 'GREŠKA',
                    bodyText: 'Nema odgovora od poslužitelja'
                };

                modalService.showModal({}, modalOptions);
                return e;

            })
        }
    ])