module.exports = function () {
  angular.module('explainApp')

    .controller('UploadImageModalCtrl', [
      '$scope', '$rootScope', '$element', '$location', 'actionType', 'fileType', 'AuthoringService', 'Search', 'User', 'ngToast', 'sweet', 'close', 'AppSettings', 'Chapters', 'ResourceService', 'fileMultimediaData', 'hasAltText',
      function ($scope, $rootScope, $element, $location, actionType, fileType, AuthoringService, Search, User, ngToast, sweet, close, AppSettings, Chapters, ResourceService, fileMultimediaData, hasAltText) {

        $scope.actionType = actionType;
        $scope.fileType = fileType;
        $scope.hasAltText = hasAltText;

        if (fileMultimediaData) {
          $scope.resTitle = fileMultimediaData.title;
          $scope.resDesc = fileMultimediaData.description;
          $scope.resAltText = fileMultimediaData.altText;
        }


        let derTypeId = 'image';
        let maxSizeInBytes = AppSettings.getMaxImageSize();
        if (fileType === 'vid') {
          derTypeId = 'video';
          maxSizeInBytes = AppSettings.getMaxVideoSize();
        } else if (fileType === 'aud') {
          derTypeId = 'audio';
          maxSizeInBytes = AppSettings.getMaxAudioSize();
        }

        $scope.maxSize = (maxSizeInBytes / (1024 * 1024)).toFixed(0) + ' MB';

        Search.setEditorSearchFilter(derTypeId);
        var derId = parseInt($location.search().derid);


        const getContributors = () => {
          const contributors = [];
          if (User.getRelatedContributors()) {
            contributors.push({
              "contributorId": $rootScope.relatedContributors[0].contributorId,
              "role": "author"
            })
          } else {
            contributors.push({
              "firstname": User.getFirstname(),
              "lastname": User.getLastname(),
              "role": "author"
            })
          }

          return contributors;
        }


        const getFileInfo = (derTypeId, title, description, copyrightId, contributors, drmType, contentTypeId, contentLength, visibility) => {
          const fileInfo = {
            derInfo: {
              derTypeId,
              title,
              description,
              copyrightId,
            },
            contributors,
            uploadFileInfo: {
              drmType,
              contentTypeId,
              contentLength,
            },
            visibility,
          };

          return fileInfo;

        };


        const getVisibility = () => {
          if ($rootScope.tenant !== 'carnet') {
            return "public";
          } else if ($rootScope.tenant === 'carnet') {
            return "private";
          }
        }

        const getContentType = (fileStream) => {
          let contentType = fileStream.type.substring(6);
          if (contentType === 'jpeg') {
            contentType = 'jpg';
          }
          if (contentType === 'mpeg') {
            contentType = 'mp3';
          }
          return contentType;
        }

        const createInsertHTML = (title, description, altText, url, relationId, resourceDerId, subtitleUrl) => {

          let insertHTML = null;
          let insertJSON = null;

          insertJSON = {
            resourceDerId,
            relationId,
            title,
            description,
            altText,
          };
          insertHTML = JSON.stringify(insertJSON);

          return insertHTML;

        };

        $scope.setFiles = function (element) {
          $scope.$apply(function () {
            $scope.file = element.files[0];
          });
        };

        $scope.setSubtitleFile = function (element) {
          $scope.$apply(function () {
            $scope.subtitleFile = element.files[0];
          });
        };

        $scope.alertMissingData = function () {
          sweet.show('Ups...', 'Niste unijeli sve obavezne podatke!', 'warning');
        };

        //insert datoteke s računala
        $scope.uploadFile = (title, description, altText) => {
          const fileStream = $scope.file;
          const subtitleFileStream = $scope.subtitleFile;
          let fileInfo, subtitleFileInfo;

          const fileSize = fileStream.size;
          const contributors = getContributors();
          const visibility = getVisibility()

          if (maxSizeInBytes && maxSizeInBytes <= fileSize) {
            alert('Datoteka je prevelika! Dodavanje nije uspjelo.');
            $scope.cancelResourceAdd();
          } else {

            let contentType = getContentType(fileStream);

            fileInfo = getFileInfo(derTypeId, title, description, 1, contributors, "web-accessible", contentType, fileSize, visibility);

            if (subtitleFileStream) {
              subtitleFileInfo = getFileInfo(derTypeId, title + "_subtitle", "", 1, contributors, "web-accessible", "vtt", subtitleFileStream.size, visibility);
            }


            $scope.uploadFileToServer(fileStream, fileInfo, subtitleFileStream, subtitleFileInfo)
              .then((fileObject) => {
                if (actionType === 'insertToEditor') {
                  $scope.$apply(() => {
                    const resourceList = [{
                      targetDerId: fileObject.derId,
                      targetDerVersion: fileObject.derVersion,
                      relationTypeId: "uses",
                      targetContentTypeId: fileObject.files[0].contentTypeId
                    }];
                    $scope.addResourceRelation(derId, Chapters.getNewDerVersion(), resourceList)
                      .then((res) => {
                        $rootScope.$broadcast('versionChanged', {
                          lastVersion: res.lastVersion,
                          createdOn: res.createdOn
                        });
                        let subtitleUrl;
                        if (fileObject.filesCount > 1) {
                          subtitleUrl = fileObject.files[1].urlRemote;
                        }
                        const insertHTML = createInsertHTML(title, description, altText, fileObject.files[0].urlRemote, res.relations[0].derRelationId, fileObject.derId, subtitleUrl);
                        $scope.saveResource(insertHTML);

                      });
                  });

                } else if (actionType === 'insertToResources') {
                  ngToast.create({
                    dismissOnTimeout: true,
                    content: 'Resurs ubačen!',
                    className: 'success'
                  });
                  $scope.saveResource(fileObject);
                } else if (actionType === 'insertToQuiz') {
                  const result = {
                    ...fileObject,
                    derInfo: {
                      title
                    },
                  };
                  $scope.saveResource(result);
                }

              });
          }
        };

        $scope.insertImageFromRepository = (der) => {
          if (actionType === 'insertToEditor') {
            const resourceList = [{
              targetDerId: der.derId,
              targetDerVersion: der.derVersion,
              relationTypeId: "uses",
              targetContentTypeId: der.files[0].contentTypeId
            }];
            $scope.addResourceRelation(derId, null, resourceList)
              .then((res) => {

                $rootScope.$broadcast('versionChanged', {
                  lastVersion: res.lastVersion,
                  createdOn: res.createdOn
                });

                const insertHTMLtitle = $scope.resTitle || der.derInfo.title;
                const insertHTMLdescription = $scope.resDesc || der.derInfo.description;
                const insertHTMLaltText = $scope.altText;

                const insertHTML = createInsertHTML(insertHTMLtitle, insertHTMLdescription, insertHTMLaltText, der.files[0].urlRemote, res.relations[0].derRelationId, der.derId);

                $scope.saveResource(insertHTML);
              });
          } else if (actionType === 'insertToQuiz') {
            $scope.saveResource(der);
          }

        };

        $scope.uploadFileToServer = (fileStream, fileInfo, subtitleFileStream, subtitleFileInfo) => {

          $scope.showSpinner = true;

          var formData = new FormData();
          formData.append("fileInfo", JSON.stringify(fileInfo));
          formData.append("fileStream", fileStream);

          if (subtitleFileStream && subtitleFileInfo) {
            formData.append("subtitleFileInfo", JSON.stringify(subtitleFileInfo));
            formData.append("subtitleFileStream", subtitleFileStream);
          }

          return ResourceService.uploadFormDataToServer(formData)
            .then((res) => {
              if (res.err !== 0) {
                throw res;
              }
              return res.data;
            })
            // handle errors
            .catch((result) => {
              console.log("Error uploading data to server", result);

              $scope.$apply(() => {

                if (result.err === 44) {
                  if (result.data.indexOf('file too large') === 0) {
                    alert('Datoteka je prevelika!');
                  } else if (result.data.indexOf('bad content-type') === 0) {
                    alert('Format ove datoteke nije podržan!');
                  }
                } else {
                  alert('Došlo je do greške! Molimo pokušajte ponovno.');
                }
              });
            })
            .finally(() => {
              $scope.showSpinner = false;
            });
        };

        $scope.addResourceRelation = (derId, version, resourceList) => {

          return AuthoringService.addRelation(derId, version, resourceList)
            .then((res) => {
              if (res.err !== 0) {
                throw res;
              }
              return res.data;
            })
            // handle errors
            .catch((result) => {
              console.log("Error adding relation to file", result);
            })
            .finally(() => {
              $scope.showSpinner = false;
              $rootScope.isLoading = false;
            });
        };

        $scope.saveResource = (res) => {
          $element.modal('hide');
          close({
            resURL: $scope.resURL,
            res: res,
            save: true
          }, 500);
        };

        $scope.cancelResourceAdd = () => {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

      }
    ])

}