/**
 * Created with JetBrains WebStorm.
 * User: sgrdjan
 * Date: 16.09.13.
 * Time: 16:31
 * To change this template use File | Settings | File Templates.
 */

module.exports = function () {
    angular.module('explainApp')
        .controller('UserLoginController', ['$scope', '$rootScope', '$cookieStore', '$location', 'UserLoginService', 'User', 'ngToast', 'AppSettings',
            function UserLoginController($scope, $rootScope, $cookieStore, $location, UserLoginService, User, ngToast, AppSettings) {

                $rootScope.userLoginData = {};

                $scope.login = function () {
                    $rootScope.wrongLogin = false;
                    $rootScope.logoutClicked = false;
                    $scope.userLoginData = {
                        username: $("#inputEmail").val(),
                        pw: $("#inputPassword").val()
                    };

                    if ($scope.userLoginData.username == null || $scope.userLoginData.pw == null ||
                        $scope.userLoginData.username == undefined || $scope.userLoginData.pw == undefined ||
                        $scope.userLoginData.username == '' || $scope.userLoginData.pw == '') {
                        $rootScope.wrongLogin = true;
                    }

                    var pwhash = SHA1($scope.userLoginData.pw.trim());
                    UserLoginService.doLogin($scope.userLoginData.username, pwhash)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                User.setUser(res.data.userId, res.data.username, res.data.nickname, res.data.firstname, res.data.lastname, res.data.role, res.data.relatedContributors, res.data.status, res.data.roles, res.data.organizationInfo);
                                UserLoginService.getSettings()
                                    .then(function (res2) {
                                        if (res2.err === 0) {
                                            $rootScope.isLoading = false;
                                            angular.forEach(res2.data, function (s) {
                                                if (s.key === 'content.maxsize.audio') {
                                                    AppSettings.setMaxAudioSize(s.value);
                                                } else if (s.key === 'content.maxsize.video') {
                                                    AppSettings.setMaxVideoSize(s.value);
                                                } else if (s.key === 'content.maxsize.img') {
                                                    AppSettings.setMaxImageSize(s.value);
                                                }
                                            });

                                        } else {
                                            $rootScope.isLoading = false;
                                        }
                                    });
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Uspješna prijava',
                                    className: 'success'
                                });
                                $location.path('/list');
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                };

                $scope.keepAlive = function () {
                    UserLoginService.doKeepAlive(false)
                        .then(function (res) {
                            if (res.err === 0) {
                                User.setUser(res.data.userId, res.data.username, res.data.nickname, res.data.firstname, res.data.lastname, res.data.role, res.data.relatedContributors, res.data.status, res.data.roles, res.data.organizationInfo);
                                UserLoginService.getSettings()
                                    .then(function (res2) {
                                        if (res2.err === 0) {
                                            $rootScope.isLoading = false;
                                            angular.forEach(res2.data, function (s) {
                                                if (s.key === 'content.maxsize.audio') {
                                                    AppSettings.setMaxAudioSize(s.value);
                                                } else if (s.key === 'content.maxsize.video') {
                                                    AppSettings.setMaxVideoSize(s.value);
                                                } else if (s.key === 'content.maxsize.img') {
                                                    AppSettings.setMaxImageSize(s.value);
                                                }
                                            });

                                        } else {
                                            $rootScope.isLoading = false;
                                        }
                                    });
                            }
                        });
                };

            }
        ])
        .controller('UserActivateController', ['$scope', '$rootScope', '$location', 'UserLoginService', 'User', 'AppSettings',
            function UserLoginController($scope, $rootScope, $location, UserLoginService, User, AppSettings) {

                $scope.termsConfirmed = false;

                $scope.activateUser = function () {
                    $rootScope.isLoading = true;
                    UserLoginService.userActivate()
                        .then(function (res) {
                            if (res.err === 0) {
                                User.setUser(res.data.userId, res.data.username, res.data.nickname, res.data.firstname, res.data.lastname, res.data.role, res.data.relatedContributors, res.data.status, res.data.roles, res.data.organizationInfo);
                                UserLoginService.getSettings()
                                    .then(function (res2) {
                                        if (res2.err === 0) {
                                            $rootScope.isLoading = false;
                                            angular.forEach(res2.data, function (s) {
                                                if (s.key === 'content.maxsize.audio') {
                                                    AppSettings.setMaxAudioSize(s.value);
                                                } else if (s.key === 'content.maxsize.video') {
                                                    AppSettings.setMaxVideoSize(s.value);
                                                } else if (s.key === 'content.maxsize.img') {
                                                    AppSettings.setMaxImageSize(s.value);
                                                }
                                            });

                                        } else {
                                            $rootScope.isLoading = false;
                                        }
                                    });
                                $rootScope.isLoading = false;
                                $location.path('/list');
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                }
            }
        ])


        /* izlogiravanje korisnika - zove servis UserService i funkciju za izlogiravanje */
        .controller('UserLogoutController', ['$scope', '$rootScope', 'UserLogoutService', 'User', '$location', 'ngToast', function UserLogoutController($scope, $rootScope, UserLogoutService, User, $location, ngToast) {
            $scope.logout = function () {
                $rootScope.logoutClicked = true;
                UserLogoutService.doLogout()
                    .then(function (res) {
                        if (res.err == 0 || res.err == 22) {
                            $rootScope.isLoading = false;
                            User.deleteUserData();
                            ngToast.create({
                                dismissOnTimeout: true,
                                content: 'Uspješna odjava',
                                className: 'success'
                            });
                        } else {
                            $rootScope.isLoading = false;
                        }
                        $location.path("/");
                    });
            };
        }])

        .controller('AAIErrorController', ['$scope', '$rootScope', '$routeParams', 'User', '$location', 'modalService', function UserLogoutController($scope, $rootScope, $routeParams, User, $location, modalService) {
            $scope.throwError = function () {
                var err = parseInt($routeParams.errorId);
                var errorText = "Došlo je do greške! Molimo pokušajte ponovno.";
                switch (err) {
                    case 23:
                        errorText = "Već ste prijavljeni drugim korisnikom. Molimo odjavite se pa ponovno prijavite.";
                        break;
                    case 26:
                        errorText = "Ovaj korisnički račun je deaktiviran te se njime nije moguće prijaviti u aplikaciju.";
                        break;
                    case 61:
                        errorText = "AAI prijava nije uspjela zbog nepostojećih podataka u Vašem AAI računu. Molimo kontaktirajte administratora.";
                        break;
                    case 62:
                        errorText = "AAI prijava nije uspjela zbog nepostojećih podataka u Vašem AAI računu. Molimo kontaktirajte administratora.";
                        break;
                    default:
                        errorText = "Došlo je do greške! Molimo pokušajte ponovno.";
                        break;
                }
                var modalOptions = {
                    showCancel: false,
                    isError: true,
                    errorNo: err,
                    closeButtonText: 'Cancel',
                    actionButtonText: 'OK',
                    headerText: err ? 'GREŠKA (' + err + ')' : 'GREŠKA',
                    bodyText: errorText
                };

                modalService.showModal({}, modalOptions);
                $rootScope.isLoading = false;
                $location.path("/");

            };

        }])

        .controller('PermissionsController', ['$scope', '$rootScope', '$routeParams', 'filterFilter', '$location', 'PermissionService', 'AuthoringService', 'modalService', 'ModalService', 'sweet', 'ngToast',
            function PermissionsController($scope, $rootScope, $routeParams, filterFilter, $location, PermissionService, AuthoringService, modalService, ModalService, sweet, ngToast) {

                $scope.changePage = function (newPage, source) {
                    $scope.currentPage = newPage;
                    if (source === 'groups') {
                        $scope.getGroupListTable();
                    }
                };
                $scope.currentPage = 0;

                $scope.getGroupDetails = function () {
                    PermissionService.groupDetails(parseInt($routeParams.groupId))
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.currentGroup = res.data;
                                $scope.getGroupUsersList($routeParams.groupId);
                            } else {
                                $rootScope.isLoading = false;
                            }
                        }, function () {

                        });
                };

                $scope.groupOwner = false;
                if ($location.search().moje === 'true' || $location.search().moje === true) {
                    $scope.groupOwner = true;
                }
                $scope.groupOwners = [{
                        id: true,
                        label: 'Samo moje'
                    },
                    {
                        id: false,
                        label: 'Sve grupe'
                    }
                ];

                $scope.searchGroups = function (searchTerm) {
                    $scope.searchTerm = searchTerm;
                    if ($scope.searchTerm) {
                        $location.path($location.path()).search({
                            moje: $scope.groupOwner,
                            pretraga: $scope.searchTerm
                        });
                    } else {
                        $location.path($location.path()).search({
                            moje: $scope.groupOwner
                        });
                    }
                };

                $scope.searchTerm = $location.search().pretraga;

                $scope.currentPageGroup = 0;
                $scope.changePageGroup = function (newPage) {
                    $scope.currentPageGroup = newPage;
                    $scope.getGroupUsersList($routeParams.groupId);
                };

                $scope.getGroupUsersList = function (gid) {
                    PermissionService.listUsers(gid, $scope.currentPageGroup)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.currentGroupUsers = res.data.items;

                                if (res.data.totalItems === 0) {
                                    $scope.noResults = true;
                                } else {
                                    $scope.noResults = false;
                                    $scope.NoPages = Math.ceil(res.data.totalItems / 50);

                                    $scope.pages = [];
                                    for (var i = 0; i < $scope.NoPages; i++) {
                                        $scope.pages.push(i);
                                    }
                                }
                            } else {
                                $rootScope.isLoading = false;
                            }
                        }, function () {

                        });
                };


                $scope.getGroupListTable = function () {
                    PermissionService.groupListTable($scope.currentPage, $scope.groupOwner, $scope.searchTerm)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.groups = res.data.items;
                                $scope.visibleIndex = null;
                                $scope.NoPages = Math.ceil(res.data.totalItems / 8);
                                $scope.pages = [];
                                for (var i = 0; i < $scope.NoPages; i++) {
                                    $scope.pages.push(i);
                                }
                            } else {
                                $rootScope.isLoading = false;
                            }
                        }, function () {

                        });
                };

                $scope.createNewUser = function (firstName, lastName, username, password, groups, organizationId) {
                    PermissionService.createUser(firstName, lastName, username, password, groups, organizationId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                if ($rootScope.tenant === 'carnet') {
                                    $scope.getOrganizationGroupList();
                                }
                            }
                        }, function () {
                            $rootScope.isLoading = false;

                        });
                };

                $scope.userChangePassword = (userId, password) => {
                    PermissionService.userChangePassword(userId, password)
                        .then(
                            (res) => {
                                if (res.err === 0) {
                                    ngToast.create({
                                        dismissOnTimeout: true,
                                        content: 'Lozinka ažurirana.',
                                        className: 'success'
                                    });
                                }
                            },
                            () => {}
                        )
                        .finally(
                            () => {
                                $rootScope.isLoading = false;
                            }
                        );
                };

                $scope.createNewGroup = function (code, name, desc) {
                    PermissionService.createGroup(code, name, desc)
                        .then(function (res) {
                            if (res.err === 0) {
                                $location.path("/grupa/" + res.data.id);
                            }
                        }, function () {

                        });
                };

                $scope.changeGroupName = function () {
                    $rootScope.isLoading = true;
                    PermissionService.gNameChange($scope.currentGroup.id, $scope.currentGroup.name, $scope.currentGroup.code, $scope.currentGroup.description)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Grupa ažurirana.',
                                    className: 'success'
                                });
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                };

                $scope.groupRemove = function () {
                    sweet.show({
                        title: 'Jeste li sigurni?',
                        text: 'Grupa će biti nepovratno obrisana.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: "Da, obriši!",
                        closeOnConfirm: true
                    }, function (isConfirm) {
                        $scope.$apply(function () {
                            if (isConfirm) {
                                PermissionService.deleteGroup($scope.currentGroup.id)
                                    .then(function (res) {
                                        if (res.err === 0) {
                                            $location.path("/grupe");
                                        }
                                    }, function () {

                                    });
                            }

                        });
                    })


                };


                $scope.userToGroupAdd = function (gId, uIds) {
                    $rootScope.isLoading = true;

                    PermissionService.addUserToGroup(gId, uIds)
                        .then(function (res) {
                            if (res.err === 0) {

                                $scope.getGroupUsersList(gId);
                                $rootScope.isLoading = false;
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Korisnik dodan.',
                                    className: 'success'
                                });
                            } else {
                                $rootScope.isLoading = false;
                            }
                        }, function () {

                        });
                };

                $scope.userFromGroup = null;
                $scope.userFromGroupRemove = function (gId, uId) {
                    $rootScope.isLoading = true;
                    var uIds = [];
                    if (uId == null) {
                        uIds.push($scope.userFromGroup);
                    } else {
                        uIds.push(uId);
                    }

                    PermissionService.removeUserFromGroup(gId, uIds)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Korisnik obrisan.',
                                    className: 'success'
                                });
                                $scope.getGroupUsersList(gId);
                            } else {
                                $rootScope.isLoading = false;
                            }
                        }, function () {

                        });
                };

                $scope.derGet = function () {

                    $scope.backToResources = false;
                    if ($location.path().indexOf('dozvole') === 1) {
                        $scope.backToResources = true;
                    }

                    AuthoringService.getDER($routeParams.derId, false, true)
                        .then(function (res) {

                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.derId = res.data.derId;
                                $scope.derInfo = res.data.derInfo;
                                $scope.title = res.data.derInfo.title;
                                $scope.visibility = res.data.visibility;
                                $scope.publicOnInit = false;
                                if ($scope.visibility === 'public') {
                                    $scope.publicOnInit = true;
                                }
                                $scope.copyright = res.data.derInfo.copyright.copyrightId;
                                $scope.style = res.data.derInfo.derStyleId;
                                $scope.lastVersion = res.data.derVersion;
                                $scope.description = res.data.derInfo.description;
                                $scope.status = res.data.derInfo.derStatus.derStatusId;
                                $scope.userPermissions = res.data.userPermissions;
                                $scope.configuration = res.data.configuration ? res.data.configuration : {};

                                //$scope.getUsersForDer();
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                };

                if ($rootScope.tenant === 'algebra') {
                    $scope.statuses = [{
                            id: 1,
                            label: "Nezapočeta"
                        },
                        {
                            id: 2,
                            label: "U izradi"
                        },
                        {
                            id: 3,
                            label: "Draft"
                        },
                        {
                            id: 4,
                            label: "Spremna za multimediju"
                        },
                        {
                            id: 5,
                            label: "Draft s multimedijom"
                        },
                        {
                            id: 6,
                            label: "Testiranje"
                        },
                        {
                            id: 7,
                            label: "Priprema za recenziju"
                        },
                        {
                            id: 8,
                            label: "Prva iteracija recenzije"
                        },
                        {
                            id: 9,
                            label: "Prva dorada"
                        },
                        {
                            id: 10,
                            label: "Lektura"
                        },
                        {
                            id: 11,
                            label: "Uređivanje"
                        },
                        {
                            id: 12,
                            label: "Druga iteracija recenzije"
                        },
                        {
                            id: 13,
                            label: "Završne dorade"
                        },
                        {
                            id: 14,
                            label: "Završena"
                        }
                    ];
                } else if ($rootScope.tenant === 'carnet') {
                    $scope.statuses = [{
                            id: 1,
                            label: "Sadržaj u izradi"
                        },
                        {
                            id: 2,
                            label: "Spreman za pregled urednika"
                        },
                        {
                            id: 3,
                            label: "Završen i spreman za objavu"
                        }
                    ];
                }

                $scope.copyrights = [{
                        id: 1,
                        label: "Imenovanje (CC BY)"
                    },
                    {
                        id: 2,
                        label: "Imenovanje-Dijeli pod istim uvjetima (CC BY-SA)"
                    },
                    {
                        id: 3,
                        label: "Imenovanje-Bez prerada (CC BY-ND)"
                    },
                    {
                        id: 4,
                        label: "Imenovanje-Nekomercijalno (CC BY-NC)"
                    },
                    {
                        id: 5,
                        label: "Imenovanje-Nekomercijalno-Dijeli pod istim uvjetima (CC BY-NC-SA)"
                    },
                    {
                        id: 6,
                        label: "Imenovanje-Nekomercijalno-Bez prerada (CC BY-NC-ND)"
                    },
                    {
                        id: 7,
                        label: "Copyright - Sva prava pridržana"
                    }
                ];

                $scope.visibilities = [{
                        id: "public",
                        label: "Dijeljeno"
                    },
                    {
                        id: "private",
                        label: "Ograničeno"
                    }
                ];

                $scope.changeStatus = function () {
                    AuthoringService.changeDERStatus($scope.derId, $scope.lastVersion, $scope.status)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.lastVersion = res.data.derVersion;
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Status ažuriran.',
                                    className: 'success'
                                });

                            } else {
                                $rootScope.isLoading = false;
                            }
                        })
                };

                $scope.changeConfiguration = function () {
                    AuthoringService.updateConfiguration($scope.derId, $scope.configuration)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Konfiguracija ažurirana.',
                                    className: 'success'
                                });
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                };

                $scope.openSearchModal = function (what, data) {

                    var title = 'Pretraživanje';
                    var type = 'user';
                    if (what === 'userToGroup') {
                        title = 'Dodavanje korisnika u grupu';

                    } else if (what === 'userToDer') {
                        title = 'Dodjela dozvola korisniku';
                    } else if (what === 'groupToDer') {
                        title = 'Dodjela dozvola grupi';
                        type = 'group';
                    }

                    ModalService.showModal({
                        templateUrl: "/views/partials/addUsersToGroupModal.html",
                        controller: "AddUsersToGroupModalController",
                        inputs: {
                            type: type,
                            title: title
                        }
                    }).then(function (modal) {
                        modal.element.modal();
                        modal.close.then(function (res) {
                            if (res.save && res.selected.length > 0) {
                                var uIds = [];
                                if (type === 'user') {
                                    angular.forEach(res.selected, function (u) {
                                        uIds.push(u.userId);
                                    });
                                } else if (type === 'group') {
                                    angular.forEach(res.selected, function (u) {
                                        uIds.push(u.id);
                                    });
                                }
                                if (what === 'userToGroup') {
                                    $scope.userToGroupAdd(data, uIds);

                                } else if (what === 'userToDer') {
                                    $scope.addToDer(data, uIds, 'U');
                                } else if (what === 'groupToDer') {

                                    $scope.addToDer(data, uIds, 'G');
                                }
                            }

                        });
                    });
                };


                $scope.saveMetaAndPermissions = function () {

                    AuthoringService.updateMetadata($scope.derId, $scope.title, $scope.visibility, $scope.style, $scope.copyright, $scope.lastVersion, $scope.description)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.lastVersion = res.data.derVersion;
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Dozvole ažurirane.',
                                    className: 'success'
                                });

                            } else {
                                $rootScope.isLoading = false;
                            }
                        });

                };

                $scope.openNewGroupModal = function () {
                    ModalService.showModal({
                        templateUrl: "/views/partials/newGroupModal.html",
                        controller: "NewGroupModalController",
                        inputs: {}
                    }).then(function (modal) {
                        modal.element.modal();
                        modal.close.then(function (res) {

                            if (res.save) {
                                $scope.createNewGroup(res.newGroupCode, res.newGroupName, res.newGroupDesc);
                            }

                        });
                    });
                };


                $scope.openNewUserModal = function () {
                    var grouplist = $scope.organizationGroups;
                    ModalService.showModal({
                        templateUrl: "/views/partials/newUserModal.html",
                        controller: "NewUserModalController",
                        inputs: {
                            "grouplist": grouplist
                        }
                    }).then(function (modal) {
                        modal.element.modal();
                        modal.close.then(function (res) {

                            if (res.save && $rootScope.tenant !== 'carnet') {
                                $scope.createNewUser(res.newUserFirstName, res.newUserLastName, res.newUserUsername.toLowerCase().trim(), res.newUserPassword, res.groups);
                            } else if (res.save && $rootScope.tenant === 'carnet') {
                                $scope.createNewUser(res.newUserFirstName, res.newUserLastName, res.newUserUsername.toLowerCase().trim(), res.newUserPassword, res.groups, $routeParams.organizationId);
                            }
                        });
                    });
                };

                $scope.openEditUserModal = (user) => {

                    ModalService.showModal({
                        templateUrl: "/views/partials/editUserModal.html",
                        controller: "EditUserModalController",
                        inputs: {
                            "user": user,
                        }
                    }).then(
                        (modal) => {
                            modal.element.modal();
                            modal.close.then(
                                (res) => {
                                    if (res.save) {
                                        $scope.userChangePassword(res.userId, res.editUserPassword);
                                    }
                                }
                            );
                        });
                };

                $scope.getOrganizationList = function () {
                    PermissionService.organizationsList()
                        .then(function (res) {
                            if (res.err === 0) {
                                $scope.organizations = res.data.list.items;
                                $scope.organizationStatus = res.data.organizationStatus;
                                $rootScope.isLoading = false;
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });
                };

                $scope.getOrganizationGroupList = function () {
                    PermissionService.organizationGroupsList($routeParams.organizationId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $scope.organizationGroups = res.data.items;
                                $rootScope.isLoading = false;
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });
                };

                $scope.getOrganization = function () {
                    PermissionService.organizationGet($routeParams.organizationId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $scope.organization = res.data;
                                $rootScope.isLoading = false;
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });
                };

                $scope.deactivateOrganization = function () {
                    PermissionService.organizationDeactivate($routeParams.organizationId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $scope.getOrganization();
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });
                };

                $scope.activateOrganization = function () {
                    PermissionService.organizationActivate($routeParams.organizationId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $scope.getOrganization();
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });
                };

                $scope.openOrganizationModal = function (organizationId, name, accountNumberLimit) {
                    ModalService.showModal({
                        templateUrl: "/views/partials/organizationModal.html",
                        controller: "OrganizationModalController",
                        inputs: {
                            "name": name,
                            "accountNumberLimit": accountNumberLimit
                        }
                    }).then(function (modal) {
                        modal.element.modal();
                        modal.close.then(function (res) {

                            if (res.save) {
                                if (organizationId) {
                                    PermissionService.organizationEdit(organizationId, res.name, res.accountNumberLimit)
                                        .then(function (res) {
                                            if (res.err === 0) {
                                                $scope.organization = res.data;
                                                $rootScope.isLoading = false;
                                            }
                                        }, function () {
                                            $rootScope.isLoading = false;
                                        });
                                } else {
                                    PermissionService.organizationCreate(res.name, res.accountNumberLimit)
                                        .then(function (res) {
                                            if (res.err === 0) {
                                                $scope.getOrganizationList();
                                            }
                                        }, function () {
                                            $rootScope.isLoading = false;
                                        });
                                }
                            }

                        });
                    });
                };



                $scope.searchTerm = "*";
                $scope.searchUser = function () {
                    PermissionService.userSearch($scope.searchTerm, 0, 250)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.users = res.data.items;
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });

                };
                if ($rootScope.tenant !== 'carnet') {
                    $scope.searchUser();
                }

                $scope.disableUser = function (userId) {
                    PermissionService.userDeactivate(userId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                if ($rootScope.tenant !== 'carnet') {
                                    $scope.searchUser($scope.searchTerm);
                                } else if ($rootScope.tenant === 'carnet') {
                                    $scope.getGroupUsersList($routeParams.groupId);
                                }
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });

                };

                $scope.enableUser = function (userId) {
                    PermissionService.userActivate(userId)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                if ($rootScope.tenant !== 'carnet') {
                                    $scope.searchUser($scope.searchTerm);
                                } else if ($rootScope.tenant === 'carnet') {
                                    $scope.getGroupUsersList($routeParams.groupId);
                                }
                            }
                        }, function () {
                            $rootScope.isLoading = false;
                        });

                };

                $scope.handleDisableUserButton = (user) => {
                    sweet.show({
                        title: 'Hmmm...',
                        text: 'Želite li stvarno deaktivirati korisnika?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: "Da, želim deaktivirati!",
                        closeOnConfirm: true
                    }, () => {

                        $scope.disableUser(user.userId);
                    })

                };

                $scope.currentPageOwners = 0;
                $scope.currentPageViewers = 0;
                $scope.currentPageModifiers = 0;

                /*$scope.changePageViewers = function (newPage) {
                    $scope.currentPageViewers = newPage;
                    $scope.getUsersForDer();
                };
    
                $scope.changePageModifiers = function (newPage) {
                    $scope.currentPageModifiers = newPage;
                    $scope.getUsersForDer();
                };*/

                $scope.getUsersForDer = function () {
                    PermissionService.listUsersForDer($scope.derId, $scope.currentPageOwners, $scope.currentPageViewers, $scope.currentPageModifiers)
                        .then(function (res) {
                            if (res.err === 0) {
                                $scope.noResultsViewers = false;
                                $scope.noResultsModifiers = false;

                                if (res.data.viewers.totalItems === 0) {
                                    $scope.noResultsViewers = true;
                                    $scope.usersgroupsView = [];
                                }
                                if (res.data.modifiers.totalItems === 0) {
                                    $scope.noResultsModifiers = true;
                                    $scope.usersgroupsEdit = [];
                                }

                                if (!$scope.noResultsViewers && !$scope.noResultsModifiers) {

                                    $scope.usersgroupsView = res.data.viewers.items;
                                    $scope.usersgroupsEdit = res.data.modifiers.items;
                                    $scope.owner = res.data.owners.items[0].id;

                                    $scope.NoPagesViewers = Math.ceil(res.data.viewers.totalItems / 8);

                                    $scope.pagesViewers = [];
                                    for (var i = 0; i < $scope.NoPagesViewers; i++) {
                                        $scope.pagesViewers.push(i);
                                    }

                                    $scope.NoPagesModifiers = Math.ceil(res.data.modifiers.totalItems / 8);
                                    $scope.pagesModifiers = [];
                                    for (var j = 0; j < $scope.NoPagesModifiers; j++) {
                                        $scope.pagesModifiers.push(i);
                                    }
                                }

                                $rootScope.isLoading = false;

                            } else {
                                $rootScope.isLoading = false;
                            }
                        });

                };

                $scope.removeFromDer = function (permission, id, type) {
                    var userIds = null;
                    var ugroupIds = null;
                    if (type === 'U') {
                        userIds = [id];
                    } else {
                        ugroupIds = [id];
                    }

                    PermissionService.derRemove($scope.derId, permission, userIds, ugroupIds)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.getUsersForDer();
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Dozvola obrisana.',
                                    className: 'success'
                                });
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                };

                $scope.addToDer = function (permission, ids, type) {
                    var userIds = null;
                    var ugroupIds = null;
                    if (type === 'U') {
                        userIds = ids;
                    } else {
                        ugroupIds = ids;
                    }

                    PermissionService.derAdd($scope.derId, permission, userIds, ugroupIds)
                        .then(function (res) {
                            if (res.err === 0) {
                                $rootScope.isLoading = false;
                                $scope.getUsersForDer();
                                ngToast.create({
                                    dismissOnTimeout: true,
                                    content: 'Dozvole dodijeljene.',
                                    className: 'success'
                                });
                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                }

            }
        ])

        .
    controller('AddUsersToGroupModalController', [
            '$scope', '$rootScope', '$element', 'title', 'type', 'close', 'sweet', 'PermissionService',
            function ($scope, $rootScope, $element, title, type, close, sweet, PermissionService) {

                $scope.title = title;

                $scope.type = type;

                $scope.currentPage = 0;

                if (type === 'user') {
                    $scope.users = [];
                    $scope.searchUsers = function () {
                        $scope.users = [];
                        PermissionService.userSearch($scope.searchTerm, $scope.currentPage)
                            .then(function (res) {
                                if (res.err === 0) {
                                    $rootScope.isLoading = false;
                                    $scope.users = res.data.items;
                                    if (res.data.totalItems === 0) {
                                        $scope.noResults = true;
                                    } else {
                                        $scope.noResults = false;
                                        $scope.NoPages = Math.ceil(res.data.totalItems / 25);

                                        $scope.pages = [];
                                        for (var i = 0; i < $scope.NoPages; i++) {
                                            $scope.pages.push(i);
                                        }
                                    }

                                } else {
                                    $rootScope.isLoading = false;
                                }
                            });
                    };
                } else if (type === 'group') {
                    $scope.groups = [];
                    $scope.searchUsers = function () {
                        $scope.groups = [];
                        PermissionService.groupListTable($scope.currentPage, false, $scope.searchTerm)
                            .then(function (res) {
                                if (res.err === 0) {
                                    $rootScope.isLoading = false;
                                    $scope.groups = res.data.items;
                                    if (res.data.totalItems === 0) {
                                        $scope.noResults = true;
                                    } else {
                                        $scope.noResults = false;
                                        $scope.NoPages = Math.ceil(res.data.totalItems / 8);

                                        $scope.pages = [];
                                        for (var i = 0; i < $scope.NoPages; i++) {
                                            $scope.pages.push(i);
                                        }
                                    }

                                } else {
                                    $rootScope.isLoading = false;
                                }
                            });
                    };
                }
                $scope.changePage = function (newPage) {
                    $scope.currentPage = newPage;
                    $scope.searchUsers();
                };

                $scope.selection = [];

                $scope.toggleSelection = function toggleSelection(u) {
                    var idx = -1;
                    angular.forEach($scope.selection, function (s, i) {
                        if ($scope.type === 'user' && s.userId === u.userId) {
                            idx = i;
                        } else if (type === 'group' && s.id === u.id) {
                            idx = i;
                        }
                    });

                    if (idx > -1) {
                        $scope.selection.splice(idx, 1);
                    } else {
                        $scope.selection.push(u);
                    }
                };

                $scope.arrayObjectIndexOf = function (u) {
                    if ($scope.selection.length < 1) {
                        return false;
                    }
                    for (var i = 0, len = $scope.selection.length; i < len; i++) {

                        if ($scope.type === 'user') {
                            if ($scope.selection[i].userId === u.userId) return true;
                        } else if (type === 'group') {
                            if ($scope.selection[i].id === u.id) return true;
                        }
                    }
                    return false;
                };

                $scope.resetSelection = function () {
                    $scope.selection = [];
                };

                $scope.close = function () {
                    $element.modal('hide');
                    close({
                        selected: $scope.selection,
                        save: true
                    }, 500);
                };

                $scope.cancel = function () {
                    $element.modal('hide');
                    close({
                        save: false
                    }, 500);
                };

            }
        ])

        .controller('NewGroupModalController', [
            '$scope', '$rootScope', '$element', 'close',
            function ($scope, $rootScope, $element, close) {

                $scope.close = function () {
                    $element.modal('hide');
                    close({
                        newGroupCode: $scope.newGroupCode,
                        newGroupName: $scope.newGroupName,
                        newGroupDesc: $scope.newGroupDesc,
                        save: true
                    }, 500);
                };

                $scope.cancel = function () {
                    $element.modal('hide');
                    close({
                        save: false
                    }, 500);
                };

            }
        ])

        .controller('NewUserModalController', [
            '$scope', '$rootScope', 'grouplist', '$element', 'close',
            function ($scope, $rootScope, grouplist, $element, close) {

                $scope.grouplist = grouplist;


                $scope.close = function () {
                    $element.modal('hide');
                    close({
                        newUserFirstName: $scope.newUserFirstName,
                        newUserLastName: $scope.newUserLastName,
                        newUserUsername: $scope.newUserUsername,
                        newUserPassword: $scope.newUserPassword,
                        groups: $scope.newUserGroup,
                        save: true
                    }, 500);
                };

                $scope.cancel = function () {
                    $element.modal('hide');
                    close({
                        save: false
                    }, 500);
                };

            }
        ])

        .controller('EditUserModalController', [
            '$scope', '$rootScope', 'user', '$element', 'close',
            function ($scope, $rootScope, user, $element, close) {

                $scope.user = user;

                $scope.close = () => {
                    $element.modal('hide');
                    close({
                        userId: user.userId,
                        firstname: $scope.userUsername || user.firstname,
                        lastname: $scope.userLastName || user.lastname,
                        username: $scope.userUsername || user.username,
                        editUserPassword: $scope.userPassword,
                        save: true
                    }, 500);
                };

                $scope.cancel = () => {
                    $element.modal('hide');
                    close({
                        save: false
                    }, 500);
                };

            }
        ])

        .controller('CNUserModalController', [
            '$scope', '$rootScope', '$element', 'close',
            function ($scope, $rootScope, $element, close) {


                $scope.close = function () {
                    $element.modal('hide');
                    close({
                        newUserFirstName: $scope.newUserFirstName,
                        newUserLastName: $scope.newUserLastName,
                        newUserUsername: $scope.newUserUsername,
                        newUserPassword: $scope.newUserPassword,
                        save: true
                    }, 500);
                };

                $scope.cancel = function () {
                    $element.modal('hide');
                    close({
                        save: false
                    }, 500);
                };

            }
        ])

        .controller('OrganizationModalController', [
            '$scope', 'name', 'accountNumberLimit', '$element', 'close',
            function ($scope, name, accountNumberLimit, $element, close) {

                $scope.name = name;
                $scope.accountNumberLimit = accountNumberLimit;

                $scope.close = function () {
                    $element.modal('hide');
                    close({
                        name: $scope.name,
                        accountNumberLimit: $scope.accountNumberLimit,
                        save: true
                    }, 500);
                };

                $scope.cancel = function () {
                    $element.modal('hide');
                    close({
                        save: false
                    }, 500);
                };

            }
        ])

        .controller('AdminController', ['$scope', '$rootScope', 'AdminServices', function AdminController($scope, $rootScope, AdminServices) {

            $scope.loadSettings = function () {
                AdminServices.settingsLoad()
                    .then(function (res) {
                        if (res.err == 0) {
                            $rootScope.isLoading = false;
                            var settings = res.data;
                            angular.forEach(settings, function (s) {
                                if (s.key.indexOf('maxsize') > -1) {
                                    s.isNumber = true;
                                } else {
                                    s.isNumber = false;
                                }
                            });

                            $scope.settings = settings;
                        } else {
                            $rootScope.isLoading = false;
                        }
                    });
            };

            $scope.saveNewSetting = function (key, value) {

                if (key.indexOf('maxsize') > -1) {
                    value = parseInt(value);
                }

                AdminServices.settingSave(key, value)

                    .then(function (res) {
                        if (res.err == 0) {
                            $rootScope.isLoading = false;
                            $scope.loadSettings();
                        } else {
                            $rootScope.isLoading = false;
                        }
                    });
            };


            $scope.reloadCache = function () {
                AdminServices.cacheReload()
                    .then(function (res) {
                        if (res.err == 0) {
                            $rootScope.isLoading = false;
                        } else {
                            $rootScope.isLoading = false;
                        }
                    });
            }

        }])

        .controller('CookieCtrl', ['$scope', function CookieCtrl($scope) {

            $scope.alertCookie = function () {
                alert(docCookies.getItem('JSESSIONID'));
            }
        }]);

};