/**
 * Created with JetBrains WebStorm.
 * User: sgrdjan
 * Date: 19.10.13.
 * Time: 17:25
 * To change this template use File | Settings | File Templates.
 */


module.exports = function() {
    angular.module('explainApp')
        .service('AdminServices', ['$rootScope', 'Restangular', function ($rootScope, Restangular) {

            this.settingsLoad = function () {
                var req = {

                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('admin/settings/list');
                return baseUrl.post(req);
            };

            this.settingSave = function (key, value) {
                var req = {
                    key: key,
                    value: value
                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('admin/settings/update');
                return baseUrl.post(req);
            };

            this.cacheReload = function(){
                var req = {

                };
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('admin/settings/reload');
                return baseUrl.post(req);
            }

        }])


        .service('RedoRequestService', ['$rootScope', '$location', function ($rootScope, $location) {

            this.redo = function () {

                for (var i = 0; i < $rootScope.whats.length; i++) {

                    switch ($rootScope.whats[i]) {
                        case 'user/details':
                            //console.log('broadcasting '+ $rootScope.reqs[i]);
                            $rootScope.$broadcast('redoUserDetails', {'req': $rootScope.reqs[i]});
                            $rootScope.$emit('redoUserDetails', {'req': $rootScope.reqs[i]});
                            break;
                        case 'user/detail':
                            //get
                            break;
                        case 'user/update':
                            break;
                        case 'user/logout':
                            //get
                            break;
                        case 'user/id':
                            //get
                            break;
                        case 'user/password/change':
                            break;
                        default:

                    }
                }

                $rootScope.whats.splice(0, $rootScope.whats.length - 1);
                $rootScope.reqs.splice(0, $rootScope.reqs.length - 1);
            }


        }])


        .service('modalService', ['$modal', '$rootScope',
            function ($modal, $rootScope) {

                var modalDefaults = {
                    backdrop: true,
                    keyboard: true,
                    modalFade: true,
                    templateUrl: '/views/partials/modal.html'
                };

                var modalOptions = {
                    showCancel: true,
                    isError: false,
                    errorNo: '',
                    closeButtonText: 'Close',
                    actionButtonText: 'OK',
                    headerText: 'Proceed?',
                    bodyText: 'Perform this action?'
                };

                this.showModal = function (customModalDefaults, customModalOptions) {
                    if (!customModalDefaults) customModalDefaults = {};
                    // customModalDefaults.backdrop = 'true';
                    return this.show(customModalDefaults, customModalOptions);
                };

                this.show = function (customModalDefaults, customModalOptions) {
                    //Create temp objects to work with since we're in a singleton service
                    var tempModalDefaults = {};
                    var tempModalOptions = {};

                    //Map angular-ui modal custom defaults to modal defaults defined in service
                    angular.extend(tempModalDefaults, modalDefaults, customModalDefaults);

                    //Map modal.html $scope custom properties to defaults defined in service
                    angular.extend(tempModalOptions, modalOptions, customModalOptions);

                    if (!tempModalDefaults.controller) {
                        tempModalDefaults.controller = function ($scope, $modalInstance) {
                            $scope.modalOptions = tempModalOptions;
                            $scope.modalOptions.ok = function (result) {
                                if ($scope.modalOptions.isError == true) {
                                    $rootScope.infoMessage = '';
                                    $rootScope.errorMessage = '';
                                    $rootScope.errNo = '';
                                }
                                $modalInstance.close(result);
                            };
                            $scope.modalOptions.close = function (result) {
                                $modalInstance.dismiss('cancel');
                            };
                        }
                    }

                    return $modal.open(tempModalDefaults).result;
                };

            }])

        .factory('Page', function () {
            var title = 'DOS uređivač';
            //var meta1 = 'pocetni meta';
            return {
                title: function () {
                    return title;
                },
                setTitle: function (newTitle) {
                    title = newTitle;
                },
                meta1: function () {
                    return meta1;
                },
                setMeta1: function (newMeta1) {
                    meta1 = newMeta1;
                }
            };
        })
        .factory('textAngularSaver', function () {
            var content = '';
            var shouldSetContent = false;

            return {
                content: function () {
                    return content;
                },
                setContent: function (newContent) {
                    content = newContent;
                },
                shouldSetContent: function(){
                    return shouldSetContent;
                },
                setShouldSetContent: function(newContent){
                    shouldSetContent = newContent;
            }
            };

        });
};
