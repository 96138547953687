const APP_CONFIG = require ('../../../scripts/configs/AppConfig');

// config value path separator
const SEPARATOR = '.';

// ----- load config objects
// base config - must exist
const CONFIG = [APP_CONFIG];
// env specific config
if (window['ENV_APP_CONFIG']) {
	CONFIG.push(window['ENV_APP_CONFIG']);
}

/** Object that provides static access to main app config */
const AppConfigService = {
	getValue: function(selector, defaultValue) {
		if (Array.isArray(selector)) {
			selector = selector.join(SEPARATOR);
		}

		let value = _reduceStateValue(selector.split(SEPARATOR));

		return (value == null && defaultValue != undefined) ? defaultValue : value;
	}	
}


// ----- private functions

/** Searches across all config objects for first appearance if given path and returns it. */
function _reduceStateValue (selector) {
	// ignore empty paths
	if (!selector || selector.length === 0) {
		return undefined;
	}

	let value;
	// iterate registered configs in reverse d order
	for (let i = CONFIG.length - 1; i >= 0; i--) {
		value = selector.reduce((current, key) => {
					return (current !== void 0) ? current[key] : current;
				},
				CONFIG[i] // current
		);

		// break when value is found
		if (value !== undefined) break;
	}

	return value;
}


// modular export for use in modern code
module.exports = AppConfigService;
