/* service for dictionary terms */

module.exports = () => {
  angular.module('explainApp')

    .service('DictionaryService', ['$rootScope', 'Restangular', function ($rootScope, Restangular) {

        let dictionaryTerm = 'dictionary';
        if($rootScope.tenant === 'carnet'){
            dictionaryTerm = 'glossary';
        }

      /** ----- Get the list of dictionary terms -----  */
      this.getDictionaryTermListForDER = (derId, page, perPage, searchTerm) => {
        const req = {
          derId,
          page,
          perPage,
          searchTerm,
        };

        const baseUrl = Restangular.all(dictionaryTerm+'/list');

        return baseUrl.post(req);
      };

      /** ----- Get a single dictionary term by id -----  */
      this.getDictionaryTerm = (dictionaryTermId) => {
        const req = {
          dictionaryTermId,
        };

        Restangular.setBaseUrl($rootScope.server);
        const baseUrl = Restangular.all(dictionaryTerm);

        return baseUrl.post(req);
      };

      /** ----- Create a new dictionary term ----- */
      this.createDictionaryTerm = (derId, title, translatedTitle, description, audioDerRelationId, imageDerRelationId) => {
          let req = {
              derId,
              title,
              description,
          };

          if ($rootScope.tenant !== 'carnet'){
              req = {
                  derId,
                  title,
                  translatedTitle,
                  description,
                  audioDerRelationId,
                  imageDerRelationId,
              }
          };

        Restangular.setBaseUrl($rootScope.server);
        const baseUrl = Restangular.all(dictionaryTerm+'/create');

        return baseUrl.post(req);
      };

      /** ----- Update an existing dictionary term ----- */
      this.updateDictionaryTerm = (dictionaryTermId, title, translatedTitle, description, audioDerRelationId, imageDerRelationId) => {

          let req = {
              title,
              description,
          };

          if ($rootScope.tenant !== 'carnet'){
              req = {
                  dictionaryTermId,
                  title,
                  translatedTitle,
                  description,
                  audioDerRelationId,
                  imageDerRelationId,
              }
          };

        Restangular.setBaseUrl($rootScope.server);
        const baseUrl = Restangular.all(dictionaryTerm+'/update');

        return baseUrl.post(req);
      };

      /** ----- Delete an existing dictionary term ----- */
      this.deleteDictionaryTerm = (dictionaryTermId) => {
        const req = {
          dictionaryTermId,
        };

        Restangular.setBaseUrl($rootScope.server);
        const baseUrl = Restangular.all(dictionaryTerm+'/delete');

        return baseUrl.post(req);
      };

    }])

}