module.exports = function () {
    angular.module('explainApp')
        .service('AuthoringService', ['$rootScope', 'Restangular', 'UserLoginService', 'User', 'AppSettings',
            function ($rootScope, Restangular, UserLoginService, User, AppSettings) {

                this.getDERMobileList = function () {
                    var req = {
                        "derTypeIds": "module",
                        "getParentHierarchy": 1,
                        "onlyWithFile": 1,
                        "page": 0,
                        "perPage": 1000,
                        "sortBy": "newest"
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/mobile/list');
                    return baseUrl.post(req);
                };

                this.getQuizDER = function (derId, derVersion, organizationId) {

                    if ($rootScope.tenant === 'carnet') {
                        var req = {
                            "derId": parseInt(derId),
                            "derVersion": parseInt(derVersion),
                            "organizationId": organizationId
                        };
                    } else if ($rootScope.tenant !== 'carnet') {
                        var req = {
                            "derId": parseInt(derId),
                            "derVersion": parseInt(derVersion)
                        };
                    }

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/get');
                    return baseUrl.post(req);
                };

                this.getDER = function (derId, derVersion, longInfo, getParentHierarchy, getChildrenHierarchy, hierarchyFetchDepth, withPermissionsForGroupId, getPermissions) {

                    var req = {
                        "derId": parseInt(derId),
                        "derVersion": parseInt(derVersion),
                        "longInfo": longInfo,
                        "getParentHierarchy": getParentHierarchy,
                        "getChildrenHierarchy": getChildrenHierarchy,
                        "hierarchyFetchDepth": hierarchyFetchDepth,
                        "withPermissionsForGroupId": withPermissionsForGroupId
                    };

                    if ($rootScope.tenant === 'carnet') {
                        req.getPermissions = getPermissions;
                    }

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/get');
                    return baseUrl.post(req);
                };

                this.listDER = function (searchTerm, includePublic, derTypeId, page, perPage, excludeOwned, sortBy, getParentHierarchy, getChildrenHierarchy, dosFilter, hierarchyFetchDepth, withPermissionsForGroupId, getPermissions, organizationId) {

                    if ($rootScope.tenant === 'carnet') {
                        var req = {
                            "searchTerm": searchTerm,
                            "sortBy": sortBy,
                            "includePublic": includePublic,
                            "excludeOwned": excludeOwned,
                            "derTypeIds": derTypeId,
                            "onlyWithFile": false,
                            "page": page,
                            "perPage": perPage,
                            "getParentHierarchy": getParentHierarchy,
                            "getChildrenHierarchy": getChildrenHierarchy,
                            "dosFilter": dosFilter,
                            "hierarchyFetchDepth": hierarchyFetchDepth,
                            "withPermissionsForGroupId": withPermissionsForGroupId,
                            "getPermissions": getPermissions,
                            "organizationId": organizationId
                        };
                    } else if ($rootScope.tenant !== 'carnet') {
                        var req = {
                            "searchTerm": searchTerm,
                            "sortBy": sortBy,
                            "includePublic": includePublic,
                            "excludeOwned": excludeOwned,
                            "derTypeIds": derTypeId,
                            "onlyWithFile": false,
                            "page": page,
                            "perPage": perPage,
                            "getParentHierarchy": getParentHierarchy,
                            "getChildrenHierarchy": getChildrenHierarchy,
                            "dosFilter": dosFilter,
                            "hierarchyFetchDepth": hierarchyFetchDepth,
                            "withPermissionsForGroupId": withPermissionsForGroupId

                        };
                    }



                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/list');
                    return baseUrl.post(req);
                };

                this.deleteDER = function (derId, lastVersion) {

                    var req = {
                        "derId": derId,
                        //"lastVersion": lastVersion,
                        "forceOverwrite": true
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/delete');
                    return baseUrl.post(req);
                };

                this.listRelatedUnits = function (derId) {
                    var req = {
                        "derId": derId
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/relation/uses');
                    return baseUrl.post(req);
                };

                /*this.copyDER = function (derId, lastVersion) {
    
                    var req = {
                        "derId": parseInt(derId),
                        "derVersion": parseInt(lastVersion),
                        "derSegmentIds": null
                    };
    
                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/create/copy');
                    return baseUrl.post(req);
                };*/

                this.copyDER = function (derId, targetDerId) {

                    var req = {
                        "derId": parseInt(derId),
                        "targetDosId": targetDerId ? parseInt(targetDerId) : null
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/copy');
                    return baseUrl.post(req);
                };

                this.createMetadata = function (derTypeId, title, visibility, derStyleId, copyrightId, contId, isbn, description, ordering) {

                    if (!description) {
                        description = "Opis knjige nije dostupan.";
                    }

                    if (typeof description !== "String" && typeof description !== "string") {
                        description = JSON.stringify(description);
                    }

                    var req = {
                        "derInfo": {
                            "derTypeId": derTypeId,    //req "eBook"/"presentation"/"audio"/"video"/etc
                            "title": title,          //req

                            "derStyleId": derStyleId,
                            "copyrightId": copyrightId,
                            //"derStatusId":7,
                            "subtitle": null,
                            "description": description,
                            "ordering": ordering
                        },
                        "visibility": visibility
                        //"locked" : true

                    };

                    if (!contId) {
                        contId = 1;
                    }
                    var contributors = [];
                    if (typeof contId == 'number') {
                        contributors.push({
                            "contributorId": contId,
                            "role": "author"
                        })
                    } else {
                        UserLoginService.doKeepAlive(true)
                            .then(function (res) {
                                if (res.err === 0) {
                                    User.setUser(res.data.userId, res.data.username, res.data.nickname, res.data.firstname, res.data.lastname, res.data.role, res.data.relatedContributors, res.data.status, res.data.roles, res.data.organizationInfo);
                                    UserLoginService.getSettings()
                                        .then(function (res2) {
                                            if (res2.err === 0) {
                                                $rootScope.isLoading = false;
                                                angular.forEach(res2.data, function (s) {
                                                    if (s.key === 'content.maxsize.audio') {
                                                        AppSettings.setMaxAudioSize(s.value);
                                                    } else if (s.key === 'content.maxsize.video') {
                                                        AppSettings.setMaxVideoSize(s.value);
                                                    } else if (s.key === 'content.maxsize.img') {
                                                        AppSettings.setMaxImageSize(s.value);
                                                    }
                                                });

                                            } else {
                                                $rootScope.isLoading = false;
                                            }
                                        });
                                }
                            });
                        contributors.push({
                            "firstname": contId.firstname,
                            "lastname": contId.lastname,
                            "role": "author"
                        })
                    }

                    req.contributors = contributors;

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/create');
                    return baseUrl.post(req);
                };

                this.updateMetadata = function (derId, title, visibility, derStyleId, copyrightId, lastVersion, description, descriptionOfVersion, ordering) {

                    if (!description) {
                        description = "Opis knjige nije dostupan.";
                    }

                    var req = {
                        "derId": derId,
                        "title": title,
                        "visibility": visibility,
                        "derStyleId": derStyleId,
                        "copyrightId": copyrightId,
                        //"locked" : true,
                        "subtitle": null,
                        "description": description,
                        "lastVersion": lastVersion,
                        "forceOverwrite": true,
                        "descriptionOfVersion": descriptionOfVersion,
                        "ordering": ordering
                    };

                    /*if($rootScope.userOrganization && $rootScope.userOrganization.organizationId){
                        req.organizationId = $rootScope.userOrganization.organizationId;
                    }*/

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/info/update');
                    return baseUrl.post(req);
                };

                this.updateConfiguration = function (derId, configuration) {

                    var req = {
                        "derId": derId,
                        "configuration": configuration
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/configuration/update');
                    return baseUrl.post(req);
                };

                this.createSegment = function (derId, ordering, title, content, lastVersion, contentTags, segmentBorders) {

                    var req = {
                        "derId": derId,
                        "ordering": ordering,
                        "title": title ? title : "Tekst",
                        "content": content,
                        "lastVersion": lastVersion,
                        "forceOverwrite": true,
                        "segmentBorders": segmentBorders ? segmentBorders : "CLOSED",
                        "contentTags": contentTags ? [contentTags] : null
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/create');
                    return baseUrl.post(req);
                };

                this.updateSegment = function (derId, derSegmentId, ordering, title, content, lastVersion, contentTags, descriptionOfVersion, segmentBorders) {

                    if (!content) {
                        content = " ";
                    }

                    var req = {
                        "derId": derId,
                        "derSegmentId": derSegmentId,
                        "ordering": ordering,
                        "title": title,          //req
                        "content": content,
                        "lastVersion": lastVersion,
                        "forceOverwrite": true,
                        "contentTags": contentTags,
                        "descriptionOfVersion": descriptionOfVersion,
                        "segmentBorders": segmentBorders ? segmentBorders : "CLOSED"
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/update');
                    return baseUrl.post(req);
                };

                this.updateAllSegments = function (derArray) {

                    var req = derArray;

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/update/all');
                    return baseUrl.post(req);
                };

                this.deleteSegment = function (derId, derSegmentId, derVersion) {

                    var req = {
                        "derId": derId,
                        "derSegmentId": derSegmentId,
                        "lastVersion": derVersion,
                        "forceOverwrite": true
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/delete');
                    return baseUrl.post(req);
                };

                this.reorderSegments = function (derId, lastVersion, newOdreding) {

                    var req = {
                        "derId": derId,
                        "lastVersion": lastVersion,
                        "forceOverwrite": true,
                        "segments": newOdreding
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/reorder');
                    return baseUrl.post(req);
                };

                this.lockDER = function (derId, lastVersion, locked) {

                    var req = {
                        "derId": derId,
                        "lastVersion": lastVersion,
                        "locked": locked

                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/lock');
                    return baseUrl.post(req);
                };

                this.changeDERStatus = function (derId, lastVersion, derStatusId) {

                    var req = {
                        "derId": derId,
                        "lastVersion": lastVersion,
                        "derStatus": {
                            "derStatusId": derStatusId
                        }
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/status/update');
                    return baseUrl.post(req);
                };


                this.createContributor = function (userId, firstname, lastname) {

                    var req = {
                        "userId": userId, "firstname": firstname, "lastname": lastname
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/contributor/create');
                    return baseUrl.post(req);
                };

                this.updateContributor = function (contributorId, userId, firstname, lastname) {

                    var req = {
                        "contributorId": contributorId, "userId": userId, "firstname": firstname, "lastname": lastname
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/contributor/update');
                    return baseUrl.post(req);
                };

                this.addContributor = function (derId, contributorId, role) {

                    var req = {
                        "derId": derId,
                        "contributorId": contributorId,
                        "role": role //"author"/"illustrator"/"editor"/"translator"
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/contributor/add');
                    return baseUrl.post(req);
                };

                this.downloadFile = function (derId, derVersion) {

                    var req = {
                        "derId": derId,
                        "derVersion": derVersion,
                        "fileType": "epub"
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/file/download');
                    return baseUrl.post(req);
                };

                this.getPreview = function (derId, derVersion) {
                    var req = {
                        "derId": derId,
                        "derVersion": derVersion
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/preview');
                    return baseUrl.post(req);
                };

                this.createFile = function (derId, lastVersion, contentType, drmType, customFilename) {

                    if ($rootScope.tenant === 'algebra') {
                        var req = {
                            "derId": derId,
                            "derSourceVersion": lastVersion,
                            "forceOverwrite": true,
                            "contentTypeId": contentType,
                            "drmType": drmType,
                            "overwriteExistingFile": true,
                            "customFilename": customFilename
                        };

                        Restangular.setBaseUrl($rootScope.server);
                        var baseUrl = Restangular.all('der/file/publish');
                        return baseUrl.post(req);
                    } else {
                        var req = {
                            "derId": derId,
                            "derSourceVersion": lastVersion,
                            "forceOverwrite": true,
                            "contentTypeId": contentType,
                            "drmType": drmType,
                            "overwriteExistingFile": true,
                            "customFilename": customFilename,
                            "creatorId":3,
                            "termExport": "full"
                        };

                        Restangular.setBaseUrl($rootScope.packagingServer);
                        var baseUrl = Restangular.all('creator/publish');
                        return baseUrl.post(req);
                    }
                };


                this.presentify = function (derId, lastVersion) {

                    var req = {
                        "derId": derId,
                        "derVersion": lastVersion
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/create/presentify ');
                    return baseUrl.post(req);
                };


                this.addRelation = function (derId, lastVersion, targets) {

                    var req = {
                        "derId": derId,
                        "lastVersion": lastVersion,
                        "forceOverwrite": true,
                        "relations": targets
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/relation/add');
                    return baseUrl.post(req);
                };

                this.delRelation = function (derId, derRelationIds, lastVersion) {

                    var req = {
                        "derId": derId,
                        "lastVersion": lastVersion,
                        "forceOverwrite": true,
                        "derRelationIds": derRelationIds
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/relation/remove');
                    return baseUrl.post(req);
                };

                this.getRelations = function (derId, relationTypeId) {

                    var req = {
                        "derId": derId,
                        "relationTypeId": relationTypeId
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/relation/getByType');
                    return baseUrl.post(req);
                };

                this.getOldVersionsList = function (derId, page) {

                    var req = {
                        "derId": derId,
                        "page": page,
                        "perPage": 5,
                        "onlyWithDescription": true
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/versions');
                    return baseUrl.post(req);
                };

                this.getDerForDiff = function (derId, derVersion) {
                    var req = {
                        "derId": parseInt(derId),
                        "derVersion": parseInt(derVersion),
                        "longInfo": true,
                        "getParentHierarchy": false,
                        "getChildrenHierarchy": false,
                        "hierarchyFetchDepth": 1
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/get/diff');
                    return baseUrl.post(req);
                };

                this.addCommentToSegment = function (derSegmentId, comment) {
                    var req = {
                        "derSegmentId": derSegmentId,
                        "content": comment,
                        "createdOn": Date.now(),
                        "startIndex": null,
                        "endIndex": null
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/addComment');
                    return baseUrl.post(req);
                };

                this.getCommentsForSegment = function (derSegmentId) {
                    var req = {
                        "derSegmentId": derSegmentId
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/getComments');
                    return baseUrl.post(req);
                };

                this.deleteCommentFromSegment = function (derCommentId) {
                    var req = {
                        "derCommentId": derCommentId
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('der/segment/deleteComment');
                    return baseUrl.post(req);
                };

                this.getOutcome = function (outcomeId) {
                    var req = {
                        "outcomeId": outcomeId
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('outcome/get');
                    return baseUrl.post(req);
                };

                this.listOutcomes = function (derId, fetchSiblingOutcomes) {
                    var req = {
                        "derId": derId,
                        "fetchSiblingOutcomes": fetchSiblingOutcomes

                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('outcome/list');
                    return baseUrl.post(req);
                };

                this.createOutcome = function (derId, name, active, ordering, outcomeDerRelations) {
                    var req = {
                        "derId": derId,
                        "name": name,
                        "active": active,
                        "ordering": ordering,
                        "outcomeDerRelations": outcomeDerRelations
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('outcome/create');
                    return baseUrl.post(req);
                };

                this.deleteOutcome = function (outcomeId) {
                    var req = {
                        "outcomeId": outcomeId
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('outcome/delete');
                    return baseUrl.post(req);
                };

                this.updateOutcome = function (outcomeId, name, outcomeDerRelations) {
                    var req = {
                        "outcomeId": outcomeId,
                        "name": name,
                        "outcomeDerRelations": outcomeDerRelations
                    };

                    Restangular.setBaseUrl($rootScope.server);
                    var baseUrl = Restangular.all('outcome/update');
                    return baseUrl.post(req);
                };

            }])

        .service('ResourceService', ['$rootScope', 'Restangular', function ($rootScope, Restangular) {

            this.resAssign = function (derId, resId, resVersion) {

                var req = {
                    "derId": derId, "resourceId": resId, "resourceVersion": resVersion
                    //, "lastVersion" : 0/nullde
                    , "forceOverwrite": true
                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/resource/assign');
                return baseUrl.post(req);
            };

            this.resGet = function (resId, resVersion) {

                var req = {
                    "resourceId": resId,
                    "resourceVersion": resVersion
                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/resource');
                return baseUrl.post(req);
            };

            this.resCreate = function (resourceType, visibility, copyrightId, content) {

                var req = {
                    "resourceType": resourceType //"quote"/"quiz"
                    , "visibility": visibility //"private"/"public"
                    , "copyrightId": copyrightId //0/null
                    , "content": content //"<some string>"
                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/resource/create');
                return baseUrl.post(req);
            };

            this.resUpdate = function (resId, resVersion, resourceType, visibility, copyrightId, content) {

                var req = {
                    "resourceId": resId,
                    "resourceVersion": resVersion,
                    "resourceType": resourceType //"quote"/"quiz"
                    , "visibility": visibility //"private"/"public"
                    , "copyrightId": copyrightId //0/null
                    , "content": content //"<some string>"
                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/resource/update');
                return baseUrl.post(req);
            };

            //TODO: upload maybe is a part of different service
            this.uploadFormDataToServer = (formData) => {

                const xhr = new XMLHttpRequest();
                xhr.open("POST", $rootScope.server + "/der/create/upload");
                xhr.withCredentials = true;

                return new Promise((resolve, reject) => {

                    xhr.onreadystatechange = () => {

                        if (xhr.readyState !== 4) {
                            return;
                        }
                        else {
                            var result = JSON.parse(xhr.responseText);
                            if (xhr.status >= 200 && xhr.status < 300) {
                                resolve(result);
                            } else {
                                reject(result);
                            }
                        }

                    };

                    xhr.send(formData);

                });
            };


        }])

        .factory('Search', ['$rootScope', function ($rootScope) {
            var editorSearchFilter;

            return {
                setEditorSearchFilter: function (newData) {
                    editorSearchFilter = newData;
                },
                getEditorSearchFilter: function () {
                    return editorSearchFilter;
                }
            }
        }])

        .factory('Chapters', ['$rootScope', function ($rootScope) {
            var chapters;
            var newDerVersion;

            return {
                setChapters: function (newData) {
                    chapters = newData;
                },
                getChapters: function () {
                    return chapters;
                },
                setNewDerVersion: function (newData) {
                    newDerVersion = newData;
                },
                getNewDerVersion: function () {
                    return newDerVersion;
                }
            }
        }])
        .factory('FormulaData', ['$timeout', '$rootScope', 'textAngularManager', function ($timeout, $rootScope, textAngularManager) {
            var editor;
            var mathMLOld;
            var restoreSelection;
            var element;

            return {
                setEditor: function (newData) {
                    editor = newData;
                },
                getEditor: function () {
                    return editor;
                },
                setRestoreSelection: function (newData) {
                    restoreSelection = newData;
                },
                getRestoreSelection: function () {
                    return restoreSelection;
                },
                setMathML: function (mathML) {
                    console.log(element);
                    console.log(editor);
                    if (element != null) {
                        element.outerHTML = mathML;

                        // allow angular to propagate changes by deferring editor refresh
                        $timeout(function () {
                            editor.displayElements.text.trigger('focus');
                            editor.wrapSelection('insertHTML', '<span></span>', true);
                            textAngularManager.refreshEditor(editor._name)
                        }, 1000);
                    } else {
                        $timeout(function () {
                            editor.displayElements.text.trigger('focus');
                            editor.wrapSelection('insertHTML', '<span>' + mathML + '&nbsp;</span>', true);
                        }, 1000);
                    }

                },
                setQuizMathML: function (mathML) {
                    if (typeof element != 'undefined' && element !== null) {
                        //console.log(typeof element);
                        //console.log(element);
                        console.log(element.outerHTML);
                        element.outerHTML = mathML;
                    } else {
                        $timeout(function () {
                            console.log("editor  " + editor);
                            console.log("mathMLOld " + mathMLOld);
                            console.log("restoreSelection " + restoreSelection);
                            console.log("element " + element);
                            editor.displayElements.text.trigger('focus');

                            //console.log(editor.displayElements.text);
                            editor.wrapSelection('insertHTML', '<span></span>', true);
                            editor.wrapSelection('insertHTML', mathML + '<span>&nbsp;</span>', true);
                        }, 1000);
                    }

                },
                getAndResetOldMathML: function () {
                    var temp = mathMLOld;
                    mathMLOld = '';
                    return temp;
                },
                setOldMathML: function (newData) {
                    mathMLOld = newData;
                },
                resetElement: function () {
                    element = null;
                },
                setElement: function (newElement) {
                    element = newElement;
                    mathMLOld = element.outerHTML;
                }
            }
        }])
        ;
};
