/**
 * Created with JetBrains WebStorm.
 * User: sgrdjan
 * Date: 13.09.13.
 * Time: 11:36
 * To change this template use File | Settings | File Templates.
 */
/* obavlja ulogiravanje korisnika i provjeru dal je korisnik ulogiran */


module.exports = function () {
    angular.module('explainApp')
        .service('UserLoginService', ['Restangular', '$rootScope', function (Restangular, $rootScope) {

            this.doLogin = function (username, pwhash) {

                var user = {
                    'username': username.toLowerCase().trim(),
                    'password': pwhash
                };

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('session/login');
                return baseUrl.post(user);

            };

            this.doKeepAlive = function (returnUserInfo) {
                Restangular.setBaseUrl($rootScope.server);
                var req = {
                    returnUserInfo: returnUserInfo
                };
                var baseUrl = Restangular.all('session/keepalive');
                return baseUrl.post(req);
            };

            this.userActivate = function () {
                Restangular.setBaseUrl($rootScope.server);
                var req = {

                };
                var baseUrl = Restangular.all('user/activate');
                return baseUrl.post(req);
            };

            this.getSettings = function () {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.one('session/settings');
                return baseUrl.get();
            }
        }])

        .service('UserLogoutService', ['$cookieStore', '$rootScope', 'Restangular', function ($cookieStore, $rootScope, Restangular) {

            this.doLogout = function () {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('session/logout');
                return baseUrl.post({});
            };

            this.cleanUserData = function () {
                $rootScope.isLoggedIn = false;
                $rootScope.userId = null;
                $rootScope.userPublishers = null;
                $rootScope.nick = '';
                Restangular.setDefaultHeaders({});

                $cookieStore.put('TbASappToken', null);
                $cookieStore.put('siteCurr', 1);

            }

        }])

        .service('UserPassChange', ['$cookieStore', '$rootScope', 'Restangular', function ($cookieStore, $rootScope, Restangular) {
            this.changePass = function (req) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('user/password/change');
                return baseUrl.post(req);
            }
        }])


        .service('UserSearchService', ['Restangular', '$rootScope', function (Restangular, $rootScope) {
            this.getUserDataSingle = function (searchString, uId, uMSISDN) {

                Restangular.setBaseUrl($rootScope.server);
                var req = {
                    "username": searchString == '' ? null : searchString,
                    "userId": uId == '' ? null : parseInt(uId),
                    "msisdn": uMSISDN == '' ? null : parseInt(uMSISDN)
                };
                var baseUrl = Restangular.all('userctrl/getdetails');
                return baseUrl.post(req);
            };

            this.getUsersData = function (searchStringPart) {


                Restangular.setBaseUrl($rootScope.server);
                var req = {
                    "partialUsername": searchStringPart
                };
                var baseUrl = Restangular.all('userctrl/getusers');
                return baseUrl.post(req);
            };

            this.getUserBorrows = function (id) {

                Restangular.setBaseUrl($rootScope.server);
                var req = {
                    "userId": id
                };
                var baseUrl = Restangular.all('borrowctrl/history');
                return baseUrl.post(req);
            };

            this.getUserSubscriptions = function (id) {

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('userctrl/subscriptions/userhistory');
                var req = {
                    "userId": id
                };
                return baseUrl.post(req);
            };

            this.getUserTransactions = function (id) {

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('userctrl/transactions/userhistory');
                var req = {
                    "userId": id
                };
                return baseUrl.post(req);
            };

            this.getUserDevices = function (id) {

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('devicectrl/getbyuser');
                var req = {
                    "userId": id
                };
                return baseUrl.post(req);
            };

            this.setDeviceStatus = function (userId, deviceId, deviceStatus) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('devicectrl/setstatus');
                var req = {
                    "userId": userId,
                    "deviceId": deviceId,
                    "deviceStatus": deviceStatus
                };
                return baseUrl.post(req);
            };

        }])

        .factory('AppSettings', ['$rootScope', function ($rootScope) {
            var maxImageSize;
            var maxVideoSize;
            var maxAudioSize;


            return {
                setMaxImageSize: function (newData) {
                    maxImageSize = newData;
                },
                getMaxImageSize: function () {
                    return maxImageSize;
                },
                setMaxVideoSize: function (newData) {
                    maxVideoSize = newData;
                },
                getMaxVideoSize: function () {
                    return maxVideoSize;
                },
                setMaxAudioSize: function (newData) {
                    maxAudioSize = newData;
                },
                getMaxAudioSize: function () {
                    return maxAudioSize;
                },
                setData: function (imagesize, videosize, audiosize) {
                    this.setMaxImageSize(imagesize);
                    this.setMaxVideoSize(videosize);
                    this.setMaxAudioSize(audiosize);
                },
                deleteData: function () {
                    this.setMaxImageSize(null);
                    this.setMaxVideoSize(null);
                    this.setMaxAudioSize(null);

                }
            }
        }])

        .factory('User', ['$rootScope', function ($rootScope) {
            var userId;
            var username;
            var firstname;
            var lastname;
            var nickname;
            var role;
            var isLoggedIn;
            var relatedContributors;
            var status;
            var roles;
            var organization;

            return {
                setUserId: function (newData) {
                    $rootScope.userId = newData;
                    userId = newData;
                },
                getUserId: function () {
                    return userId;
                },
                setUsername: function (newData) {
                    $rootScope.username = newData;
                    username = newData;
                },
                getUsername: function () {
                    return username;
                },
                setFirstname: function (newData) {
                    $rootScope.firstname = newData;
                    firstname = newData;
                },
                getFirstname: function () {
                    return firstname;
                },
                setLastname: function (newData) {
                    $rootScope.lastname = newData;
                    lastname = newData;
                },
                getLastname: function () {
                    return lastname;
                },
                setNickname: function (newData) {
                    nickname = newData;
                    if (nickname == '' && nickname == null) {
                        nickname = username;
                    }
                    $rootScope.nickname = nickname;
                },
                getNickname: function () {
                    return nickname;
                },

                setRole: function (newData) {
                    role = newData;
                    $rootScope.role = role;

                    switch (newData) {
                        case 'admin':
                            $rootScope.roleDisplay = "Administrator";
                            break;
                        case 'instructor':
                            $rootScope.roleDisplay = "Predavač";
                            break;
                        case 'creator':
                            $rootScope.roleDisplay = "Kreator";
                            break;
                        case 'regular':
                            $rootScope.roleDisplay = "Učenik";
                            break;
                    }

                },
                getRole: function () {
                    return role;
                },
                setIsLoggedIn: function (newData) {
                    isLoggedIn = newData;
                    $rootScope.isLoggedIn = newData;

                },
                getIsLoggedIn: function () {
                    return isLoggedIn;
                },
                setRelatedContributors: function (newData) {
                    $rootScope.relatedContributors = newData;
                    relatedContributors = newData;
                },
                getRelatedContributors: function () {
                    return relatedContributors;
                },
                setStatus: function (newData) {
                    $rootScope.userStatus = newData;
                    status = newData;
                },
                getStatus: function () {
                    return status;
                },
                setRoles: function (newData) {
                    $rootScope.userRoles = newData;
                    roles = newData;
                },
                getRoles: function () {
                    return roles;
                },
                setUserOrganization: function (newData) {
                    $rootScope.userOrganization = newData;
                    var organization = newData;
                },
                getUserOrganization: function () {
                    return organization;
                },
                setUser: function (userId, username, nickname, firstname, lastname, role, relatedContributors, status, roles, organizationInfo) {
                    this.setUserId(userId);
                    this.setUsername(username);
                    this.setNickname(nickname);
                    this.setFirstname(firstname);
                    this.setLastname(lastname);
                    this.setRole(role);
                    this.setRelatedContributors(relatedContributors);
                    this.setStatus(status);
                    if (status === 'a') {
                        this.setIsLoggedIn(true);
                    }
                    this.setRoles(roles);
                    this.setUserOrganization(organizationInfo);
                },
                deleteUserData: function () {
                    $rootScope.userId = null;
                    $rootScope.isLoggedIn = false;
                    $rootScope.nickname = '';
                    $rootScope.username = null;
                    $rootScope.firstname = null;
                    $rootScope.lastname = null;
                    $rootScope.role = null;
                    $rootScope.roleDisplay = null;
                    $rootScope.relatedContributors = null;
                    $rootScope.userStatus = null;
                    $rootScope.userRoles = null;
                    userId = null;
                    username = null;
                    firstname = null;
                    lastname = null;
                    role = null;
                    relatedContributors = null;
                    isLoggedIn = false;
                    status = null;
                    roles = null;
                    $rootScope.userOrganization = null;
                }
            }
        }])

        .service('PermissionService', ['$rootScope', 'Restangular', function ($rootScope, Restangular) {

            this.groupDetails = function (gId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/details');
                var req = {
                    "ugroupId": gId
                };
                return baseUrl.post(req);
            };

            this.groupListTable = function (page, onlyCreatedByViewer, searchTerm) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/list');
                var req = {
                    onlyCreatedByViewer: onlyCreatedByViewer,
                    searchTerm: searchTerm,
                    page: page,
                    perPage: 8
                };
                return baseUrl.post(req);
            };


            this.createGroup = function (code, name, desc, ugroupParentIds, permissionIds) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/create');
                var req = {
                    "code": code,
                    "name": name,
                    "description": desc,
                    "ugroupParentIds": ugroupParentIds,
                    "permissionIds": permissionIds
                };
                return baseUrl.post(req);
            };

            this.deleteGroup = function (gId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/delete');
                var req = {
                    "ugroupId": gId
                };
                return baseUrl.post(req);
            };

            this.gNameChange = function (gId, name, code, description) {
                var baseUrl = Restangular.all('acl/ugroup/update');
                var req = {
                    ugroupId: gId,
                    code: code,
                    name: name,
                    description: description
                };
                return baseUrl.post(req);
            };

            this.addUserToGroup = function (gId, uIds) {

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/addusers');
                var req = {
                    "ugroupId": parseInt(gId), //1
                    "userIds": uIds // [2,3]
                };
                return baseUrl.post(req);
            };

            this.removeUserFromGroup = function (gId, uIds) {

                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/removeusers');
                var req = {
                    "ugroupId": gId, //1
                    "userIds": uIds // [2,3]
                };
                return baseUrl.post(req);
            };


            this.createUser = function (firstName, lastName, username, password, groups, organizationId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('user/create');

                if ($rootScope.tenant !== 'carnet') {
                    var req = {
                        "firstName": firstName,
                        "lastName": lastName,
                        "username": username,
                        "password": SHA1(password),
                        "createdWith": 'webapp', //TODO: put URL
                        "groups": [
                            {
                                "id": 1581,
                                "code": "author"
                            }
                        ]
                    };
                } else if ($rootScope.tenant === 'carnet') {
                    var req = {
                        "firstName": firstName,
                        "lastName": lastName,
                        "username": username,
                        "password": SHA1(password),
                        "createdWith": 'webapp', //TODO: put URL
                        "organizationId": parseInt(organizationId),
                        "groups": [
                            {
                                "id": groups.id,
                                "code": groups.code
                            }
                        ]
                    };
                }


                return baseUrl.post(req);
            };

            this.userSearch = function (searchTerm, page, perpage) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('user/search');
                var req = {
                    "searchTerm": searchTerm,
                    "searchByName": true,
                    "searchByNick": true,
                    "searchByEmail": true,
                    "searchByInstitution": true,
                    "page": page,
                    "perPage": perpage ? perpage : 25
                };
                return baseUrl.post(req);

            };

            this.userChangePassword = function (userId, password) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('user/changepassword');
                var req = {
                    "userId": userId,
                    "password": SHA1(password),
                };
                return baseUrl.post(req);

            };

            this.userDeactivate = function (userId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('user/deactivate');
                var req = {
                    "userId": parseInt(userId)
                };
                return baseUrl.post(req);
            };

            this.userActivate = function (userId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('user/activate');
                var req = {
                    "userId": parseInt(userId)
                };
                return baseUrl.post(req);
            };

            this.listUsers = function (gid, page) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/listusers');
                var req = {
                    "ugroupId": gid,
                    "searchTerm": null,
                    "page": page,
                    "perPage": 50,
                    "sortBy": null
                };
                return baseUrl.post(req);
            };

            this.listUsersForDer = function (derId, pageOwners, pageViewers, pageModifiers) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/acl/listusers');
                var req = {
                    "derId": derId,
                    "ownersPage": {
                        "page": pageOwners,
                        "perPage": 8
                    },
                    "viewersPage": {
                        "page": pageViewers,
                        "perPage": 8
                    },
                    "modifiersPage": {
                        "page": pageModifiers,
                        "perPage": 8
                    }
                };
                return baseUrl.post(req);
            };

            this.derRemove = function (derIds, permission, userIds, ugroupIds) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/acl/removeusers');
                var req = {
                    "derIds": derIds,
                    "permission": permission//"VIEW"/"MODIFY"
                    ,
                    "userIds": userIds,
                    "ugroupIds": ugroupIds
                };
                return baseUrl.post(req);
            };

            this.derAdd = function (derIds, permission, userIds, ugroupIds) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('der/acl/addusers');
                var req = {
                    "derIds": derIds,
                    "permission": permission//"VIEW"/"MODIFY"
                    ,
                    "userIds": userIds,
                    "ugroupIds": ugroupIds
                };
                return baseUrl.post(req);
            };

            this.organizationsList = function () {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('organization');
                var req = {

                };
                return baseUrl.post(req);
            };

            this.organizationCreate = function (name, accountNumberLimit) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('organization/create');
                var req = {
                    "name": name,
                    "accountNumberLimit": accountNumberLimit
                };
                return baseUrl.post(req);
            };

            this.organizationEdit = function (organizationId, name, accountNumberLimit) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('organization/update');
                var req = {
                    "organizationId": organizationId,
                    "name": name,
                    "accountNumberLimit": accountNumberLimit
                };
                return baseUrl.post(req);
            };

            this.organizationGroupsList = function (organizationId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('acl/ugroup/list');
                var req = {
                    "onlyCreatedByViewer": false,
                    "page": 0,
                    "perPage": 50,
                    "organizationId": organizationId
                };

                if ($rootScope.tenant === 'carnet') {
                    req.includeOrganizationalUgroups = true;
                }

                return baseUrl.post(req);
            };

            this.organizationGet = function (organizationId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('organization/details');
                var req = {
                    "organizationId": organizationId
                };
                return baseUrl.post(req);
            };

            this.organizationDeactivate = function (organizationId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('organization/deactivate');
                var req = {
                    "organizationId": organizationId
                };
                return baseUrl.post(req);
            };

            this.organizationActivate = function (organizationId) {
                Restangular.setBaseUrl($rootScope.server);
                var baseUrl = Restangular.all('organization/activate');
                var req = {
                    "organizationId": organizationId
                };
                return baseUrl.post(req);
            };

        }]);

    /*
    {
    "derId": [
        {
            "propagateToChildren": true,
            "derId": 25352
        }
    ],
    */

};
