
// ----- App base config
const APP_CONFIG = {
  app: {
    environment: 'dev', // possible values: dev, test, production // TODO: should we rename "production" to "prod", to be short like the rest of the values?
    // tenant: <tenant> // tenant should be defined in environment specific configs
  },

  // ---------- REST API config
  api: {
    // base URL
    baseUrl: '/explain/services/v2',
    // packaging base URL
    packagingBaseUrl: '/api',

    paging: {
      smallPageSize: 5,
      defaultPageSize: 20,
      maxPageSize: 1000,
    },

  },

  matheditor: {
    // apiKey: <API KEY>,
  },

  logging: {
    logLevel: 'debug', // see loglevel LogLevelDesc for details

    sentry: {
      enabled: false,
      config: {
        dsn: '<DSN>',
      },
    },
  },
};

module.exports = APP_CONFIG;
