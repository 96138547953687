const Sentry = require('@sentry/browser');


/**
 * Loglevel sentry plugin.
*/
const methodFactory = (originalFactory) => (methodName /*string*/, level /*LogLevelNumbers*/, loggerName /*string*/) => {
  const rawMethod = originalFactory(methodName, level, loggerName);

  return (...args) => {
    // ----- error log
    if (methodName === 'error') {
      Sentry.withScope(scope => {
        const eventData = prepareEventData(...args);

        if (eventData.error != null) {
          scope.setExtra('message', eventData.message);
          Sentry.captureException(eventData.error);
        }
        else {
          Sentry.captureMessage(eventData.message);
        }
      });
    }
    // ----- warning log
    else if (methodName === 'warn') {
      const eventData = prepareEventData(...args);

      // we do not expect error objects here(?!)
      Sentry.captureMessage(eventData.message, Sentry.Severity.Warning);
    }

    // always call raw method to preserve logging function chain
    rawMethod.apply(null, args);
  };
};


/** Search method args, separate error from messages and create event object. */
function prepareEventData(...args) {
  let error;
  const messages = [];

  // search args and extract error from messages
  args.forEach(item => {
    // find an instance of Error object
    if (item instanceof Error) {
      error = item;
    }
    // everything else is serialized as string message
    else {
      messages.push(JSON.stringify(item));
    }
  });

  const message = messages.join('; ');

  return {
    error,
    message,
  };
}

/** Plugin application function. */
function applyPlugin(rootLogger) {
  const originalFactory = rootLogger.methodFactory;
  rootLogger.methodFactory = methodFactory(originalFactory);
}

module.exports = {
  applyPlugin
};
