const rootLogger = require('loglevel');


// ----- Loggers

/** Returns root logger. */
function getRootLogger() {
  return rootLogger;
}

/** Create new or return existing named logger */
function getLogger(name) {
  return rootLogger.getLogger(name);
}


// ----- Log level

/** Set default logging level. Lower levels are not logged. */
function setDefaultLevel(level) {
  rootLogger.setDefaultLevel(level);
}


/** Get current logging level */
function getLevel() {
  return rootLogger.getLevel();
}


// ----- Plugins

/**
 * Add logger plugin.
 * Plugins are applied to logger ie. to logger's default factory method.
 * Multiple logins can be applied as they will create function composition applying each plugin's tranfromation to desired log message.
 */
function addLoggerPlugin(applyFunction) {
  applyFunction(rootLogger);
}

module.exports = {
  getRootLogger,
  getLogger,
  setDefaultLevel,
  getLevel,
  addLoggerPlugin
};
