/**
 * Default log decorator. Adds additional data to log message ie. logger name, timestamp, ...
 */
const methodFactory = (originalFactory) => (methodName, level, loggerName) => {
  const rawMethod = originalFactory(methodName, level, loggerName);

  return (...args) => {

    rawMethod.apply(null, [
      // logger name
      ...(loggerName != null ? [`[${loggerName}]`] : []),

      // log level - enable if needed
      // ...(methodName != null ? [`[${methodName}]`] : []),

      // logging args
      ...args,
    ]);
  };
};


/**
 * Plugin application function.
 */
function applyPlugin(rootLogger) {
  const originalFactory = rootLogger.methodFactory;
  rootLogger.methodFactory = methodFactory(originalFactory);
}

module.exports = {
  applyPlugin
};
