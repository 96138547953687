/**
 * Master Controller
 */

module.exports = function() {
    angular.module('explainApp')
        .controller('MasterCtrl', ['$scope', '$cookieStore', '$location', '$rootScope', 'UserLoginService', 'User', 'AppSettings', MasterCtrl]);

    function MasterCtrl($scope, $cookieStore, $location, $rootScope, UserLoginService, User, AppSettings) {

        $scope.menuClosed = true;

        $scope.toggleMenu = function () {
            $scope.menuClosed = !$scope.menuClosed;
        };

        /**
         * Sidebar Toggle & Cookie Control
         */
        var mobileView = 992;

        $scope.getWidth = function () {
            return window.innerWidth;
        };

        $scope.$watch($scope.getWidth, function (newValue, oldValue) {
            if (newValue >= mobileView) {
                if (angular.isDefined($cookieStore.get('toggle'))) {
                    $scope.toggle = !$cookieStore.get('toggle') ? false : true;
                } else {
                    $scope.toggle = true;
                }
            } else {
                $scope.toggle = false;
            }

        });

        $scope.toggleSidebar = function () {
            $scope.toggle = !$scope.toggle;
            $cookieStore.put('toggle', $scope.toggle);
        };

        window.onresize = function () {
            $scope.$apply();
        };

        var cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
            document.cookie = "testcookie";
            cookieEnabled = (document.cookie.indexOf("testcookie") != -1) ? true : false;
        }
        if (!cookieEnabled) {
            $location.path('/nocookies');
        }

        $rootScope.status = 'not-ready';

        $rootScope.errorMessage = '';
        $rootScope.infoMessage = '';

        $rootScope.isLoading = false;
        $rootScope.isLoggedIn = false;
        $rootScope.logoutClicked = false;
        $rootScope.isInitCall = true;
        UserLoginService.doKeepAlive(true)
            .then(function (res) {
                $rootScope.isInitCall = false;
                if (res.err === 0) {
                    User.setUser(res.data.userId, res.data.username, res.data.nickname, res.data.firstname, res.data.lastname, res.data.role, res.data.relatedContributors, res.data.status, res.data.roles, res.data.organizationInfo);

                    UserLoginService.getSettings()
                        .then(function (res2) {
                            if (res2.err === 0) {
                                $rootScope.isLoading = false;
                                angular.forEach(res2.data, function (s) {
                                    if (s.key === 'content.maxsize.audio') {
                                        AppSettings.setMaxAudioSize(s.value);
                                    } else if (s.key === 'content.maxsize.video') {
                                        AppSettings.setMaxVideoSize(s.value);
                                    } else if (s.key === 'content.maxsize.img') {
                                        AppSettings.setMaxImageSize(s.value);
                                    }
                                });

                            } else {
                                $rootScope.isLoading = false;
                            }
                        });
                    if ($location.path() === '/') {
                        $location.path("/list");
                    }
                }
                else if (res.err === 22) {
                    $rootScope.isLoading = false;
                    $location.path("/");
                }
                else {
                    $rootScope.isLoading = false;
                }
            });

    }
};