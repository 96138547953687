const Sentry = require('@sentry/browser');

const logging = require('../../utils/logging/logger');
const AppConfigService = require('../../utils/config/AppConfigService');
const decoratorPlugin = require('../../utils/logging/loglevelDecoratorPlugin');
const sentryPlugin = require('../../utils/logging/loglevelSentryPlugin');


const APP_ENVIRONMENT = AppConfigService.getValue('app.environment');
const LOGGING_LEVEL = AppConfigService.getValue('logging.logLevel');
const SENTRY_CONFIG = AppConfigService.getValue('logging.sentry');


// ---------- Configure logging statically

// set log level
logging.setDefaultLevel(LOGGING_LEVEL);

// ---- configure plugins
logging.addLoggerPlugin(decoratorPlugin.applyPlugin);
if (SENTRY_CONFIG && SENTRY_CONFIG.enabled) {  
  // initialize sentry
  // NOTE: maybe it''s not a good idea to tie Sentry directly to logger, but we don't have app init method and this is always executed
  Sentry.init({
    ...SENTRY_CONFIG.config,
    environment: APP_ENVIRONMENT,
  });  

  logging.addLoggerPlugin(sentryPlugin.applyPlugin);
}

/* ----- logger test
const logger = logging.getLogger('TestLogger');

logger.info('Ovo je prva logging poruka', 'pa jos jedna', { a: 11 });
logger.warn('Evo nekakvo upozorenje 222!');
try {
  throw 'Ovo je jedna testna greska';
}
catch (err) {
  logger.error('Evo neka greska', err, 'i jos malo opisa greske', 123456789);
}
*/