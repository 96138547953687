module.exports = function () {
  angular.module('explainApp')

    .controller('QuizPlayModalController', [
      '$scope', '$rootScope', '$element', 'quizHTML', '$sce', 'close',
      function ($scope, $rootScope, $element, quizHTML, $sce, close) {
        var res;
        //20170131 HM: refactored quiz content and showing it outside iframe
        if (quizHTML.includes('<p id="results">Provjeri odgovore!</p>')) {
          //da radi sa starim verzijama
          res = quizHTML.replace('<p id="results">Provjeri odgovore!</p>', '<p id="resultsModal" onclick="checkQuizAnswers();">Provjeri odgovore!</p>');
        } else {
          res = quizHTML.replace('">Provjeri odgovore!</p>', '">Provjeri odgovore!</p><p id="resultsModal" onclick="checkQuizAnswers();">Provjeri odgovore!</p>');
        }
        res = res.replace(new RegExp("_questionId_", 'g'), "_playQuizQuestionId_");

        /*$scope.quizSrc = $sce.trustAsHtml(
          res + '<script type="text/javascript">'+ eval(getQuizModalElementId).toString() + ';' + eval(checkQuizAnswers).toString() + ';'+ eval(showHideQuizElement).toString()
          + ';'+ eval(resetQuizQuestion).toString() + '</script>');*/
        $scope.quizSrc = $sce.trustAsHtml(res);

        $scope.close = function () {
          $element.modal('hide');
          close({
            save: true
          }, 500);
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };


      }
    ])


    .controller('QuizModalController', [
      '$scope', '$rootScope', '$element', '$window', '$location', 'quiz', 'relations', 'quizId', 'version', 'segment', 'close', 'derId', 'derTypeId', 'sweet', 'AuthoringService', 'User', 'ModalService', '$sce', '$timeout',
      function ($scope, $rootScope, $element, $window, $location, quiz, relations, quizId, version, segment, close, derId, derTypeId, sweet, AuthoringService, User, ModalService, $sce, $timeout) {

        /**
         * segment could be an object with only title property, not always a full blown segment
         */

        $scope.quiz = quiz || [];
        $scope.hasOutcomes = (segment.title === "Procjena znanja");
        $scope.editorToolbarType = 'toolbarFull';

        const getNewQuestionObject = () => {
          const question = {
            questionName: 'Jedan točan odgovor',
            questionText: null,
            extensionText: null,
            questionTextAlt: null,
            type: 'pair',
            imageURL: null,
            imageDerId: null,
            imageDerVersion: null,
            imageTitle: null,
            imageRelationId: null,
            imageContentTypeId: null,
            audioURL: null,
            audioDerId: null,
            audioDerVersion: null,
            audioTitle: null,
            audioRelationId: null,
            audioContentTypeId: null,
            helpText: null,
            procedureText: null,
            outcome: null,
            // REPL start
            replType: "javascript",
            replCode: "",
            blocklyToolbox: "",
            blocklyWorkspace: "",
            // REPl end
            answers: [],
          };

          return question;
        };

        const getNewAnswerObject = () => {
          const answer = {
            answerText: "",
            imageURL: null,
            imageDerId: null,
            imageDerVersion: null,
            imageTitle: null,
            imageRelationId: null,
            imageContentTypeId: null,
            audioURL: null,
            audioDerId: null,
            audioDerVersion: null,
            audioTitle: null,
            audioRelationId: null,
            audioContentTypeId: null,
            isCorrect: false,
            feedbackText: "",
            pairPrompt: '',
            extensionText: '',
            minValue: '',
            maxValue: '',
            answerList: [{
              elementText: ""
            }],
            percentageX: '',
            percentageY: '',
            caseSensitive: false,
          };

          return answer;
        };

        const setImageToQuizElement = (element, imageURL, imageTitle, imageRelationId, imageDerId, imageDerVersion, imageContentTypeId) => {

          element.imageURL = imageURL;
          element.imageTitle = imageTitle;
          element.imageRelationId = imageRelationId;
          element.imageDerId = imageDerId;
          element.imageDerVersion = imageDerVersion;
          element.imageContentTypeId = imageContentTypeId;
        };

        const setAudioToQuizElement = (element, audioURL, audioTitle, audioRelationId, audioDerId, audioDerVersion, audioContentTypeId) => {

          element.audioURL = audioURL;
          element.audioTitle = audioTitle;
          element.audioRelationId = audioRelationId;
          element.audioDerId = audioDerId;
          element.audioDerVersion = audioDerVersion;
          element.audioContentTypeId = audioContentTypeId;
        };

        $timeout(function () {
          // height at which should "stickiness" be turned on
          // calculating this dynamically from "sticky" element itself causes flickering of that element, so we used static value
          var stickyDivOffsetThreshold = 440;

          // TODO: should we know about modal
          var $container = $(".modal");
          var $stickydiv = $('.wysiwyg-btns-container', $container);

          var height;
          var width;

          $container.scroll(function () {
            // calculate&cache some dimensions (they don't change so no need for recalculation)
            if (height === undefined) {
              height = $stickydiv.outerHeight();
            }
            if (width === undefined) {
              width = $(".quiz-container").outerWidth();
            }

            // these elements are contained in "fixed" position element (modal dialog), so another (inner) fixed position won't work
            // thus we have to update it's "top" property with every scroll to keep it's position
            var scrollTop = $container.scrollTop();
            $stickydiv.toggleClass('sticky-mainmenu-panel', scrollTop > stickyDivOffsetThreshold);
            // enable stickiness
            if (scrollTop > stickyDivOffsetThreshold) {
              $stickydiv.css('top', scrollTop);
              $stickydiv.css('width', width);
              $('.wysiwyg-placeholder').css('height', height);
            }
            // disable stickiness
            else {
              $stickydiv.css('width', '');
              $('.wysiwyg-placeholder').css('height', 0);
            }
          });
        });

        $scope.changeQuizType = () => {
          if (segment.title === "Procjena znanja") {
            segment.title = "Interakcija srednje razine"
            $scope.hasOutcomes = false;
          } else {
            segment.title = "Procjena znanja";
            $scope.hasOutcomes = true;
          }
        };

        $scope.getOutcomeList = function () {
          AuthoringService.listOutcomes(derId, derTypeId === 'eBook')
            .then(function (res) {
              if (res.err == 0) {
                $rootScope.isLoading = false;
                $scope.outcomes = res.data.items;

              } else {
                $rootScope.isLoading = false;
              }
            })
        };

        if ($scope.hasOutcomes) {
          $scope.getOutcomeList();
        }

        $scope.renderHtml = function (html_code) {
          return $sce.trustAsHtml(html_code);
        };

        // ---------- Editor toolbar type toggling
        $scope.isEditorToolbarTypeFull = function () {
          return $scope.editorToolbarType == 'toolbarFull';
        };
        $scope.isEditorToolbarTypeShort = function () {
          return $scope.editorToolbarType == 'toolbarShort';
        };
        $scope.onEditorFocus = function (event) {
          var toolbarType = event.target.getAttribute("ta-target-toolbars");
          if (toolbarType === "toolbarShort" || toolbarType === "toolbarFull") {
            $scope.editorToolbarType = toolbarType;
          } else {
            console.log("Unknown editor toolbar type: " + toolbarType);
          }
        };

        $scope.collapseTagsOfItem = function (element) {
          //element.a.answerText=collapseTags(element.a.answerText);
          //element.a.answerText = element.a.answerText.replace(/(<p>|<\/p>)/g, " ").trim();
        }
        //Sets percentages on quiz model
        $scope.setPercentages = function (percentageX, percentageY, questionNum, answerNum) {

          var answer = $scope.quiz[questionNum].answers[answerNum];
          answer.percentageX = percentageX;
          answer.percentageY = percentageY;
          //console.log($scope.quiz[questionNum].answers[answerNum]);
        };

        $scope.setPictureShown = function (bool) {
          $scope.pictureShown = bool;
          //console.log("postavio sam pictureShown na " + bool + ";");
        }

        $scope.selectQuestion = (index) => {
          $('#canEditHTML').show();

          $scope.currentQ = (index < $scope.quiz.length) ? $scope.quiz[index] : null;
          $scope.currentIndex = index;
        };

        $scope.selectQuestion(0);

        $scope.addQuestion = () => {
          const questionObject = getNewQuestionObject();

          questionObject.answers.push(getNewAnswerObject(), getNewAnswerObject());

          $scope.quiz.push(questionObject);
          $scope.selectQuestion($scope.quiz.length - 1);

        };

        $scope.deleteQuestion = function (index) {
          // remeber current index so we can restore it the best we can
          var nextIndex = $scope.currentIndex;

          // remove targeted question
          $scope.quiz.splice(index, 1);

          // quiz has at least one question left, select next question
          if ($scope.quiz.length > 0) {
            $scope.selectQuestion(nextIndex < $scope.quiz.length ? nextIndex : $scope.quiz.length - 1);
          } else {
            // if we clear selected Q to null right away null it is ignored by Angular and later changes to currentQ are ignored
            setTimeout(function () {
              // select 0 although list is empty, but it will set currentQ to null and index to 0
              $scope.selectQuestion(0)
            });
          }

          return false;
        };


        $scope.addAnswer = () => {
          const answer = getNewAnswerObject();
          $scope.currentQ.answers.push(answer);


          // ----- initialize dynamically added answer elements
          if ($scope.currentQ.type === 'dragOnPicture') {
            $scope.initializeDraggableAnswer($scope.currentQ.answers.length - 1);
          }
        };

        $scope.deleteAnswer = function (index) {
          $scope.currentQ.answers.splice(index, 1);
        };

        $scope.addElement = function (indexOfGroup) {
          var element = {
            elementText: ""
          };
          if (!$scope.currentQ.answers[indexOfGroup].answerList) {
            $scope.currentQ.answers[indexOfGroup].answerList = [];
          }
          $scope.currentQ.answers[indexOfGroup].answerList.push(element);

        };

        $scope.deleteElement = function (parentIndex, index) {
          $scope.currentQ.answers[parentIndex].answerList.splice(index, 1);
        };

        $scope.deleteAnswerImage = (location) => {
          setImageToQuizElement($scope.currentQ.answers[location], null, null, null, null, null, null);
        };

        $scope.deleteQuestionImage = () => {
          setImageToQuizElement($scope.currentQ, null, null, null, null, null, null);
        };

        $scope.deleteAnswerAudio = (location) => {
          setAudioToQuizElement($scope.currentQ.answers[location], null, null, null, null, null, null);
        };

        $scope.deleteQuestionAudio = () => {
          setAudioToQuizElement($scope.currentQ, null, null, null, null, null, null);
        };

        $scope.changedType = function (newType) {

          var oldTypeValue = $scope.currentQ.oldType;
          switch ($scope.currentQ.type) {
            case "truefalse":

              $scope.currentQ.type = "truefalse";
              var correctSelected = false;
              $scope.currentQ.questionName = "Da/Ne";

              for (var i = $scope.currentQ.answers.length - 1; i >= 0; i--) {
                if (i > 1) {
                  $scope.currentQ.answers.splice(i, 1);
                } else if ($scope.currentQ.answers[i].isCorrect === true) {
                  if (correctSelected) {
                    $scope.currentQ.answers[i].isCorrect = false;
                  } else {
                    correctSelected = true;
                  }
                }
              }

              $scope.currentQ.answers[0].answerText = "Da";
              $scope.currentQ.answers[1].answerText = "Ne";
              /*
              sweet.show({
                title: 'Jeste li sigurni?',
                text: 'Ako pretvorite u Da/Ne izgubit ćete dosad napisane odgovore na pitanja.',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: "Da, želim!",
                closeOnConfirm: true
              }, function(isConfirm) {
                $scope.$apply(function() {
    
                  if (isConfirm) {
    
    
    
                  } else {
                    $scope.currentQ.type=oldTypeValue;
                    $scope.currentQ.oldType = oldTypeValue;
                  }
                });
              });*/
              break;
            case "pair":
              var correctSelected = false;
              angular.forEach($scope.currentQ.answers, function (a) {
                if (a.isCorrect === true) {
                  if (correctSelected) {
                    a.isCorrect = false;
                  } else {
                    correctSelected = true;
                  }
                }
              });
              break;
              // REPL start
            case "repl":
              $scope.currentQ.answers[0].isCorrect = true;
              break;
              // REPL end
          }

          if (oldTypeValue != newType) {
            $scope.currentQ.oldType = newType;
            $scope.currentQ.type = newType;
            var nameOfQuestionDictionary = {
              "truefalse": "Da/Ne",
              "pair": "Jedan točan odgovor",
              "multi": "Višestruki odabir",
              "matching": "Uparivanje odgovora",
              "wordInsert": "Upisivanje riječi",
              insertWordToElement: "Upisivanje u elemente",
              "wordSelect": "Odabiranje riječi",
              "dragElements": "Povlačenje riječi",
              "dragPairs": "Povlačenje parova",
              "dragSortElements": "Razvrstavanje elemenata",
              "dragOrderElements": "Uređivanje poretka elemenata",
              "numericValue": "Numerički",
              "dragOnPicture": "Povlačenje na sliku",
              // REPL start
              "repl": "REPL zadatak"
              // REPL end
            };
            if (newType != "truefalse") {
              $scope.currentQ.questionName = nameOfQuestionDictionary[newType.toString()];
            }
          } else {
            $scope.currentQ.oldType = oldTypeValue;
            $scope.currentQ.type = oldTypeValue;
          }
        };

        $scope.radioChanged = function (index) {
          angular.forEach($scope.currentQ.answers, function (a, i) {
            if (index == i) {
              a.isCorrect = true;
            } else {
              a.isCorrect = false;
            }
          })
        };

        var checkQuizValidity = function () {
          var errorMsg = '';
          var noQuestionText = [];
          var noAnswerCorrect = [];
          var noAnswerText = [];
          var noRequiredText = [];
          var notNumericValue = [];
          var notSetDraggableElement = [];
          // REPL start
          var noREPLAnswer = [];
          var noBlocklyToolbox = [];
          // REPL end
          angular.forEach($scope.quiz, function (q, i) {
            if (!q.answers.length) {
              noAnswerText.push(i);
            }
            if (!q.questionText && q.type !== 'wordInsert' && q.type !== 'wordSelect' && q.type !== 'dragElements' && q.type !== 'numericValue') {
              //errorMsg += "U pitanju " + (i + 1) + " nije naveden tekst pitanja. ";
              noQuestionText.push(i);
            }

            var noneCorrect = true;
            if (q.type === 'matching' || q.type === 'dragPairs' || q.type === 'dragSortElements' || q.type === 'numericValue' || q.type === 'dragOnPicture' || q.type === 'repl') {
              noneCorrect = false;
            } else {
              angular.forEach(q.answers, function (a, k) {

                if (a.isCorrect) {
                  noneCorrect = false;
                }
                if ((!a.answerText || a.answerText.length < 1) && !a.imageURL) {
                  //errorMsg += "U pitanju " + (i + 1) + " nije naveden tekst odgovora broj " + (k + 1) + ". ";
                  noAnswerText.push(i);
                }

              });
            }
            if (q.type === 'wordInsert' || q.type === 'dragElements' || q.type === 'dragOrderElements' || q.type === 'insertWordToElement') {
              noneCorrect = false;
            } else if (q.type === 'matching' || q.type === 'dragPairs') {
              angular.forEach(q.answers, function (a, k) {
                if (!a.pairPrompt) {
                  noAnswerText.push(i);
                }
                if (!a.answerText) {
                  noAnswerText.push(i);
                }
              });

            } else if (q.type === 'wordSelect') {

              angular.forEach(q.answers, function (a, k) {
                if (!a.allAnswersText) {
                  noAnswerCorrect.push(i);
                  return;
                }
                var allAnswers = a.allAnswersText.split(';');
                noneCorrect = true;
                for (var k = 0; k < allAnswers.length; k++) {
                  if (allAnswers[k] && allAnswers[k].trim().toLowerCase() == a.answerText.trim().toLowerCase()) {
                    noneCorrect = false;
                  }
                }
                if (noneCorrect) {
                  noAnswerCorrect.push(i);
                }
                noneCorrect = false;
              });
            } else if (q.type === 'dragSortElements') {
              noneCorrect = false;

              angular.forEach(q.answers, function (a, k) {
                if (!a.groupName) {
                  noRequiredText.push(i);
                }
                angular.forEach(a.answerList, function (answer) {
                  if (!collapseTags(answer.elementText)) {
                    noAnswerText.push(i);
                  }
                });
              });
            } else if (q.type === 'numericValue') {

              angular.forEach(q.answers, function (a, k) {

                if (!a.minValue && a.minValue != 0 || isNaN(a.minValue)) {
                  notNumericValue.push(i);
                }
                if (!a.maxValue && a.maxValue != 0 || isNaN(a.maxValue)) {
                  notNumericValue.push(i);
                }
                if (!isNaN(a.maxValue) && !isNaN(a.minValue) && parseFloat(a.minValue) > parseFloat(a.maxValue)) {
                  notNumericValue.push(i);
                }
              });
            } else if (q.type === 'dragOnPicture') {
              if (!q.imageURL) {
                noRequiredText.push(i);
              } else {
                angular.forEach(q.answers, function (a, k) {
                  if (!collapseTags(a.answerText)) {
                    noAnswerText.push(i);
                  }
                  if (isNaN(parseFloat(a.percentageX)) || isNaN(parseFloat(a.percentageX)) || a.percentageX == "-1") {
                    notSetDraggableElement.push(i);
                  }

                });
              }
              // REPL start
            } else if (q.type === 'repl') {
              // REPL zadatak bez ispisa točnog odgovora
              if (!q.answers.length || !q.answers[0].answerText) {
                noREPLAnswer.push(i);
              }
              // TODO HH:
              // treba li provjera da q.replType nije tekst i nije isključivo "javascript", "python" ili "blockly" ?!?

              // REPL zadatak tipa Blockly bez obaveznog Toolbox-a
              if (q.replType === "blockly" && !q.blocklyToolbox) {
                noBlocklyToolbox.push(i);
              }
              // REPL end
            }

            if (noneCorrect) {
              //errorMsg += "U pitanju " + (i + 1) + " ni jedan odgovor nije označen kao točan. ";
              noAnswerCorrect.push(i);
            }
            noneCorrect = true;

          });

          if (noQuestionText.length) {
            var qs = '(';
            angular.forEach(noQuestionText, function (i) {
              qs += (i + 1) + ' ';
            });
            qs += ')';
            errorMsg += 'Neka pitanja nemaju naveden tekst pitanja ' + qs + '. ';
          }
          if (noAnswerCorrect.length) {
            var na = '(';
            angular.forEach(noAnswerCorrect, function (i) {
              na += (i + 1) + ' ';
            });
            na += ')';
            errorMsg += 'Neka pitanja nemaju označen točan odgovor ' + na + '. ';
          }
          if (noAnswerText.length) {
            var nt = '(';
            angular.forEach(noAnswerText, function (i) {
              nt += (i + 1) + ' ';
            });
            nt += ')';
            errorMsg += 'Neka pitanja nemaju naveden odgovor ' + nt + '. ';
          }
          if (noRequiredText.length) {
            var nr = '(';
            angular.forEach(noRequiredText, function (i) {
              nr += (i + 1) + ' ';
            });
            nr += ')';
            errorMsg += 'Neka pitanja nemaju navedena obavezna polja ' + nr + '. ';
          }
          if (notNumericValue.length) {
            var nnm = '(';
            angular.forEach(notNumericValue, function (i) {
              nnm += (i + 1) + ' ';
            });
            nnm += ')';
            errorMsg += 'Neka pitanja nemaju ispravno zadane numeričke vrijednosti ' + nnm + '. ';
          }
          if (notSetDraggableElement.length) {
            var nnm = '(';
            angular.forEach(notSetDraggableElement, function (i) {
              nnm += (i + 1) + ' ';
            });
            nnm += ')';
            errorMsg += 'Neka pitanja nemaju ispravno namještene prijenosne elemente ' + nnm + '. ';
          }
          // REPl start
          if (noREPLAnswer.length) {
            var nnm = '(';
            angular.forEach(noREPLAnswer, function (i) {
              nnm += (i + 1) + ' ';
            });
            nnm += ')';
            errorMsg += 'Neki REPL zadaci nemoaju ispis točnog rješenja ' + nnm + '. ';
          }
          if (noBlocklyToolbox.length) {
            var nnm = '(';
            angular.forEach(noBlocklyToolbox, function (i) {
              nnm += (i + 1) + ' ';
            });
            nnm += ')';
            errorMsg += 'Neki REPL zadaci vrste Blockly nemaju definiran Toolbox ' + nnm + '. ';
          }
          // REPL end
          return errorMsg;
        };


        $scope.showImageDialog = function (i) {
          $scope.imageDialogVisibleIndex = i;
        };

        $scope.addQuizFileResource = (location, fileType) => {
          document.getElementById("QuizModal").style.display = 'none';
          ModalService.showModal({
            templateUrl: "/views/partials/uploadResourceModal.html",
            controller: "UploadImageModalCtrl",
            inputs: {
              hasAltText: false,
              fileMultimediaData: null,
              fileType: fileType,
              actionType: 'insertToQuiz',
            }
          }).then((modal2) => {
            modal2.element.modal();
            modal2.close.then((res) => {
              if (res.save) {
                const der = {
                  ...res.res
                };
                if (!der.derInfo) {
                  der.derInfo = {};
                }
                const quizElement = location === 'question' ? $scope.currentQ : $scope.currentQ.answers[location];

                if (fileType === 'img') {
                  setImageToQuizElement(quizElement, der.files[0].urlRemote, der.derInfo.title, null, der.derId, der.derVersion, der.files[0].contentTypeId);
                } else if (fileType === 'aud') {
                  setAudioToQuizElement(quizElement, der.files[0].urlRemote, der.derInfo.title, null, der.derId, der.derVersion, der.files[0].contentTypeId);
                } else {
                  console.log('wrong element type')
                }

              }
              const b = document.body;
              b.className = b.className + " modal-open";

              document.getElementById("QuizModal").style.display = 'block';

            });
          });
          return false;
        };

        $scope.dragControlListeners = {
          accept: function (sourceItemHandleScope, destSortableScope) {
            return true
          }, //override to determine drag is allowed or not. default is true.
          itemMoved: function (event) {},
          orderChanged: function (event) {}
        };

        $scope.close = function () {

          var isQuizValid = checkQuizValidity();
          if (!isQuizValid.length) {

            var relationIdsToDelete = [];

            angular.forEach(relations, function (r) {
              relationIdsToDelete.push(r.derRelationId);
            });

            var targetsToAdd = [];

            angular.forEach($scope.quiz, function (q) {
              if (q.imageURL && !q.imageRelationId) {
                targetsToAdd.push({
                  targetDerId: q.imageDerId,
                  targetDerVersion: q.imageDerVersion,
                  relationTypeId: "uses",
                  targetContentTypeId: q.imageContentTypeId
                });
              }
              angular.forEach(q.answers, function (a) {
                if (a.imageURL && !a.imageRelationId) {
                  targetsToAdd.push({
                    targetDerId: a.imageDerId,
                    targetDerVersion: a.imageDerVersion,
                    relationTypeId: "uses",
                    targetContentTypeId: a.imageContentTypeId
                  });
                }
              });

            });
            $rootScope.isLoading = true;

            if (relations && quizId) {
              AuthoringService.delRelation(quizId, relationIdsToDelete, version)
                .then(function (res) {
                  if (res.err === 0) {

                    AuthoringService.addRelation(quizId, res.data.derVersion, targetsToAdd)
                      .then(function (res5) {
                        if (res5.err === 0) {
                          if (res5.data && res5.data.relations) {
                            angular.forEach(res5.data.relations, function (r) {
                              angular.forEach($scope.quiz, function (q) {
                                if (q.imageDerId === r.targetDerId) {
                                  q.imageRelationId = r.derRelationId;
                                }
                                angular.forEach(q.answers, function (a) {
                                  if (a.imageDerId === r.targetDerId) {
                                    a.imageRelationId = r.derRelationId;
                                  }
                                });

                              });
                            });
                          }
                          $rootScope.isLoading = false;
                          $element.modal('hide');
                          close({
                            quiz: angular.copy($scope.quiz),
                            quizId: quizId,
                            isNewQuiz: false,
                            save: true
                          }, 500);
                        } else {
                          $rootScope.isLoading = false;
                        }
                      });
                  } else {
                    $rootScope.isLoading = false;
                  }
                });
            } else if (quizId && !relations) {

              AuthoringService.addRelation(quizId, version, targetsToAdd)
                .then(function (res) {
                  if (res.err === 0) {
                    if (res.data && res.data.relations) {
                      angular.forEach(res.data.relations, function (r) {
                        angular.forEach($scope.quiz, function (q) {
                          if (q.imageDerId === r.targetDerId) {
                            q.imageRelationId = r.derRelationId;
                          }
                          angular.forEach(q.answers, function (a) {
                            if (a.imageDerId === r.targetDerId) {
                              a.imageRelationId = r.derRelationId;
                            }
                          });

                        });
                      });
                    }
                    $rootScope.isLoading = false;
                    $element.modal('hide');
                    close({
                      quiz: angular.copy($scope.quiz),
                      quizId: quizId,
                      isNewQuiz: false,
                      save: true
                    }, 500);
                  } else {
                    $rootScope.isLoading = false;
                  }
                });
            } else {
              var CONTRIBUTORID = 1;
              if ($rootScope.relatedContributors && $rootScope.relatedContributors[0]) {
                CONTRIBUTORID = $rootScope.relatedContributors[0].contributorId;
              } else {
                CONTRIBUTORID = {
                  firstname: User.getFirstname(),
                  lastname: User.getLastname()
                };
              }

              var visibility = null;
              if ($rootScope.tenant !== 'carnet') {
                visibility = "public";
              } else if ($rootScope.tenant === 'carnet') {
                visibility = "private";
              }

              AuthoringService.createMetadata("test", "Kviz", visibility, null, null, CONTRIBUTORID, null, $scope.quiz)
                .then(function (res2) {


                  if (targetsToAdd && targetsToAdd.length) {
                    AuthoringService.addRelation(res2.data.derId, res2.data.lastVersion, targetsToAdd)
                      .then(function (res) {
                        if (res.err === 0) {
                          if (targetsToAdd.length > 0 && res.data.relations) {
                            angular.forEach(res.data.relations, function (r) {
                              angular.forEach($scope.quiz, function (q) {
                                if (q.imageDerId === r.targetDerId) {
                                  q.imageRelationId = r.derRelationId;
                                }
                                angular.forEach(q.answers, function (a) {
                                  if (a.imageDerId === r.targetDerId) {
                                    a.imageRelationId = r.derRelationId;
                                  }
                                });

                              });
                            });
                          }
                          $rootScope.isLoading = false;
                          $element.modal('hide');
                          close({
                            quiz: angular.copy($scope.quiz),
                            quizId: res2.data.derId,
                            isNewQuiz: true,
                            save: true
                          }, 500);
                        } else {
                          $rootScope.isLoading = false;
                        }
                      });
                  } else {
                    $rootScope.isLoading = false;
                    $element.modal('hide');
                    close({
                      quiz: angular.copy($scope.quiz),
                      quizId: res2.data.derId,
                      isNewQuiz: true,
                      save: true
                    }, 500);
                  }
                });
            }

          } else {
            sweet.show({
              title: 'Kviz nije ispravan!',
              text: isQuizValid,
              type: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#DD6B55',
              confirmButtonText: "OK",
              closeOnConfirm: true
            });
          }
        };

        $scope.cancel = function () {
          $element.modal('hide');
          close({
            save: false
          }, 500);
        };

        $scope.setDraggableElements = function () {

          var modal = document.getElementById("modal-quiz-creator");
          if (!modal) {
            console.log("modal ne postoji!!!");
            return;
          }

          var groupElements = modal.getElementsByClassName("draggable-restricted");
          var pictureElement = getQuizModalElementId(modal, "picture-dropzone" + $scope.currentIndex);
          // these elements are dynamically created so we have to initialize them
          initDragOnPictureElements("question_" + ($scope.currentIndex || 0));

          var pictureRect = pictureElement.getBoundingClientRect();
          var pictureHeight = pictureElement.height;
          var pictureWidth = pictureElement.width;

          for (var j = 0; j < groupElements.length; j++) {
            //check if this draggable elements are for that question
            if ((groupElements[j].id.split("dop_q")[1].split("a")[0]) == ($scope.currentIndex ? $scope.currentIndex : 0)) {
              // clear set positions and use only natural element's positions (elements are reused when changing questions thus previous values should be cleared)
              groupElements[j].style.left = "";
              groupElements[j].style.top = "";

              // take new element's position
              var elRect = groupElements[j].getBoundingClientRect();


              // ---------- recalculate positions on image
              // percentages are calculated according to draggable's center point, so we have to reverse that calculation to get new absolute position

              // saved positions
              var positionXPercentage = parseFloat(groupElements[j].getAttribute("data-position-x") || 0);
              var positionYPercentage = parseFloat(groupElements[j].getAttribute("data-position-y") || 0);

              // position only already positioned elements
              if (positionXPercentage > 0 || positionXPercentage > 0) {
                // horizontal (x)
                var newAbsoluteLeft = pictureRect.left + pictureRect.width * positionXPercentage - (elRect.right - elRect.left) / 2;
                var newRelativeLeft = newAbsoluteLeft - elRect.left;
                // vertical (y)
                var newAbsoluteTop = pictureRect.top + pictureRect.height * positionYPercentage - (elRect.bottom - elRect.top) / 2;
                var newRelativeTop = newAbsoluteTop - elRect.top;

                // set positions as supporting library expects it (see more info in quiz.js)
                groupElements[j].style.left = newRelativeLeft + "px";
                groupElements[j].style.top = newRelativeTop + "px";
              }
            }
          }
        };

        /** Make draggable answer draggable. */
        $scope.initializeDraggableAnswer = function (answerIndex) {
          // allow small delay to allow DOM changes to take effect
          //  this is very ugly :-(, but DOM el is rendered after angular's lifecycle, ID is defined only in DOM
          setTimeout(function () {
            initDragOnPictureAnswerElement($("#dop_q" + $scope.currentIndex + "a" + answerIndex));
          }, 10);
        };
      }
    ])
    .directive('imageonload', function () {
      return {
        link: function (scope, element, attrs) {
          element.on('load', function () {
            //console.log("usao sam u directivu");
            scope.setDraggableElements();
          });
        }
      };
    });
};