
// ---------- scripts

// vendor scripts
// NOTE: if any of these scripts contains global variables, script MUST NOT use "strict" mode because it will prevent those "wannabe globals" to be inject into global namespace

require("../bower_components/angular/angular-route.js");
require("../bower_components/angular/angular-animate.min.js");

require("../bower_components/angular-resource/angular-resource.min.js");
require("../bower_components/angular-cookies/angular-cookies.min.js");

// =

require("../bower_components/restangular/dist/restangular.min.js");
require("../bower_components/lodash/dist/lodash.min.js");
require("../bower_components/sha1.js");
require("../bower_components/ngstorage/ngStorage.js");
require("../bower_components/ui-bootstrap-tpls-0.12.1.min.js");

require("../bower_components/textAngular/textAngular-rangy.min.js");
require("../bower_components/textAngular/textAngular-sanitize.js");
require("../bower_components/textAngular/textAngularSetup.js");
require("../bower_components/textAngular/textAngular-factories.js");
require("../bower_components/textAngular/textAngular-DOM.js");
require("../bower_components/textAngular/textAngular-validators.js");
require("../bower_components/textAngular/textAngular-taBind.js");
require("../bower_components/textAngular/textAngular.js");

require("../bower_components/cookies.js");

require("../bower_components/ng-sortable/ng-sortable.js");
require("../bower_components/angular-modal-service.js");

require("../bower_components/resize-service.js");

require("../bower_components/sweetalert/sweetalert.min.js");
require("../bower_components/sweetalert/ngSweetAlert.min.js");
require("../bower_components/ngToast/ngToast.min");

require("../bower_components/image-crop/image-crop.js");
require("../bower_components/checklist-model.js");

require("../bower_components/angulartics/angulartics.min.js");
require("../bower_components/angulartics/angulartics-g.min.js");

require("../bower_components/windowsPopup/windowsPopup.js");
require("../bower_components/windowsPopup/windowsPopupConfig.js");

require("../bower_components/interact/interact.js");
require("../bower_components/htmldiff/js/htmldiff.js");



// ---------- app templates

// require all templates and allow ngtemplate-loader to resolve them when needed
function importAllTemplates(r) {
    r.keys().forEach(function (file) {
        r(file);
    });
}
// require all files in ../views folder recursively
importAllTemplates(require.context('../', true, /.+\/views\/.*\.html$/));

// utils
require("expose-loader?AppConfigService!./utils/config/AppConfigService"); // expose module as global variable
require("./common/setup/loggingSetup");
require("expose-loader?ContactService!./utils/contact/ContactService"); // expose module as global variable

// app scripts

require('./app.js');

require("../js/ResizeSensor.js");
require("script-loader!../js/quiz.js"); // this script is used also in preview so we cannot just change it to export module
require("./controllers/UserControllers.js")();
require("./services/UserServices.js")();
require("./services/UtilServices.js")();
require("./controllers/CoreControllers.js")();
require("./controllers/UploadImageModalController.js")();
require("./controllers/QuizControllers.js")();
require("./services/AuthoringServices.js")();
require("./services/ReportingServices.js")();
require("./controllers/ui/master-ctrl.js")();
require("./services/DictionaryService.js")();
require("./controllers/DictionaryController.js")();


// ----- images

function importAllImages(r) {
    r.keys().forEach(function (file) {
        r(file);
    });
}
// require all images in ../app folder recursively 
importAllImages(require.context('../', true, /.+\/img\/.(jpe?g|png|gif|svg)$/i));


// ----- styles

// vendor CSS
require('../bower_components/textAngular/textAngular.css');
require("../bower_components/ng-sortable/ng-sortable.css");
require("../bower_components/sweetalert/sweetalert.css");
require("../bower_components/ngToast/ngToast.css");
require("../bower_components/image-crop/image-crop-styles.css");

// app CSS
require('../styles/master.css');
require('../styles/adds.css');
require('../styles/responsive.css');
require('../styles/quiz.css');
require('../styles/repl.css');
