module.exports = function() {
    angular.module('explainApp')
    .service('ReportService', ['$rootScope', 'Restangular', function ($rootScope, Restangular) {

        this.getSummaryReportForDerType = function (derTypeId) {
            var req = {
                "derTypeId": derTypeId
            };

            Restangular.setBaseUrl($rootScope.server);
            var baseUrl = Restangular.all('report/summaryReportForDerType');
            return baseUrl.post(req);
        }

        this.getDetailedReportForDer = function (derId) {
            var req = {
                "derTypeId": 'dos',
                "derId": derId
            };

            Restangular.setBaseUrl($rootScope.server);
            var baseUrl = Restangular.all('report/detailedReportForDer');
            return baseUrl.post(req);
        }

        this.getReportCsvFile = function (reportId, derTypeId, derId) {
            var req = {
                "reportId": reportId,
                "derTypeId": derTypeId,
                "derId": derId
            };

            Restangular.setBaseUrl($rootScope.server);
            var baseUrl = Restangular.all('report/getReportCsvFile');
            return baseUrl.post(req);
        }
    }]);
};