const logging = require('../../utils/logging/logger');

const Sentry = require('@sentry/browser');

const LOGGER = logging.getLogger('ContactService');

const SENTRY_CONFIG = AppConfigService.getValue('logging.sentry');

const SENTRY_CONTACT_FORM_TAG = "CONTACT_FORM";
const SENTRY_BE_RESPONSE_TAG = "BE_RESPONSE";

/** Send contact form data to sentry. */
function sendContactFormData(name, data, tags) {
  if (SENTRY_CONFIG == null || !SENTRY_CONFIG.enabled) {
    LOGGER.info('Cannot send contact data because sentry is not enabled');
    return;
  }

  if (name == null || name == '') {
    LOGGER.warn('Cannot send contact form data without a name. Please use contact\'s name or some other unique identifier in order to distinguish contact messages later.');
    return;
  }

  const message = `[${SENTRY_CONTACT_FORM_TAG}] ${name}`;
  LOGGER.debug(`Sending contact data ${name}`, JSON.stringify(data));

  Sentry.withScope(scope => {
    Object.keys(data).forEach((key) => {
      scope.setExtra(key, data[key]);
    });
    scope.setTag('source', SENTRY_CONTACT_FORM_TAG);

    Object.keys(tags).forEach((key) => {
        scope.setTag(key, data[key]);
  });

    Sentry.captureMessage(message, Sentry.Severity.Info);
  });
}

/** Send data from backend error response to sentry. */
function sendBackendErrorData(name, data, tags) {
    if (SENTRY_CONFIG == null || !SENTRY_CONFIG.enabled) {
        LOGGER.info('Cannot send contact data because sentry is not enabled');
        return;
    }

    if (name == null || name == '') {
        LOGGER.warn('Cannot send contact form data without a name. Please use contact\'s name or some other unique identifier in order to distinguish contact messages later.');
        return;
    }

    const message = `[${SENTRY_BE_RESPONSE_TAG}] ${name}`;
    LOGGER.debug(`Sending backend response data ${name}`, JSON.stringify(data));

    Sentry.withScope(scope => {
        Object.keys(data).forEach((key) => {
        scope.setExtra(key, data[key]);
});
    scope.setTag('source', SENTRY_BE_RESPONSE_TAG);

    Object.keys(tags).forEach((key) => {
        scope.setTag(key, data[key]);
});

    Sentry.captureMessage(message, Sentry.Severity.Warning);
});
}


module.exports = {
  sendContactFormData, sendBackendErrorData
};
